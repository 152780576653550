export class ProductAvailability {
  constructor() {
    this.availableQuantity = 0;
  }
    id: number;
    itemNumber: string;
    s2kAvailable: number;
    availableQuantity: number;
    pendingSoldQuantity: number;
    confirmedSoldQuantity: number;
}
