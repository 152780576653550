import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ProductReviewService} from "../../core/services/product-review.service";
import {take} from "rxjs";
import {ProductReview} from "../../core/models/product-review.model";
import {OrderHistoryItem} from "../../core/models/order-history.model";

@Component({
  selector: 'app-product-review-write',
  templateUrl: './product-review-write.component.html',
  styleUrls: ['./product-review-write.component.scss']
})
export class ProductReviewWriteComponent implements OnInit{
  orderedProduct: OrderHistoryItem;
  userId: number;
  reviewForm: FormGroup;
  starRating: number = 0;

  constructor(private formBuilder: FormBuilder, private productReviewService: ProductReviewService) {
  }

  ngOnInit() {
    this.reviewForm = this.formBuilder.group({
      productId: this.orderedProduct.productId,
      orderItemId: this.orderedProduct.id,
      userId: this.userId,
      name: ['', [Validators.required, Validators.maxLength(255)]],
      review: ['', [Validators.required, Validators.maxLength(512)]],
      rating: this.starRating,
      approved: false
    });
  }

  submit() {
    this.productReviewService.createProductReview(this.reviewForm.value)
      .pipe(take(1))
      .subscribe((response: ProductReview) => {
        console.log('review created',response)
      })
  }

  updateStarRating(newRating: number) {
    this.reviewForm.patchValue({ rating: newRating })
    this.starRating = newRating;
  }
}
