import {Image} from './image.model';
import {TagRelation} from './tag-relation.model';
import { Category } from './category.model';
import {ProductAvailability} from "./product-availability.model";
import {ProductPrice} from "./product-price.model";
import {ProductCategory} from "./product-category.model";
import {ProductReview} from "./product-review.model";



export class Product {
  constructor() {
    this.productAvailability = new ProductAvailability();
    this.productPrice = new ProductPrice();
    this.displayName = '';
  }
    id: number;
    itemNumber: string;
    metaTitle: string;
    metaDescription: string;
    description1: string;
    description2: string;
    uom: string;
    packageSize: string;
    brand: string;
    brandName: string;
    itemWeight: number;
    itemWeightUom: string;
    perishable: boolean;
    shortDescription: string;
    longDescription: string;
    replacementItemNumber: string;
    displayName: string;
    url: string;
    giftable: boolean;
    promotable: boolean;
    active: boolean;
    deleted: boolean;
    salesTaxCode: string;
    cubicVolume: number;
    cubicVolumeUom: string;
    createdTs: Date;
    displaySeq: number;
    catchWeight: string;
    catchWeightUom: string;
    quantity: number;
    images: Image[];
    tagRelations: TagRelation[];
    productAvailability: ProductAvailability;
    productPrice: ProductPrice;
    productCategories: ProductCategory[];
    // tax: number;
    // taxable: boolean;

    // this may stay it may not, the world may never know
    breadcrumbs?: Category[];

    image?: Image;
    reviews?: ProductReview[];
}
