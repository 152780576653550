import {Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID} from '@angular/core';
import {Store} from '@ngrx/store';
import {Product} from '../../core/models/product.model';
import {ShoppingCart, ShoppingCartItem} from '../../core/models/shopping-cart.model';
import {AppStore} from '../../core/models/state.model';
import {CartService} from '../../core/services/cart.service';
import {OrderService} from '../../core/services/order.service';
import {PromotionsService} from '../../core/services/promotions.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from "../../shared/components/confirmation-dialog/confirmation-dialog.component";
import {Router} from '@angular/router';
import {Account} from '../../core/models/account.model';
import {LoginService} from '../../core/services/login.service';
import { isPlatformBrowser } from '@angular/common';
import {UpdateCartItemQuantity} from "../../core/actions/state.actions";
import {User} from "../../core/models/user.interface";
import {take} from "rxjs";

@Component({
    selector: 'app-view-cart',
    templateUrl: './view-cart.component.html',
    styleUrls: ['./view-cart.component.scss']
})

export class ViewCartComponent implements OnInit {

    @Output() completed = new EventEmitter();

    shoppingCart: ShoppingCart = new ShoppingCart();
    promoCode: string;
    promoWaiting: boolean = false;
    promoSuccess: boolean;
    promoMessages: string[] = [];
    checkoutDisabled: boolean = false;
    showCheckoutDisabledMessage: boolean = false;
    activePromoCode: string;
    loggedInUser: User | null;

    itemNumbers: string[] = [];
    updateQueue: ShoppingCartItem[] = [];
    timer: any;
  timeMilliseconds: number = 2 * 1000; // seconds * 1000 = milliseconds
  disableQuantityChecks: boolean = false

    constructor(private cartService: CartService,
                private orderService: OrderService,
                private promotionsService: PromotionsService,
                private loginService: LoginService,
                private dialog: MatDialog,
                private router: Router,
                @Inject(PLATFORM_ID) private platformId: any,
                private store: Store<AppStore>) {
        store.subscribe(subscribedStore => {
            const state = subscribedStore.state;
            this.loggedInUser = state.user;
            this.shoppingCart.items = state.cartItems;
            if (this.shoppingCart.items) {
                this.calculateTotals();
                this.getActivePromoCode();
                this.itemNumbers = this.shoppingCart.items.map(item => item.itemNumber);
            }
            this.disableQuantityChecks = state.quantityIsDisabled;
        });


        // if (!this.loggedInUser) {
        //   loginService.getCheckoutDisableStatus().subscribe(success => {
        //     if (!success.success) {
        //       this.checkoutDisabled = true;
        //       this.showCheckoutDisabledMessage = true;
        //       return;
        //     }
        //     this.checkoutDisabled = success.result;
        //     this.showCheckoutDisabledMessage = success.result;
        //   }, error => {
        //     this.checkoutDisabled = true;
        //     this.showCheckoutDisabledMessage = true;
        //     console.error(error.error);
        //   });
        // } else {
        //
        // }

    }

    ngOnInit() {}

    get noItemsInStock(): boolean {
        return this.shoppingCart.items.every(item => item.availableQuantity === 0);
    }

    next() {

        if (!this.loggedInUser) {
            const loginRef = this.dialog.open(ConfirmationDialogComponent, {
                data: {
                  title: 'Before you checkout...',
                  message: 'Would you like to login / sign up before you place your order?'}
            });
            loginRef.componentInstance.yesNoMode = true;

            loginRef.afterClosed().subscribe(goToLogin => {
                if (goToLogin) {
                    this.router.navigateByUrl('login');
                } else {
                    this.proceed();
                }
            });
        } else {
            this.proceed();
        }

    }

    proceed() {
        // see if there are any orders where the available quantity is less than the wanted quantity
        const passes = this.shoppingCart.items.every(item => item.quantity <= item.availableQuantity);

        // for now we just want to pass we do not want to show the popup
        if (passes || this.disableQuantityChecks) {
            this.prepareOrder();
        } else {
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                data: 'There is not enough stock for one or more items in your cart. Would you like to continue with your order? (Click NO to see cart for details.)'
            });

            dialogRef.afterClosed().subscribe(confirm => {
                if (!confirm) {
                    return;
                }
                this.prepareOrder();
            });
        }

    }

    prepareOrder() {
        this.orderService.prepare()
            .subscribe(prepareResponse => {
                if (!prepareResponse) {
                    return;
                }

                this.completed.emit();
            }, error => {
                console.error(error);
            });
    }

    update() {

        const queue = this.updateQueue.slice();

        this.updateQueue = [];
        this.checkoutDisabled = false;

        queue.forEach(cartItem => {
            this.cartService.editInCart(cartItem)
            .subscribe(
                (editInCartResponse: ShoppingCartItem[]) => {
                    if (editInCartResponse) {

                        const gettingUpdatedAgain = this.updateQueue.some(uq => uq.itemNumber === cartItem.itemNumber);

                        if (!gettingUpdatedAgain) {
                            this.cartService.dispatchCartUpdate(editInCartResponse);
                        }
                    }
                },
                (error: any) => console.error(error),
                () => {
                }
            );
        });
    }

    delayUpdate(shoppingCartItem: ShoppingCartItem) {
        if (this.timer) {
            clearTimeout(this.timer);
          }

          const index = this.updateQueue.findIndex(uq => uq.itemNumber === shoppingCartItem.itemNumber);

          if (index === -1) {
              this.updateQueue.push(shoppingCartItem);
          } else {
              this.updateQueue[index] = shoppingCartItem;
          }

          this.checkoutDisabled = true;

          this.store.dispatch( new UpdateCartItemQuantity(shoppingCartItem))

          this.timer = setTimeout(() => {
            this.update();
          }, this.timeMilliseconds);
    }

    delete(shoppingCartItem: ShoppingCartItem) {
        this.checkoutDisabled = true;
        this.cartService.deleteFromCart(shoppingCartItem.id)
            .subscribe(
                (newCartItems: ShoppingCartItem[]) => {
                  this.cartService.dispatchCartUpdate(newCartItems);
                  this.checkoutDisabled = false;
                },
                (error: any) => console.error(error),
                () => {
                }
            );
    }

    calculateTotals() {
        let count = 0,
            subtotal = 0.00;

        if (this.shoppingCart.items && this.shoppingCart.items.length > 0) {
            this.shoppingCart.items.forEach(item => {
                count++;
                const itemAvailability = this.disableQuantityChecks ? true : item.availableQuantity;
                const itemQuantity = item.quantity;
                const itemPrice = item.discountPrice > 0 || item.discountPercent > 0 ? item.discountPrice : item.price;

                if (itemAvailability && itemQuantity && itemPrice) {
                    subtotal += itemQuantity * itemPrice;
                }
            });
        }

        this.shoppingCart.count = count;
        this.shoppingCart.subtotal = subtotal;
    }

    filterShoppingCartItems() { // remove garbage from shopping cart items array
        this.shoppingCart.items = this.shoppingCart.items.filter(function (item) {
            return typeof item === 'object';
        });
    }

    getActivePromoCode() {
        const promos: any = {};
        this.shoppingCart.items.forEach(item => {
            if (item.promoCode) {
                if (promos[item.promoCode]) {
                    promos[item.promoCode] += 1;
                } else {
                    promos[item.promoCode] = 1;
                }
            }
        });
        let activePromoCode = null;
        let activePromoCodeCount = 0;
        for (const key in promos) {
            if (promos.hasOwnProperty(key)) {
                if (promos[key] > activePromoCodeCount) {
                    activePromoCode = key;
                    activePromoCodeCount = promos[key];
                }
            }
        }

        if (activePromoCode) {
            this.activePromoCode = activePromoCode;
        }
    }

    applyPromoCode() {
        if (!this.promoCode) {
            return;
        }

        this.promoWaiting = true;
        this.promotionsService.getPromotion(this.promoCode)
            .subscribe(success => {
            this.promoSuccess = !!success;
            this.promoMessages = success.message;
            this.cartService.dispatchCartUpdate(success.result);
            this.promoWaiting = false;
            this.promoCode = '';
            setTimeout(() => {
                this.promoSuccess = false;
            }, 2000);
        }, error => {
            console.error(error);
        });

    }

}
