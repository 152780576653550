import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutComponent } from './checkout.component';
import {SharedModule} from "../shared/shared.module";
import {ViewCartComponent} from "./view-cart/view-cart.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {CheckoutSummaryComponent} from "./checkout-summary/checkout-summary.component";
import {CategoryModule} from "../category/category.module";
import {ShippingInformationComponent} from "./shipping-information/shipping-information.component";
import {
  AddressValidationDialogComponent
} from "./shipping-information/address-validation-dialog/address-validation.dialog.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { ShippingOptionsComponent } from './shipping-options/shipping-options.component';
import {PaymentCompleteComponent} from "./payment-complete/payment-complete.component";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {PaymentProcessingDialogComponent} from "./payment-options/dialogs/payment-processing.dialog.component";
import {PaymentOptionsComponent} from "./payment-options/payment-options.component";
import {MatRadioModule} from "@angular/material/radio";
import {PromotionsService} from "../core/services/promotions.service";
import {FedexService} from "../core/services/fedex.service";
import {AddressService} from "../core/services/address.service";
import {ErrorService} from "../core/services/error.service";
import {PaymentService} from "../core/services/payment.service";
import {AccountDetailsService} from "../core/services/account-details.service";
import {OrderService} from "../core/services/order.service";
import {CartService} from "../core/services/cart.service";
import {StripeElementsDirective, StripePaymentElementComponent, StripePaymentRequestButtonComponent} from "ngx-stripe";

@NgModule({
  declarations: [
    CheckoutComponent,
    ViewCartComponent,
    CheckoutSummaryComponent,
    ShippingInformationComponent,
    AddressValidationDialogComponent,
    PaymentCompleteComponent,
    ShippingOptionsComponent,
    PaymentOptionsComponent,
    PaymentProcessingDialogComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatCheckboxModule,
        MatInputModule,
        MatSnackBarModule,
        MatRadioModule,
        CategoryModule,
        MatTooltipModule,
        StripeElementsDirective,
        StripePaymentElementComponent,
        StripePaymentRequestButtonComponent
    ],
  providers: [
    PromotionsService,
    FedexService,
    AddressService,
    ErrorService,
    PaymentService,
    AccountDetailsService,
    OrderService,
    CartService
  ]
})
export class CheckoutModule { }
