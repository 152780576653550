import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class NewsLetterService {

  readonly baseUrl = `${environment.url}/api/news-letter`;

  constructor(private http: HttpClient) { }

  signUp(email: string): Observable<{success: boolean}> {
    return this.http.post<{success: boolean}>(this.baseUrl, {email: email});
  }

  updateSubscribe(subscribe: boolean): Observable<{success: boolean}> {
	  return this.http.put<{success: boolean}>(this.baseUrl, {subscribe: subscribe});
  }
}
