<div class="flex flex-wrap md:flex-nowrap justify-center items-stretch">
  <div class="checkout-main shipping-opt flex flex-col w-3/4 md:w-full gap-5" appMobileView>

    <!--        <label class="disclaimer">-->
    <!--            <strong>A friendly reminder:</strong><br>-->
    <!--            For Priority Overnight delivery, orders placed after 3:00 p.m. EST on Thursday or Friday-Sunday will ship-->
    <!--            the following Monday for Tuesday delivery. See <a href="/shipping">Shipping</a> for more information.-->
    <!--        </label>-->

    <label class="shipping-title">Choose a shipping method:</label>

    <!--Something is loading-->
    <div *ngIf="!orderArray || !orderArray[0]  || (!perishableShippingRates && !nonperishableShippingRates && !combinedShippingRates)">
      <mat-spinner class="text-center" [diameter]="35"></mat-spinner>
    </div>

    <!--Nothing is loading-->
    <div *ngIf="orderArray && orderArray[0] && (perishableShippingRates || nonperishableShippingRates || combinedShippingRates)">



      <!--Split Shipping Option-->
      <div *ngIf="splitShippingOption" class="flex flex-col gap-5">

        <!--Error: split shipping sption with less than three rates obtained-->
        <div *ngIf="!perishableShippingRates?.checked || !nonperishableShippingRates?.checked || !combinedShippingRates?.checked">
          <p>{{fedexUnavailableRatesErrorMsg}}</p>
          <button mat-button class="checkout-button" (click)="getRates()">Try Again</button>
        </div>

        <!-- No error -->
        <div *ngIf="perishableShippingRates?.checked && nonperishableShippingRates?.checked && combinedShippingRates?.checked" class="flex flex-col gap-5">
          <div class="order-container" class="flex flex-col gap-2">
            <label>Your order has both perishable and non-perishable items.</label>
            <label>It may be cheaper to split your order into two shipments. Please see rates below.</label>
            <mat-checkbox color="primary" name="splitOrderToggle" [(ngModel)]="splitOrder" (change)="updateSplitOrders($event.checked)">Split order?</mat-checkbox>
          </div>

          <div class="flex flex-col gap-8">
            <div class="split-order-container flex flex-col gap-2"  [ngClass]="{'disabled': splitOrder}">
              <label>The following rates are for shipping all items in a combined shipment:</label>
              <div  class="shipping-options flex flex-col gap-2">
                <div class="flex justify-between" *ngFor="let rateDetail of combinedShippingRates?.rateDetail">
                  <mat-checkbox color="primary" class="checkbox" [disabled]="splitOrder" [checked]="isCombinedRateChosen(rateDetail)" (click)="preventToggle($event, isCombinedRateChosen(rateDetail))" (change)="changeCombinedRate(rateDetail)">{{rateDetail.name}}</mat-checkbox>
                  <div class="flex gap-1">
                    <label class="product-price" [ngClass]="{'product-price-strike-trough': showDiscountPrice(rateDetail)}">{{ rateDetail.shipCost | currency:'USD':'symbol':'0.2-2'}}</label>
                    <label class="product-discount-price" *ngIf="showDiscountPrice(rateDetail)">{{calculateDiscountRate(rateDetail)
                      | currency:'USD':'symbol':'0.2-2'}}</label>
                  </div>
                </div>

              </div>
            </div>


            <div class="split-order-container flex flex-col gap-4" [ngClass]="{'disabled': !splitOrder}">
              <label>The following items are perishable and must be shipped overnight.</label>
              <div *ngFor="let item of perishableItems">
                <label>{{item.itemDescription}}</label>
              </div>

              <div class="shipping-options flex flex-col gap-2">
                <div class="flex justify-between" *ngFor="let rateDetail of perishableShippingRates?.rateDetail">
                  <mat-checkbox color="primary" [disabled]="!splitOrder" [checked]="isPerishableRateChosen(rateDetail)" (click)="preventToggle($event, isPerishableRateChosen(rateDetail))" (change)="changePerishableRate(rateDetail)">{{rateDetail.name}}</mat-checkbox>
                  <div class="flex gap-1">
                    <label class="product-price" [ngClass]="{'product-price-strike-trough': showDiscountPrice(rateDetail)}">{{ rateDetail.shipCost | currency:'USD':'symbol':'0.2-2'}}</label>
                    <label class="product-discount-price" *ngIf="showDiscountPrice(rateDetail)">{{calculateDiscountRate(rateDetail)
                      | currency:'USD':'symbol':'0.2-2'}}</label>
                  </div>
                </div>

              </div>

              <div class="grey-divider"></div>

              <label>The following items are non-perishable:</label>
              <div *ngFor="let item of nonperishableItems">
                {{item.itemDescription}}<br>
              </div>

              <div class="shipping-options flex flex-col gap-2">
                <div class="flex justify-between" *ngFor="let rateDetail of nonperishableShippingRates?.rateDetail">
                  <mat-checkbox color="primary" class="checkbox" [disabled]="!splitOrder" [checked]="isNonPerishableRateChosen(rateDetail)" (click)="preventToggle($event, isNonPerishableRateChosen(rateDetail))" (change)="changeNonPerishableRate(rateDetail)">{{rateDetail.name}}</mat-checkbox>
                  <div class="flex gap-1">
                    <label class="product-price" [ngClass]="{'product-price-strike-trough': showDiscountPrice(rateDetail)}">{{ rateDetail.shipCost | currency:'USD':'symbol':'0.2-2'}}</label>
                    <label class="product-discount-price" *ngIf="showDiscountPrice(rateDetail)">{{calculateDiscountRate(rateDetail)
                      | currency:'USD':'symbol':'0.2-2'}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- No Split -->
      <div *ngIf="!splitShippingOption">

        <div *ngIf="orderArray[0].hasPerishables">
          <p>NOTE: Your order contains all perishable items, which require overnight shipping.</p>

          <div *ngFor="let item of perishableItems">
            {{item.itemDescription}}<br>
          </div>

          <div *ngIf="!perishableShippingRates?.checked">
            <p>{{fedexUnavailableRatesErrorMsg}}</p>
            <button mat-button class="checkout-button" (click)="getRates()">Try Again</button>
          </div>

          <div *ngIf="perishableShippingRates?.checked">
            <div class="shipping-options flex flex-col gap-2">
              <div class="flex justify-between" *ngFor="let rateDetail of perishableShippingRates?.rateDetail">
                <mat-checkbox color="primary" class="checkbox" [disabled]="splitOrder" [checked]="isPerishableRateChosen(rateDetail)" (click)="preventToggle($event, isPerishableRateChosen(rateDetail))" (change)="changePerishableRate(rateDetail)">{{rateDetail.name}}</mat-checkbox>
                <div class="flex gap-1">
                  <label class="product-price" [ngClass]="{'product-price-strike-trough': showDiscountPrice(rateDetail)}">{{ rateDetail.shipCost | currency:'USD':'symbol':'0.2-2'}}</label>
                  <label class="product-discount-price" *ngIf="showDiscountPrice(rateDetail)">{{calculateDiscountRate(rateDetail)
                    | currency:'USD':'symbol':'0.2-2'}}</label>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div *ngIf="orderArray[0].hasNonPerishables">
          <div *ngFor="let item of nonperishableItems">
            {{item.itemDescription}}<br>
          </div>

          <div *ngIf="!nonperishableShippingRates?.checked">
            <p>{{fedexUnavailableRatesErrorMsg}}</p>
            <button mat-flat-button color="primary" class="checkout-button" (click)="getRates()">Try Again</button>
          </div>

          <div *ngIf="nonperishableShippingRates?.checked">
            <div class="shipping-options flex flex-col gap-2" appMobileView>
              <div class="flex justify-between" *ngFor="let rateDetail of nonperishableShippingRates?.rateDetail">
                <mat-checkbox color="primary" class="checkbox" [disabled]="splitOrder" [checked]="isNonPerishableRateChosen(rateDetail)"  (change)="changeNonPerishableRate(rateDetail)">{{rateDetail.name}}</mat-checkbox>
                <div class="flex gap-1">
                  <label class="product-price" [ngClass]="{'product-price-strike-trough': showDiscountPrice(rateDetail)}">{{ rateDetail.shipCost | currency:'USD':'symbol':'0.2-2'}}</label>
                  <label class="product-discount-price" *ngIf="showDiscountPrice(rateDetail)">{{calculateDiscountRate(rateDetail)
                    | currency:'USD':'symbol':'0.2-2'}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="gift flex flex-col gap-2" *ngIf="!giftIsDisabled">
        <label class="shipping-title">Are there gifts in your order?</label>
        <div class="gift-container flex flex-wrap md:flex-nowrap gap-5">
          <div class="flex gap-5">
            <div class="gift-checkbox">
              <mat-checkbox color="primary" [checked]="!giftFlag" (change)="updateGiftFlag()">NO</mat-checkbox>
            </div>
            <div class="gift-checkbox" [ngClass]="{'sm:gift-message-active': giftFlag}">
              <mat-checkbox color="primary" class="checkbox" [checked]="giftFlag" (change)="updateGiftFlag()">YES</mat-checkbox>
            </div>
          </div>
          <div *ngIf="giftFlag" class="gift-message flex flex-col gap-2">
            <div class="flex justify-between">
              <div class="flex gap-2">
                <mat-checkbox color="primary" [(ngModel)]="giftReceipt">GIFT RECEIPT</mat-checkbox>
                <label class="gift-continue-message">with optional message</label>
              </div>
              <label>(No Charge)</label>
            </div>
            <mat-form-field>
              <textarea matInput placeholder="Write your message" [(ngModel)]="giftMessage" [maxlength]="giftMessageMax"></textarea>
            </mat-form-field>
            <label class="gift-info-message" >Message will be printed on invoice with prices omitted</label>
          </div>
        </div>
      </div>


    </div>

    <!--</div>-->


  </div>
  <div class="flex flex-col w-full md:w-1/4">
    <div class="checkout-grey-box" appMobileView>
      <app-checkout-summary [orderArray]="orderArray"></app-checkout-summary>

      <div class="checkout-sidebar-section action flex justify-center" >
        <button mat-button class="checkout-button" (click)="next()" [disabled]="!validateInputs()">Continue</button>
      </div>
    </div>

    <div class="checkout-grey-box" appMobileView>
      <div class="promo-container flex flex-col justify-center gap-4">
        <div class="flex flex-col justify-center">
          <label class="promo-active" *ngIf="orderArray && orderArray[0] && orderArray[0].promoCode">Promo Code {{orderArray[0].promoCode}} is currently active. Want to use a different one?</label>
          <label class="promo-active" *ngIf="!orderArray || !orderArray[0] || !orderArray[0].promoCode">Have a promo code? </label>
          <label class="promo-active">Please go back to Shopping Cart to apply it.</label>
        </div>
        <div class="flex justify-center">
          <button mat-flat-button color="accent" class="white-button" (click)="return()">Return To Cart</button>
        </div>
      </div>
    </div>

    <div class="checkout-grey-box checkout-help-box flex flex-col" appMobileView>
      <label class="checkout-summary-title">Need Assistance?</label>
      <div class="flex flex-col gap-2">
        <label>Email:<br>
          <a class="checkout-email checkout-assistance" href="mailto:Humveeparts@amgeneral.com">Humveeparts@amgeneral.com</a></label>
        <label>Phone:<br>
          <a class="checkout-email checkout-assistance" href="tel:800-348-8833">(800) 348-6833</a></label>
      </div>

    </div>
  </div>
</div>

