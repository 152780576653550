<div class="bread-crumb-options flex flex-col justify-between gap-4" appMobileView>
  <div class="checkout-header flex justify-between">
    <div class="bread-crumbs flex" appMobileView>

            <span *ngIf="cartView < 5">
                <span>
                  <a routerLink="/">Home</a>
                </span>
                <i class="fa fa-angle-double-right divider"></i>
                <a (click)="showViewCart()" class="{{(cartView === 1) ? 'active':''}}">Shopping Cart</a>
                <span *ngIf="cartView > 1">
                    <i class="fa fa-angle-double-right divider"></i>
                    <a (click)="showShippingInformation()" class="{{(cartView === 2) ? 'active':''}}">Shipping Information</a>
                </span>
                <span *ngIf="cartView > 2">
                    <i class="fa fa-angle-double-right divider"></i>
                    <a  (click)="showShippingOptions()" class="{{(cartView === 3) ? 'active':''}}">Shipping Options</a>
                </span>
                <span *ngIf="cartView > 3">
                    <i class="fa fa-angle-double-right divider"></i>
                    <a  (click)="showPaymentOptions()" class="{{(cartView === 4) ? 'active':''}}">Payment Options</a>
                </span>
            </span>
      <span *ngIf="cartView === 5">
                <label class="order-complete-header">Order Confirmation</label>
            </span>


    </div>
  </div>
</div>

<div class="flex flex-col" [ngSwitch]="cartView">
  <app-view-cart *ngSwitchCase="CartView.VIEW_CART" (completed)="showShippingInformation()" ></app-view-cart>
  <app-shipping-information  *ngSwitchCase="CartView.SHIPPING_INFORMATION"
                             (returning)="showViewCart()"
                             (completed)="showShippingOptions()"></app-shipping-information>
  <app-shipping-options  *ngSwitchCase="CartView.SHIPPING_OPTIONS"
                         (returning)="showViewCart()"
                         (completed)="showPaymentOptions()"></app-shipping-options>
  <app-payment-options  *ngSwitchCase="CartView.PAYMENT_OPTIONS"
                        (returning)="showViewCart()"
                        (completed)="completeOrder()"></app-payment-options>
  <app-payment-complete *ngSwitchCase="CartView.PAYMENT_COMPLETE"></app-payment-complete>
</div>
