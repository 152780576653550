import {
  AfterViewChecked, AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input, OnDestroy, Renderer2
} from '@angular/core';
// import {WINDOW} from "@ng-toolkit/universal";

@Directive({
  selector: '[appImageLazyLoad]'
})
export class ImageLazyLoadDirective implements AfterViewChecked, OnDestroy {

  @Input() appImageLazyLoad: string;
  isLoaded: boolean = false;
  ieImgDiv: HTMLElement;

  // @Inject(WINDOW) private window: Window
  constructor(private element: ElementRef,
              private renderer: Renderer2,
              ) {
  }

  ngAfterViewChecked() {
    if (this.isLoaded) {
      return;
    }

    const el = this.element.nativeElement;

    // if(el.offsetTop < (this.window.innerHeight + this.window.pageYOffset)) {
    //   el.src = this.appImageLazyLoad;
    //   const userAgent = this.window.navigator.userAgent;
    //   const ieReg = /msie|Trident.*rv[ :]*11\./gi;
    //   const ie = ieReg.test(userAgent);
    //
    //   if (ie && this.element && this.element.nativeElement && this.element.nativeElement.parentElement) {
    //     this.ieImgDiv = this.window.document.createElement('div');
    //     this.ieImgDiv.className = this.element.nativeElement.className;
    //     this.ieImgDiv.style.backgroundImage = `url(${this.element.nativeElement.src})`;
    //
    //     this.renderer.addClass(this.ieImgDiv, 'custom-object-fit-contain');
    //
    //     this.element.nativeElement.parentElement.insertBefore(this.ieImgDiv, this.element.nativeElement);
    //     this.element.nativeElement.style['display'] = 'none';
    //   }
    //   this.isLoaded = true;
    // }
    this.isLoaded = true;
  }

  ngOnDestroy() {
    if (this.ieImgDiv) {
      this.element.nativeElement.parentElement.removeChild(this.ieImgDiv);
    }
  }


}
