import {Component, Input} from '@angular/core';
import {ProductReview} from "../../core/models/product-review.model";

@Component({
  selector: 'app-product-reviews',
  templateUrl: './product-reviews.component.html',
  styleUrls: ['./product-reviews.component.scss']
})
export class ProductReviewsComponent  {
  @Input() reviews: ProductReview[] = [];
  constructor(){}

}
