import {NavigationExtras} from '@angular/router';

export interface navItem {
	routerLink: string;
	icon: string;
	text: string;
	extras?: NavigationExtras;
}
export const navData: navItem[] = [
	{
		routerLink: '/dashboard',
		icon: 'format_list_bulleted',
		text: 'Dashboard'
	},
];
