<label class="checkout-summary-title ">Order Summary</label>

<div *ngIf="shoppingCart; then showShoppingCart else showOrders"></div>


<ng-template #showShoppingCart>
  <div class="checkout-sidebar-section flex justify-between">
    <span>Subtotal</span>
    <span>{{ shoppingCart.subtotal | currency:'USD':'symbol':'0.2-2'}}</span>
  </div>
</ng-template>

<ng-template #showOrders>
  <div class="flex flex-col gap-3" appMobileView>
    <label class="checkout-summary-title split-title" *ngIf="orderArray && orderArray.length > 1">
      You have chosen to split your order in two.
      Your payment method will be charged separately for each order.
    </label>
    <div *ngFor="let order of orderArray; let index = index" class="flex flex-col">
      <label class="checkout-summary-title" *ngIf="orderArray.length > 1">Order #{{index + 1}}</label>
      <div class="checkout-sidebar-section flex justify-between">
        <span>Subtotal</span>
        <span>{{ order.productTotal | currency:'USD':'symbol':'0.2-2'}}</span>
      </div>
      <div *ngIf="order.taxTotal && order.taxTotal !== 0" class="checkout-sidebar-section flex justify-between">
        <span>Est. Tax</span>
        <span>{{ order.taxTotal | currency:'USD':'symbol':'0.2-2'}}</span>
      </div>
      <div *ngIf="order.freight && order.freight !== 0" class="checkout-sidebar-section flex justify-between">
        <span>Est. Shipping</span>
        <span>{{ calculateFreight(order) | currency:'USD':'symbol':'0.2-2'}}</span>
      </div>
      <div class="checkout-sidebar-section order-total flex justify-between">
        <span>Order Total</span>
        <span>{{(order.taxTotal + calculateFreight(order) + order.productTotal) | currency:'USD':'symbol':'0.2-2'}}</span>
      </div>
    </div>
  </div>
</ng-template>
