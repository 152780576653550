import {Component, Input} from '@angular/core';
import {Feature} from '../../core/models/feature.model';
import {Store} from '@ngrx/store';
import {AppStore} from '../../core/models/state.model';
import {Product} from '../../core/models/product.model';
import {Category} from '../../core/models/category.model';
import {Router} from '@angular/router';
import {ContentPage} from '../../core/models/content-page.model';
import {FeatureTypeEnum} from "../../core/enums/feature-type.enum";

@Component({
    selector: 'app-featured-item',
    templateUrl: './featured-item.component.html',
    styleUrls: ['./featured-item.component.scss']
})
export class FeaturedItemComponent {

    @Input() featureItem: Feature;
    imageNotFound: boolean;
    products: Product[];
    categories: Category[];
    contentPages: ContentPage[];
    description: string;
    subCategories: Category[];

    constructor(private router: Router,
                private store: Store<AppStore>) {
        store.subscribe(store => {
            const state = store.state;
            this.products = state.products;
            this.categories = state.categories;
            this.contentPages = state.contentPages;
        });
    }


    imageError() {
        this.imageNotFound = true;
    }

    get link(): string {
        let link = '';
        if (this.featureItem) {
            switch (this.featureItem.type) {
                case FeatureTypeEnum.CATEGORY:
                    const category = this.categories[this.featureItem.featuredId];
                    if (category) {
                        link = `/products/${category.url}`;
                        this.findSubCategories(category);
                    }
                    break;
                case FeatureTypeEnum.PRODUCT:
                    const product = this.products.find(prod => prod.itemNumber === this.featureItem.featuredId.toString());
                    if (product) {
                        link = `/product/${product.url}`;
                    }
                    break;
                case FeatureTypeEnum.CONTENT:
                    const contentPage = this.contentPages[this.featureItem.featuredId];
                    link = contentPage.url;
                    break;
            }
        }
        return link;
    }

    goToRelated() {
        if (!this.link) {
            return;
        }
        this.router.navigateByUrl(this.link);
    }

    private findSubCategories(category: Category) {
        const categories = this.categories.filter(c => category.categoryRelations.findIndex(cr => cr.categoryId === c.id) > -1);
        this.subCategories = categories.sort(((a:Category, b: Category) => a.displaySeq - b.displaySeq));
    }

}
