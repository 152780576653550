import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './core/components/login/login.component';
import {UsersListComponent} from './maintenance/users/users-list/users-list.component';
import {ChangePasswordRequestComponent} from './core/components/change-password-request/change-password-request.component';
import {FirstLoginResetPasswordComponent} from './core/components/first-login-reset-password/first-login-reset-password.component';
import {RolesListComponent} from './maintenance/roles/roles-list/roles-list.component';
import {CodesListComponent} from './maintenance/codes/codes-list/codes-list.component';
import {ConfirmNavigateAwayGuard} from './shared/guards/confirm-navigate-away.guard';
import {AdminGuard} from './core/guards/admin.guard';
import {DashboardComponent} from "./dashboard/dashboard.component";
import {AuditLogComponent} from "./core/components/audit-log/audit-log.component";
import {AccountComponent} from "./core/components/account/account.component";
import {CategoryComponent} from "./category/category.component";
import {CategoryResolver} from "./core/resolvers/category.resolver";
import {ContentPageComponent} from "./content-page/content-page.component";
import {PageNotFoundComponent} from "./core/components/page-not-found/page-not-found.component";
import {ContentPageResolver} from "./core/resolvers/content-page.resolver";
import {ProductComponent} from "./product/product.component";
import {ProductResolver} from "./core/resolvers/product.resolver";
import {SearchResultsComponent} from "./search-results/search-results.component";
import {SearchResultsResolver} from "./core/resolvers/search-results.resolver";
import {CheckoutComponent} from "./checkout/checkout.component";
import {CheckoutResolver} from "./core/resolvers/checkout.resolver";
import {LandingPageComponent} from "./landing-page/landing-page.component";
import {LandingPageResolver} from "./core/resolvers/landing-page.resolver";
import {CheckEmailComponent} from "./shared/components/check-email/check-email.component";
import {VerifyComponent} from "./core/components/verify/verify.component";
import {OrderHistoryComponent} from './order-history/order-history.component';
import {OrderHistoryResolver} from './core/resolvers/order-history.resolver';
import {AccountDetailsComponent} from "./core/components/account-details/account-details.component";

const routes: Routes = [
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full'
	},
	{path: 'login', component: LoginComponent},
	{path: 'forgot-password', component: ChangePasswordRequestComponent},
	{path: 'new-login', component: FirstLoginResetPasswordComponent},
  {path: 'account', component: AccountComponent},
  {path: 'order-history', component: OrderHistoryComponent, resolve: { resolve: OrderHistoryResolver }},
  {path: 'audit-log', component: AuditLogComponent},
  {path: 'dashboard', component: LandingPageComponent, resolve: { resolve: LandingPageResolver}},
  {path: 'product/:id', component: ProductComponent, resolve: { resolve: ProductResolver }},
  {path: 'products/:id', component: CategoryComponent, resolve: { resolve: CategoryResolver }},
  {path: 'users', component: UsersListComponent, canActivate: [AdminGuard]},
	{path: 'codes', component: CodesListComponent, canActivate: [AdminGuard]},
	{path: 'roles', component: RolesListComponent, canActivate: [AdminGuard]},
  {path: 'search/:id', component: SearchResultsComponent, resolve: { resolve: SearchResultsResolver }, data: { title: 'Search' }},
  {path: 'check-email', component: CheckEmailComponent },
  {path: 'verify', component: VerifyComponent },
  {path: 'account-details', component: AccountDetailsComponent },
  {
    path: 'checkout',
    component: CheckoutComponent,
    data: { title: 'Checkout' },
    resolve: { resolve: CheckoutResolver }
  },
  {path: 'not-found', component: PageNotFoundComponent},
  { // Please keep declared last
    path: ':id',
    component: ContentPageComponent,
    resolve: { resolve: ContentPageResolver }
  },
	{path: '**', component: PageNotFoundComponent}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking'
})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
