<div class="bread-crumb-options flex flex-col justify-between gap-4">
    <div class="flex flex-col md:flex-row justify-between pb-4">
        <div class="bread-crumbs flex flex-row gap-2">
            <label class="order-breadcrumb-title">Order History</label>
        </div>

        <div class="options flex flex-row gap-5">
            <label class="date-filter-label">Viewing orders placed in:</label>
            <mat-form-field class="sort-by">
                <mat-select [(ngModel)]="dateFilter">
                    <mat-option *ngFor="let option of dateFilters" [value]="option">{{option.title}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>


<div class="flex flex-col gap-5">

    <div *ngIf="loading; then showLoading else showOrders"></div>

    <ng-template #showLoading>
        <mat-spinner class="text-center" [diameter]="35"></mat-spinner>
    </ng-template>


    <ng-template #showOrders>

        <div *ngIf="filteredOrders.length > 0; then showHistory else showNoHistory"></div>

        <ng-template #showNoHistory>
            <label class="section-title">No orders in the time frame.</label>
        </ng-template>

        <ng-template #showHistory>
            <div class="flex flex-col gap-5">
                <div *ngFor="let order of filteredOrders" class="order-history-card flex flex-col">
                    <div class="order-history-header flex flex-col gap-2.5">
                        <div class="flex flex-row justify-between">
                            <div class="flex flex-col">
                                <label>ORDER PLACED</label>
                                <label>{{order.orderDate | date:'short'}}</label>
                            </div>
                            <div class="flex flex-col">
                                <label>{{order.items.length}} Items</label>
                                <label>{{order.orderTotal | currency: 'USD':'symbol':'0.2-2'}} Total</label>
                            </div>
                            <div class="flex flex-col">
                                <label>SHIP TO</label>
                                <label>{{order.shipToName}}</label>
                            </div>
                            <div class="flex flex-col" *ngIf="order.s2kOrderNumber">
                                <label>ORDER #</label>
                                <label>{{order.s2kOrderNumber}}</label>
                            </div>
                            <div class="flex flex-col">
                                <label>WEB ORDER #</label>
                                <label>{{order.id}}</label>
                            </div>
                        </div>

                        <div class="flex flex-col" *ngIf="order.refundTotal">
                            <label class="order-refunded">A refund was issued on this order. Total amount of refund: {{order.refundTotal | currency:'USD':'symbol':'0.2-2'}}</label>
                        </div>
                    </div>
                    <div class="order-history-detail flex flex-col gap-5">
                        <div class="flex flex-row justify-between">
                            <label class="order-shipping-status">Order Status: {{getOrderStatusName(order.orderStatus) | titlecase }}<span *ngIf="order.orderStatus !== 'PROCESSING'"> {{order.shippingStatus}}</span></label>
                        </div>
                        <div class="flex flex-row flex-wrap justify-end gap-5">
                            <div *ngFor="let tracking of order.orderTrackingInfo; let i = index; let last = last" [ngClass]="{'tracking-button': true, 'last': last}">
                                <button mat-button class="blue-button" (click)="showTrackingHistory(tracking.url)" *ngIf="tracking.trackingNumber">Track package {{i+1}}</button>
                            </div>
                        </div>
                        <div class="order-history-items flex flex-col gap-5">
                            <div class="flex flex-row"  *ngFor="let item of order.items">
                                <a [routerLink]="'/product/' + item.url" class="clickable order-history-item flex flex-row gap-5">
                                    <img *ngIf="item.image" class="order-item-image" [src]="item.image.url">
                                    <img *ngIf="!item.image" class="order-item-image" alt="not found image" src="./assets/image-not-found.500.jpg"/>
                                    <div class="flex flex-col justify-between">
                                        <div class="flex flex-col">
                                            <label class="clickable">{{item.itemDescription}}</label>
                                            <label class="clickable">#{{item.itemNumber}}</label>
                                            <label class="clickable">NSN {{item.shortDescription}}</label>
                                            <label class="clickable order-item-refund" *ngIf="item.refundAmount || item.refundQty">{{item.refundQty}} refunded for {{item.refundAmount | currency:'USD':'symbol':'0.2-2'}}</label>
                                        </div>
                                        <div class="flex flex-col">
                                            <label class="order-price clickable">
                                                {{item.price | currency:'USD':'symbol':'0.2-2'}} x {{item.quantity}} = {{item.price * item.quantity | currency:'USD':'symbol':'0.2-2'}}</label>
                                          <div class="flex items-center">
                                            <app-add-to-cart [product]="getItemAsProduct(item)" [qty]="item.quantity" [style]="addToCartButton" class="do-no-go" [isDisabled]="getAddToCartDisabled(item)"></app-add-to-cart>
                                            <button
                                              mat-icon-button
                                              color="primary"
                                              (click)="openWriteReviewModal($event, item)"
                                              matTooltip="Write a review for this product"
                                            >
                                              <mat-icon>rate_review</mat-icon>
                                            </button>
                                          </div>

                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

    </ng-template>

</div>

