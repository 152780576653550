import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Tag} from "../../../core/models/tag.model";

@Component({
	selector: 'app-mobile-category-filter-dialog',
	templateUrl: './mobile-category-filter.dialog.component.html',
	styleUrls: ['./mobile-category-filter.dialog.component.scss']
})
export class MobileCategoryFilterDialogComponent implements OnInit {

	constructor(private dialog: MatDialogRef<MobileCategoryFilterDialogComponent>,
				@Inject(MAT_DIALOG_DATA) public data: { selectedFilters: number[], filterGroups: { title: string, groupId: number, tags: Tag[] }[] }) {
	}

	ngOnInit() {
	}

	isFilterSelected(filter: Tag): boolean {
		return this.data.selectedFilters.includes(filter.id);
	}

	filterIsSelected(filter: Tag) {
		const index = this.data.selectedFilters.indexOf(filter.id);
		if (index > -1) {
			this.data.selectedFilters.splice(index, 1);
		} else {
			this.data.selectedFilters.push(filter.id);
		}
	}

	close(newFilters?: number[]) {
		this.dialog.close(newFilters);
	}

	submit() {
		this.close(this.data.selectedFilters);
	}

}
