import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {State} from '@ngrx/store'
import {SearchResult} from "../core/models/search-result.model";
import {Tag} from "../core/models/tag.model";
import {TagGroup} from "../core/models/tag-group.model";
import {AccountFilter} from "../core/models/account-filter.model";
import {Category} from "../core/models/category.model";
import {MatDialog} from "@angular/material/dialog";
import {AppStore} from "../core/models/state.model";
import {Product} from "../core/models/product.model";
import {TagRelation} from "../core/models/tag-relation.model";
import {
  MobileCategoryFilterDialogComponent
} from "../shared/components/mobile-category-filter-dialog/mobile-category-filter.dialog.component";
import {MajorCategoryEnum} from '../core/enums/major-category.enum';

@Component({
	selector: 'app-search-results',
	templateUrl: './search-results.component.html',
	styleUrls: ['./search-results.component.scss']
})

export class SearchResultsComponent implements OnInit {

	searchParam: string;              // the term (parameter)
	searchResult: SearchResult;       // result storage
	isSearchActive: boolean;          // whether request in progress
	tags: Tag[];
	tagGroups: TagGroup[];
	accountFilters: AccountFilter[];
	categories: Category[];
	categoryUrl: string;
	selectedFilters: number[] = [];
	filterGroups: { title: string, groupId: number, tags: Tag[] }[] = [];
  majorCategories: string[] = Object.values(MajorCategoryEnum);


	constructor(private router: Router,
				private route: ActivatedRoute,
				private dialog: MatDialog,
				private store: State<AppStore>) {
		store.subscribe(subscribedStore => {
			const state = subscribedStore.state;
			this.tagGroups = state.tagGroups;
			this.tags = state.tags;
			this.categories = state.categories;
			this.accountFilters = state.accountFilters;
			this.selectedFilters = this.accountFilters ? this.accountFilters?.map(af => af.tagId) : [];
		});
	}

	ngOnInit() {
		this.route.params.subscribe((params: Params) => {
			this.searchParam = params['id'];
			if (!this.searchParam) {
				this.notFound();
			} else {
				this.performSearch();
			}
		});

	}

	performSearch() {
		this.isSearchActive = true;
		this.searchResult = new SearchResult();
		this.route.data.subscribe(resolve => {
				const result: SearchResult = resolve['resolve'];
				this.isSearchActive = false;

				if (!result) {
					return false;
				}

				if (result.products.length + result.categories.length === 1) {

          let prefix: string = '';
          let url: string = '';
          if (result.products.length) {
            prefix = 'product';          // product
            url = result.products[0].url;
          } else {
            prefix = 'products';        // category
            url = result.categories[0].url
          }
          this.router.navigateByUrl(`/${prefix}/${url}`);
				}

				if (result.products.length > 0 || result.categories.length > 0) {
					this.searchResult = result;
					this.getFilterGroups();
				}
        return true;
			},
			error => {
				this.isSearchActive = false;
				console.error(error);
			});
	}

	notFound() {
		this.router.navigateByUrl('/search/not-found', {skipLocationChange: true});
	}


	isFilterSelected(filter: Tag): boolean {
		return this.selectedFilters?.includes(filter.id);
	}

	filterIsSelected(filter: Tag) {
		const index = this.selectedFilters?.indexOf(filter.id);
		if (index > -1) {
			this.selectedFilters.splice(index, 1);
		} else {
			this.selectedFilters.push(filter.id);
		}
	}

	get category(): Category {
		let category = null;

		if (this.categories && this.categoryUrl) {
			category = this.categories.filter(cat => cat.url).find(cat => cat.url === this.categoryUrl);
		}

		return category ? category : new Category();
	}

  get filteredCategories(): Category[] {
    return this.searchResult?.categories?.
    filter((category: Category) => !this.majorCategories.includes(category?.name?.toLowerCase())); // Excluding the header categories
  }

	get categoryProducts(): Product[] {
    if (!this.searchResult) {
      return [];
    }
		return this.searchResult.products.filter(
			prod => this.searchResult.productCategories.findIndex(pc => pc.itemNumber === prod.itemNumber) > -1
		);
	}

	get filteredAndSortedProducts(): Product[] {
		if (this.searchResult.categories.length > 0 && this.searchResult.productCategories.length > 0 && this.searchResult.products.length > 0) {
			return this.searchResult.products
				// .filter(prod => this.searchResult.productCategories.findIndex(pc => pc.itemNumber === prod.itemNumber) > -1)
				.filter(prod => this.selectedFilters?.length ? this.selectedFilters.every(filter => prod.tagRelations.findIndex(tag => tag.tagId === filter) > -1) : true)
				.sort((a, b) => this.productSort(a, b));
		} else {
			return this.searchResult.products.sort((a, b) => this.productSort(a, b));
		}
	}

	get productBottomRow(): number {
		const totalRows =  this.filteredAndSortedProducts.length / 3;

		const secondToLastRow = Math.ceil(totalRows - 1);

		const numberOfItemsInRows = secondToLastRow * 3;

		const lastIndex = numberOfItemsInRows - 1;

		return  lastIndex
	}

	get categoryBottomRow(): number {
		const totalRows =  this.searchResult.categories.length / 3;

		const secondToLastRow = Math.ceil(totalRows - 1);

		const numberOfItemsInRows = secondToLastRow * 3;

		const lastIndex = numberOfItemsInRows - 1;

		return  lastIndex
	}

	productSort(productA: Product, productB: Product): number {
		if (productA.displayName < productB.displayName) {
			return -1;
		} else if (productA.displayName > productB.displayName) {
			return 1;
		} else {
			return 0;
		}
	}

	getFilterGroups() {

		this.filterGroups = [];

		if (this.tagGroups && this.tags) {
			const filterTagGroups: TagGroup[] = this.tagGroups.filter(tg => tg.filter);
			const tagRelations: TagRelation[] = this.categoryProducts.flatMap(prod => prod.tagRelations);

			const filters = this.tags.filter(tag => filterTagGroups.findIndex(tg => tg.id === tag.tagGroupId) > -1)
				.filter(tag => tagRelations.findIndex(tr => tr.tagId === tag.id) > -1 || this.selectedFilters?.includes(tag.id));

			filters.forEach((filter) => {
				const index = this.filterGroups.findIndex(fg => fg.groupId === filter.tagGroupId);

				if (index > -1) {
					this.filterGroups[index].tags.push(filter);
					this.filterGroups[index].tags.sort((a, b) => {
						if (a.name < b.name) {
							return -1;
						} else if (a.name > b.name) {
							return 1;
						} else {
							return 0;
						}
					});
				} else {
					const tagGroup = filterTagGroups.find(tg => tg.id === filter.tagGroupId);
					this.filterGroups.push({
						title: tagGroup?.name ?? '',
						groupId: filter.tagGroupId,
						tags: [filter]
					});
				}
			});

			this.filterGroups.sort((a, b) => {
				if (a.title < b.title) {
					return -1;
				} else if (a.title > b.title) {
					return 1;
				} else {
					return 0;
				}
			});
		}
	}

	openFilterDialog() {
		const dialogRef = this.dialog.open(MobileCategoryFilterDialogComponent, {
			width: '80%',
			data: {
				filterGroups: this.filterGroups.slice(),
				selectedFilters: this.selectedFilters.slice()
			}
		});

		dialogRef.afterClosed().subscribe(newFilters => {
			if (!newFilters) {
				return;
			}


			this.selectedFilters = newFilters;
		});
	}

}
