import {Product} from "./product.model";
import {FeatureTypeEnum} from "../enums/feature-type.enum";

export class Feature {
    id: number;
    carousel: boolean;
    displaySeq: number;
    imageFilename: string;
    description: string;
    title: string;
    featuredId: number;
    deleted: boolean;
    active: boolean;
    type: FeatureTypeEnum;
    product?: Product;
}
