<ng-container *ngIf="!loading; else loadingContent">
  <div *ngIf="verificationSuccess; then success else error"></div>
</ng-container>

<ng-template #loadingContent>
  <h2 class="text-center bg-background">Loading...</h2>
</ng-template>

<ng-template #success>
  <h2 class="text-center bg-background">Verification Success!</h2>
</ng-template>

<ng-template #error>
  <h2 class="text-center bg-background">Verification Failed!</h2>
</ng-template>

<app-login></app-login>
