import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Order} from '../models/order.model';
import {Observable} from 'rxjs';
import {OrderItem} from '../models/order-item.model';
import {ConfirmedOrder} from '../models/confirmed-order.model';
import {OrderHistory} from '../models/order-history.model';

@Injectable()
export class OrderService {

    readonly baseUrl = `${environment.url}/order`;

    constructor(private http: HttpClient) {
    }

    get(): Observable<Order[]> {
        return this.http.get<Order[]>(`${this.baseUrl}`);
    }

    getItems(): Observable<{ success: boolean, result: { perishable: OrderItem[], nonperishable: OrderItem[] } }> {
        return this.http.get<{ success: boolean, result: { perishable: OrderItem[], nonperishable: OrderItem[] } }>(`${this.baseUrl}/items`);
    }

    prepare(): Observable<boolean> {
        return this.http.get<boolean>(`${this.baseUrl}/prepare`);
    }

    verify(code: string): Observable<boolean> {
      return this.http.post<boolean>(`${this.baseUrl}/visual-verify`, {code});
    }

    getGiftDisabledStatus(): Observable<boolean> {
      return this.http.get<boolean>(`${this.baseUrl}/get-gift-disabled-status`);
    }

    getOrdersReadyForShippingDecisions(): Observable<Order[]> {
      return this.http.get<Order[]>(`${this.baseUrl}/get-orders-ready-for-shipping-decisions`);
    }

    addShippingAddress(order: Order, accepted?: boolean): Observable<boolean> {
        let params = new HttpParams();
        if (accepted) {
            params = params.set('accepted', 'true');
        }
        return this.http.post<boolean>(`${this.baseUrl}/shippingAddress`, order, {params});
    }

    chooseShippingMethod(orders: Order[]): Observable<boolean> {
        return this.http.post<boolean>(`${this.baseUrl}/choose-shipping-method`, {rates: orders});
    }

    // createOrder(body: Order): Observable<{success: boolean, result: Order}> {
    //     return this.http.post<{success: boolean, result: Order}>(`${this.baseUrl}`, body);
    // }
    //
    // updateOrder(body: Order): Observable<{success: boolean, result: Order}> {
    //     return this.http.put<{success: boolean, result: Order}>(`${this.baseUrl}/${body.id}`, body);
    // }

    // processOrder(body: Order): Observable<{ success: boolean, result: Order }> {
    //     return this.http.post<{ success: boolean, result: Order }>(`${this.baseUrl}/process`, body);
    // }

    processOrders(body: Order[]): Observable<Order[]> {
        return this.http.post<Order[]>(`${this.baseUrl}/process`, body);
    }

    getConfirmOrders(): Observable<ConfirmedOrder> {
        return this.http.get<ConfirmedOrder>(`${this.baseUrl}/confirm`);
    }

    updateOrderStripeInfo(stripeInfo: Partial<Order>) {
      return this.http.patch<Order>(`${this.baseUrl}/update-stripe-info`,stripeInfo)
    }

    getHistory(): Observable<OrderHistory[]> {
      return this.http.get<OrderHistory[]>(`${this.baseUrl}/history`)
    }
}
