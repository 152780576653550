import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Store} from "@ngrx/store";
import {AppStore} from "../models/state.model";
import {Tag} from "../models/tag.model";
import {AccountFilter} from "../models/account-filter.model";
import {UpdateAccountFilters} from "../actions/state.actions";

@Injectable()
export class AccountFilterService {

  readonly baseUrl = `${environment.url}/api/filter`;

  constructor(private http: HttpClient,
              private store: Store<AppStore>) {
  }

  createFilter(tag: Tag) {
    this.http.post<Response>(this.baseUrl, {tagId: tag.id}).subscribe(success=> this.handleCallBack(success));
  }

  deleteFilter(filter: Tag) {
    this.http.delete<Response>(`${this.baseUrl}/${filter.id}`).subscribe(success=> this.handleCallBack(success));
  }

  private handleCallBack(response: Response) {
    this.store.dispatch(new UpdateAccountFilters(response.result))
  }
}

class Response {
  success: boolean;
  result: AccountFilter[]
}
