import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProductComponent} from "./product.component";
import {SharedModule} from "../shared/shared.module";
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {MatTooltipModule} from "@angular/material/tooltip";
import {CartService} from "../core/services/cart.service";
import {SeoService} from "../core/services/seo.service";
import {FavoritesService} from "../core/services/favorites.service";
import {ProductsService} from "../core/services/products.service";
import {ImageViewerComponent} from "./image-viewer/image-viewer.component";
import { ProductReviewsComponent } from './product-reviews/product-reviews.component';
import {MatCardModule} from "@angular/material/card";
import {MatDividerModule} from "@angular/material/divider";

@NgModule({
  declarations: [
    ProductComponent,
    ImageViewerComponent,
    ProductReviewsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    RouterModule,
    MatTooltipModule,
    MatCardModule,
    MatDividerModule
  ],
  providers: [
    CartService,
    SeoService,
    FavoritesService,
    ProductsService
  ]
})
export class ProductModule { }
