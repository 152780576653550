<a class="list-container flex justify-between gap-5 md:gap-2 md:mobile-view" [routerLink]="'/product/' + product.url">
  <div class="product-details flex flex-col w-1/2 md:3/5">
    <label class="product-name clickable mb-1">{{product.displayName}}</label>
    <label class="product-item-number">ITEM #{{product.itemNumber}}</label>
    <label class="product-item-number">NSN {{product.shortDescription}}</label>
    <label class="product-item-number">WEIGHT {{product.itemWeight}} {{product.itemWeightUom ? product.itemWeightUom : 'lbs'}}</label>
  </div>
<!--  <div class="w-1/3 md:invisible">-->
<!--    <div class="product-list-image">-->
<!--      <img [src]="primaryImage.url" [alt]="primaryImage.alt" class="product-image-small center-vertical clickable" *ngIf="!imageNotFound" (error)="imageError()"/>-->
<!--      <img class="center-vertical product-image-small clickable" alt="Not found" aria-hidden="true"-->
<!--           *ngIf="imageNotFound" src="./assets/image-not-found.500.jpg">-->
<!--    </div>-->
<!--  </div>-->
  <div class="flex flex-col justify-end w-1/3 gap-2 min-w-[100px] p-4">
    <div class="product-list-image ">
      <img [src]="primaryImage.url" [alt]="primaryImage.alt" class="product-image-small center-vertical clickable" *ngIf="!imageNotFound" (error)="imageError()"/>
      <img class="center-vertical product-image-small clickable" alt="Not found" aria-hidden="true"
           *ngIf="imageNotFound" src="./assets/image-not-found.500.jpg">
    </div>
    <span class="product-price ">{{product.productPrice.price | currency:'USD':'symbol':'0.2-2'}}</span>
    <app-add-to-cart class="self-end md:self-auto do-not-go m-auto mt-4" [style]="addToCartStyle" [product]="product"
                     [isDisabled]="disableAddToCart()"></app-add-to-cart>
  </div>

</a>
