import {Component, Input, OnInit} from '@angular/core';
import {Product} from '../../../core/models/product.model';
import {Image} from '../../../core/models/image.model';
import {CartService} from '../../../core/services/cart.service';
import {FavoritesService} from '../../../core/services/favorites.service';
import {Store} from '@ngrx/store';
import {AppStore} from '../../../core/models/state.model';
import {Router} from '@angular/router';
import {FavoriteProduct} from '../../../core/models/favorite-product.model';
import {Tag} from '../../../core/models/tag.model';
import {TagGroup} from '../../../core/models/tag-group.model';
import {AddToCartEnum} from '../../../core/enums/add-to-cart.enum';

@Component({
  selector: 'app-product-card-list',
  templateUrl: './product-card-list.component.html',
  styleUrls: ['./product-card-list.component.scss']
})
export class ProductCardListComponent implements OnInit {

  @Input() product: Product;
  favoriteProducts: FavoriteProduct[] = [];
  imageNotFound: boolean = true;
  primaryImage: Image;
  tags: Tag[];
  tagGroups: TagGroup[];
  quantityDisabled: boolean = false;

  addToCartStyle = AddToCartEnum.ICON;

  constructor(private cartService: CartService,
              private favoritesService: FavoritesService,
              private store: Store<AppStore>,
              private router: Router) {
    store.subscribe(store => {
      const state = store.state;
      this.favoriteProducts = state.favoriteProducts;
      this.tags = state.tags;
      this.tagGroups = state.tagGroups;
      this.quantityDisabled = state.quantityIsDisabled;
    });
  }

  ngOnInit() {
    this.getPrimaryImage();
  }

  get isFavorite(): boolean {
    if (this.favoriteProducts && this.product) {
      const index = this.favoriteProducts.findIndex(fp => fp.itemNumber === this.product.itemNumber);
      return index > -1;
    }
    return false
  }

  get package(): string {
    let packageSize = null;
    let packageType = null;
    if (this.tags && this.tagGroups && this.product && this.product.itemNumber && this.product.tagRelations && this.product.tagRelations.length > 0) {
      const packageSizeGroup = this.tagGroups.find(tg => tg.section === 'PACKAGE_SIZE');
      packageSize = this.tags.filter(tag => tag.tagGroupId === packageSizeGroup?.id)
        .find(tag => this.product.tagRelations ? this.product.tagRelations.findIndex(tr => tr.tagId === tag.id) > -1 : false);

      const packageTypeGroup = this.tagGroups.find(tg => tg.section === 'PACKAGE_TYPE');
      packageType = this.tags.filter(tag => tag.tagGroupId === packageTypeGroup?.id)
        .find(tag => this.product.tagRelations ? this.product.tagRelations.findIndex(tr => tr.tagId === tag.id) > -1 : false);
    }
    return `${packageSize ? packageSize.name : ''} ${packageType ? packageType.name : ''}`;
  }

  getPrimaryImage() {
    if (this.product && this.product.images) {
      const images = this.product.images.sort((a, b) => a.displaySeq - b.displaySeq);
      if (images[0]) {
        this.imageNotFound = false;
        this.primaryImage = images[0];
      }
    }
  }

  addToFavorites(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.favoritesService.addFavoriteProduct(this.product.itemNumber);
  }

  deleteFromFavorites(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.favoritesService.deleteFavoriteProduct(this.product.itemNumber);
  }

  imageError() {
    this.imageNotFound = true;
  }

  goToProduct(event: any) {
    this.router.navigateByUrl(`/product/${this.product.url}`);
  }

  disableAddToCart(): boolean {
    return (this.product.productAvailability.availableQuantity <= 0 && !this.quantityDisabled) || this.product.productPrice.price <= 0
  }
}
