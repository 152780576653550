import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
// import {MatInputModule} from '@angular/material/input';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './core/components/login/login.component';
import {AuthInterceptorProviders} from './core/interceptors/auth.interceptor';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {AuthService} from './core/services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CookieService} from 'ngx-cookie-service';
import {AppInitProvider, AppInitService} from './core/services/app-init.service';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {SharedModule} from './shared/shared.module';
import {MatRadioModule} from '@angular/material/radio';
import {MainToolbarComponent} from './core/components/main-toolbar/main-toolbar.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatBadgeModule} from '@angular/material/badge';
import {SideNavComponent} from './core/components/side-nav/side-nav.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {FooterComponent} from './core/components/footer/footer.component';
import {ChangePasswordRequestComponent} from './core/components/change-password-request/change-password-request.component';
import {FirstLoginResetPasswordComponent} from './core/components/first-login-reset-password/first-login-reset-password.component';
import {MaintenanceModule} from './maintenance/maintenance.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {NotificationsComponent} from './notifications/notifications.component';
import {MatListModule} from '@angular/material/list';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AuditLogComponent} from './core/components/audit-log/audit-log.component';
import {AuditLogDisplayComponent} from './core/components/audit-log/audit-log-display/audit-log-display.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AccountComponent} from './core/components/account/account.component';
import {provideClientHydration} from '@angular/platform-browser';
import {HeaderComponent} from './core/components/header/header.component';
import {CatalogSearchService} from './core/services/catalog-search.service';
import {LoginService} from './core/services/login.service';
import {StorageService} from './core/services/storage.service';
import {StateReducer} from './core/reducers/state.reducer';
import {StoreModule} from '@ngrx/store';
import {CategoryModule} from './category/category.module';
import {CommonModule} from '@angular/common';
import {ContentPageComponent} from './content-page/content-page.component';
import {ContentPageService} from './core/services/content-page.service';
import {PageNotFoundComponent} from './core/components/page-not-found/page-not-found.component';
import {ContentPageResolver} from './core/resolvers/content-page.resolver';
import {CategoryService} from './core/services/category.service';
import {SeoService} from './core/services/seo.service';
import {FavoritesResolver} from './core/resolvers/favorites.resolver';
import {SearchResultsResolver} from './core/resolvers/search-results.resolver';
import {CategoryResolver} from './core/resolvers/category.resolver';
import {CheckoutResolver} from './core/resolvers/checkout.resolver';
import {OrderHistoryResolver} from './core/resolvers/order-history.resolver';
import {LandingPageResolver} from './core/resolvers/landing-page.resolver';
import {SitemapResolver} from './core/resolvers/sitemap.resolver';
import {FavoritesService} from './core/services/favorites.service';
import {OrderService} from './core/services/order.service';
import {SearchResultsComponent} from './search-results/search-results.component';
import {CategoryCardComponent} from './search-results/category-card/category-card.component';
import {ProductModule} from './product/product.module';
import {ProductResolver} from './core/resolvers/product.resolver';
import {CheckoutModule} from './checkout/checkout.module';
import {LandingPageModule} from './landing-page/landing-page.module';
import {PaymentService} from './core/services/payment.service';
import {VerifyComponent} from './core/components/verify/verify.component';
import {VerifyService} from "./core/services/verify.service";
import {NgxStripeModule} from "ngx-stripe";
import { environment } from '../environments/environment'
import {OrderHistoryComponent} from './order-history/order-history.component';
import {ProductReviewWriteComponent} from "./order-history/product-review-write/product-review-write.component";
import {ProductReviewService} from "./core/services/product-review.service";
import {AccountDetailsComponent} from "./core/components/account-details/account-details.component";
import {AccountFilterService} from "./core/services/account-filter.service";

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		MainToolbarComponent,
		SideNavComponent,
		FooterComponent,
		FirstLoginResetPasswordComponent,
		ChangePasswordRequestComponent,
		NotificationsComponent,
		DashboardComponent,
		AuditLogComponent,
		AuditLogDisplayComponent,
		AccountComponent,
		ContentPageComponent,
		PageNotFoundComponent,
		HeaderComponent,
		SearchResultsComponent,
		CategoryCardComponent,
    OrderHistoryComponent,
		VerifyComponent,
    ProductReviewWriteComponent,
    AccountDetailsComponent
	],
	imports: [
		BrowserModule,
		CommonModule,
		CategoryModule,
		FormsModule,
		CheckoutModule,
		ProductModule,
		LandingPageModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		MatCardModule,
		MatTableModule,
		MatFormFieldModule,
		MatInputModule,
		MatSortModule,
		MatIconModule,
		MatTableModule,
		MatPaginatorModule,
		ReactiveFormsModule,
		MatDialogModule,
		MatButtonModule,
		MatAutocompleteModule,
		SharedModule,
		MatRadioModule,
		MatMenuModule,
		MatBadgeModule,
		MatSidenavModule,
		MaintenanceModule,
		MatProgressSpinnerModule,
		MatDatepickerModule,
		MatSelectModule,
		MatListModule,
		MatTooltipModule,
    MatCheckboxModule,
    NgxStripeModule.forRoot(environment.stripeTestPublicKey),

		StoreModule.forRoot({
			// @ts-ignore
			state: StateReducer
		})
	],
	providers: [
		provideClientHydration(),
		AppComponent,
		AuthService,
		AuthInterceptorProviders,
		MatSnackBar,
		CookieService,
		AppInitProvider,
		AppInitService,
		CatalogSearchService,
		ContentPageService,
    VerifyService,
		ContentPageResolver,
		SearchResultsResolver,
		LoginService,
		SeoService,
		StorageService,
		CategoryService,
		SeoService,
		OrderService,
		PaymentService,
    ProductReviewService,
    AccountFilterService,
		FavoritesService,
		LandingPageResolver,
		CategoryResolver,
		ProductResolver,
		FavoritesResolver,
		OrderHistoryResolver,
		ContentPageResolver,
		SearchResultsResolver,
		CheckoutResolver,
		SitemapResolver,
		{provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline', subscriptSizing: 'dynamic'}}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
