<div *ngIf="style" [ngSwitch]="style">

  <div *ngSwitchCase="enum.ICON">
    <div *ngIf="!waiting">
      <i *ngIf="success === true" class="fa fa-check green"></i>
      <i *ngIf="success === false" class="fa fa-times red"></i>
      <a *ngIf="success === null && !isDisabled"  class="product-button" (click)="add($event)">
        ADD TO CART<!-- mat-icon svgIcon="cart"></mat-icon -->
      </a>
      <a *ngIf="success === null && isDisabled" matTooltip="Out of Stock" class="product-button-disabled">
        ADD TO CART<!-- mat-icon svgIcon="cart"></mat-icon-->
      </a>
    </div>
    <div *ngIf="waiting">
      <mat-spinner [diameter]="22"></mat-spinner>
    </div>
  </div>

  <div *ngSwitchCase="enum.BUTTON" class="button-size">
    <div class="text-center">
      <div *ngIf="!waiting">
        <i *ngIf="success === true" class="fa fa-check green"></i>
        <i *ngIf="success === false" class="fa fa-times red"></i>
        <button *ngIf="success === null && !isDisabled" (click)="add($event)" [disabled]="isDisabled" mat-button class="product-button">ADD TO CART</button>
        <button *ngIf="success === null && isDisabled" (click)="add($event)" [disabled]="isDisabled" mat-button class="product-button">ADD TO CART</button>
      </div>
      <div *ngIf="waiting">
        <mat-spinner class="text-center" [diameter]="22"></mat-spinner>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="enum.BUY_IT_AGAIN_BUTTON" class="small-button-size">
    <div class="text-center">
      <div *ngIf="!waiting">
        <i *ngIf="success === true" class="fa fa-check green"></i>
        <i *ngIf="success === false" class="fa fa-times red"></i>
        <button *ngIf="success === null" (click)="add($event)" mat-button class="blue-button small-button-size">BUY IT AGAIN</button>
      </div>
      <div *ngIf="waiting">
        <mat-spinner class="text-center" [diameter]="22"></mat-spinner>
      </div>
    </div>
  </div>

</div>

