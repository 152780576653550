import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryComponent } from './category.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { MobileCategorySubCategoriesComponent } from './dialogs/mobile-category-sub-categories/mobile-category-sub-categories.component';
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import { ProductCardCartComponent } from './product-cards/product-card-cart/product-card-cart.component';
import { ProductCardGalleryComponent } from './product-cards/product-card-gallery/product-card-gallery.component';
import { ProductCardListComponent } from './product-cards/product-card-list/product-card-list.component';
import {CartService} from "../core/services/cart.service";
import {SharedModule} from "../shared/shared.module";

@NgModule({
  declarations: [
    CategoryComponent,
    SubCategoryComponent,
    MobileCategorySubCategoriesComponent,
    ProductCardCartComponent,
    ProductCardGalleryComponent,
    ProductCardListComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    RouterModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule
  ], providers: [ CartService],
  exports: [
    ProductCardGalleryComponent,
    ProductCardCartComponent
  ]
})
export class CategoryModule { }
