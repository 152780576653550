<div *ngIf="displayMode; then display else edit"></div>

<ng-template #edit>
  <button
    mat-icon-button
    color="accent"
    *ngFor="let ratingId of ratingArr;index as i"
    (click)="onClick(i+1)">
    <mat-icon>
      {{showIcon(i)}}
    </mat-icon>
  </button>
</ng-template>

<ng-template #display>
  <mat-icon *ngFor="let ratingId of ratingArr;index as i" color="primary">
    {{showIcon(i)}}
  </mat-icon>
</ng-template>

