import { Component, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Account } from '../../models/account.model';
import { LoginService } from '../../services/login.service';
import { PaymentService } from '../../services/payment.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppStore } from '../../models/state.model';
import { LoginUser, LogoutUser } from '../../actions/state.actions';
import { StripeCustomer } from '../../models/stripe.model';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { StorageService } from "../../services/storage.service";
import { StorageEnum } from "../../enums/storage.enum";
import {SeoService} from '../../services/seo.service';
import { CheckEmailEnum } from '../../enums/check-email.enum';
import {User} from "../../models/user.interface";
import {LoginResponse} from "../../models/login-response.interface";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {

  userEmailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  userPasswordFormControl = new FormControl('', [
    Validators.required
  ]);

  newFirstNameFormControl = new FormControl('', [
    Validators.required
  ]);

  newLastNameFormControl = new FormControl('', [
    Validators.required
  ]);

  newEmailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  newPasswordFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$')
  ]);

  user: User = new User();
  newUser: User = new User();
  incorrectEmail = false;
  isVerified = true;
  signUpDisabled: boolean = false;
  creatingAccount = false;
  loggedInUser: User;
  stripeCustomer: StripeCustomer;
  snackBarConfig = new MatSnackBarConfig();
  snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  returnUrl: string = '/';

  constructor(private loginService: LoginService,
              private paymentService: PaymentService,
              private route: ActivatedRoute,
              private router: Router,
              private store: Store<AppStore>,
              private storageService: StorageService,
              private seoService: SeoService,
              private snackBar: MatSnackBar) {
    store.forEach(subscribedStore => {
      const state = subscribedStore.state;
      if (state.user) this.loggedInUser = state.user;
      // this.stripeCustomer = state.stripeCustomer;
    });

    this.snackBarConfig.horizontalPosition = 'center';
    this.snackBarConfig.verticalPosition = 'top';
    this.snackBarConfig.panelClass = 'snackBar';
  }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParam => {
      this.returnUrl = queryParam['returnUrl'] ? queryParam['returnUrl'] : '/';
      if (queryParam['returnUrl']) {
        const url: string = queryParam['returnUrl'].substring(1).replace('-', ' ')
          .replace(/(^\w{1})|(\s{1}\w{1})/g, (match: string) => match.toUpperCase());
        const title = `${url} > Login | Grow Generation`;
        this.seoService.setTitle(title);
        this.seoService.setMetaTitle(title);
        this.seoService.setMetaDescription(`Grow Generation ${url} > Login`);
        this.seoService.setMetaKeywords(`Content ${title}`);
      } else {
        const title = `Login | Grow Generation`;
        this.seoService.setTitle(title);
        this.seoService.setMetaTitle(title);
        this.seoService.setMetaDescription('Grow Generation Login');
        this.seoService.setMetaKeywords(`Content ${title}`);
      }
    });
    // this.loginService.getSignUpDisableStatus().subscribe(success => {
    //   if (!success.success) {
    //     this.signUpDisabled = true;
    //     return;
    //   }
    //   this.signUpDisabled = success.result;
    // }, error => {
    //   this.signUpDisabled = true;
    //   console.error(error.error);
    // });
  }

  ngOnDestroy() {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
  }

  forgotPassword() {
    if (!this.user.email) {
      this.userEmailFormControl.markAsTouched();
      return;
    }

    this.loginService.forgotPassword(this.user)
      .subscribe(success => {
        if (!success.success) {
          return;
        }

        this.router.navigate(['/check-email'], {queryParams: {option: CheckEmailEnum.FORGOT_PASSWORD}});
      }, error => {
        this.incorrectEmail = true;
        console.error(error.error);
      });
  }

  signIn() {
    this.loginService.signIn(this.user)
      .subscribe((userLoggedIn: LoginResponse) => {
        if (!userLoggedIn) {
          this.userPasswordFormControl.setValue(null);
          return;
        }

        this.store.dispatch(new LoginUser(userLoggedIn));
        this.storageService.setItem(StorageEnum.IS_USER_LOGGED_IN, 'true');

        // this.getStripeCustomer();

        this.router.navigateByUrl(this.returnUrl);
      }, error => {
        if (error.status === 400) {
          this.incorrectEmail = true;
          this.userPasswordFormControl.setValue(null);
        } else if (error.status === 401) {
          this.isVerified = false;
          this.userPasswordFormControl.setValue(null);
        } else {
          console.error(error.statusText);
          this.userPasswordFormControl.setValue(null);
        }
      });
  }

  tickPasswordForm() {
    this.newPasswordFormControl.markAsTouched();
  }

  checkIfNewUserIsInvalid() {
    return !this.newUser.firstName
      || !this.newUser.lastName
      || (!this.newUser.email || this.newEmailFormControl.hasError('email'))
      || this.checkIfNewPasswordIsInvalid();
  }

  checkIfNewPasswordIsInvalid() {
    return !this.newUser.password
      || this.newUser.password.length < 8
      || !/\d/.test(this.newUser.password)
      || !/[A-Z]/.test(this.newUser.password);
  }

  checkPasswordLength() {
    let isValid;

    if (!this.newUser.password) {
      isValid = false;
    } else {
      isValid = this.newUser.password.length > 8;
    }

    return isValid;
  }


  containsNumber() {
    return /\d/.test(this.newUser.password);
  }

  containsOneUpperCase() {
    return /[A-Z]/.test(this.newUser.password);
  }

  onKeydownSignIn(event: any) {
    if (this.user.email !== undefined && this.user.password !== undefined) {
      this.signIn();
    } else {
      this.userEmailFormControl.markAsTouched();
      this.userPasswordFormControl.markAsTouched();
    }
  }

  signUp() {
    this.creatingAccount = true;
    this.loginService.signUp(this.newUser)
      .subscribe((response: User) => {
        if (!response) {
          return;
        }

        this.creatingAccount = false;

        // FIXME once email is setup
        this.router.navigate(['/check-email'], {queryParams: {option: CheckEmailEnum.VERIFY}});
      }, error => {
        this.creatingAccount = false;

        if (error.status === 401) {
          this.snackBarRef =
            this.snackBar.open('This email is already in use.', 'Close', this.snackBarConfig);
        } else {
          console.error(error.statusText);
        }
      });
  }

  onKeydownSignUp(event: any) {
    if (!this.checkIfNewUserIsInvalid()) {
      this.signUp();
    } else {
      console.log(this.newEmailFormControl)
      this.newEmailFormControl.markAsTouched();
      this.newFirstNameFormControl.markAsTouched();
      this.newLastNameFormControl.markAsTouched();
      this.newPasswordFormControl.markAsTouched();
    }
  }

  signOut() {
    this.loginService.signOut()
      .subscribe((logout: boolean) => {
        if (!logout) {
          return;
        }

        this.store.dispatch(new LogoutUser());

        this.router.navigate(['/']);
      }, error => {
        console.error(error.error);
      });
  }


  getStripeCustomer() {
    if (this.loggedInUser.stripeId == null || this.loggedInUser.stripeId === '') {
      return;
    }
    // this.paymentService.getStripeCustomer(this.loggedInUser)
    //   .subscribe(stripeCustomerResult => {
    //     if (!stripeCustomerResult.success) {
    //       return;
    //     }

        // this.store.dispatch(new UpdateStripeCustomer(stripeCustomerResult.result));
        // this.router.navigate(['/']);

      // }, error => {
      //   console.error(error.error);
      // });
  }

}
