<div class="flex flex-wrap md:flex-nowrap justify-center items-stretch">
    <div class="checkout-main payment-options flex flex-col w-full md:w-3/4" appMobileView>

        <div class="flex flex-col gap-2" *ngIf="showPaymentOptions()">
            <label class="section-title">Choose a Payment Option:</label>
            <mat-radio-group id="payment-method-type-radio-group"
                             class="flex flex-wrap gap-8">

                <div class="flex flex-col gap-2" *ngIf="loggedInUser && stripeCustomer?.sources?.data">
                    <img class="payment-method-icon" alt="credit card"
                         src="../../../assets/credit-card.jpg"/>
                    <mat-radio-button name="payment-method-type"
                                      value="{{savedCreditCard}}"
                                      (change)="setPaymentMethodType(savedCreditCard)"
                                      [checked]="getPaymentMethodType() === savedCreditCard">Saved Credit Card
                    </mat-radio-button>
                </div>

                <div class="flex flex-col gap-2">
                    <img class="payment-method-icon" alt="credit card" src="../../../assets/credit-card.jpg"/>
                    <mat-radio-button name="payment-method-type"
                                      value="{{newCreditCard}}"
                                      (change)="setPaymentMethodType(newCreditCard)"
                                      [checked]="getPaymentMethodType() === newCreditCard">New Credit Card
                    </mat-radio-button>
                </div>

                <div class="flex flex-col gap-2">
                    <img class="payment-method-icon" *ngIf="googlePayAvailable" alt="google pay" src="../../../assets/google-pay.jpg"/>
                    <mat-radio-button name="payment-method-type"
                                      value="{{googlePay}}"
                                      (change)="setPaymentMethodType(googlePay)"
                                      [checked]="getPaymentMethodType() === googlePay"
                                      *ngIf="googlePayAvailable">Google Pay
                    </mat-radio-button>
                </div>

                <div class="flex flex-col gap-2">
                    <img class="payment-method-icon" *ngIf="applePayAvailable" alt="apple pay" src="../../../assets/apple-pay.jpg"/>
                    <mat-radio-button name="payment-method-type"
                                      value="{{applePay}}"
                                      (change)="setPaymentMethodType(applePay)"
                                      [checked]="getPaymentMethodType() === applePay"
                                      *ngIf="applePayAvailable">Apple Pay
                    </mat-radio-button>
                </div>

            </mat-radio-group>
        </div>

        <div id="saved-payment-methods" *ngIf="loggedInUser && stripeCustomer?.sources?.data"
             [hidden]="!paymentMethodTypeSavedCreditCard">
            <div class="body-section flex flex-col gap-2">
                <label class="section-title">Select a saved payment method:</label>
                <div id="payment-sources" class="flex flex-wrap gap-5">
                    <div *ngFor="let source of stripeCustomer?.sources?.data">
                        <div class="tile-container flex flex-col" *ngIf="source">
                            <div class="tile-body flex flex-col">
                                <mat-checkbox class="checkbox"
                                              [checked]="isSourceSelected(source)"
                                              (change)="sourceSelectionChanged($event.checked, source)">Selected
                                </mat-checkbox>
                                <label *ngIf="source.card">Credit Card:</label>
                                <span>{{source.card.brand}}</span>
                                <span>Ending in: {{source.card.last4}}</span>
                                <span>Expires: {{source.card.exp_month}} / {{source.card.exp_year}}</span>
                            </div>
                            <div class="tile-buttons flex justify-evenly gap-2" >
                                <button class="tile-button" (click)="updateSource(source)"><i class="fa fa-pencil" aria-hidden="true"></i>
                                    Edit
                                </button>
                                <button class="tile-button" (click)="detachSource(source)"><i class="fa fa-times" aria-hidden="true"></i>
                                    Remove
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="enter-payment-method" [hidden]="!paymentMethodTypeNewCreditCard">
            <div class="body-section flex flex-col gap-2">
                <label class="section-title">Enter your payment details:</label>

                <form id="create-source-form" novalidate>

                    <div class="owner-input flex flex-col">
                        <label for="email" class="input-title">Email (required)</label>
                        <mat-form-field>
                          <input matInput id="email" name="email"
                          (keydown)="sourceSelectionChanged(true, stripeSourceData)"
                          [formControl]="emailFormControl"/>
<!--                            <input matInput id="email" name="email" [(ngModel)]="stripeSourceData.owner.email"-->
<!--                                   (keydown)="sourceSelectionChanged(true, stripeSourceData)"-->
<!--                                   (keyup)="stripeSourceData.owner.email = stripeSourceData.owner.email?.replace(' ','')"-->
<!--                                   [formControl]="emailFormControl"/>-->
                            <mat-error
                                    *ngIf="emailFormControl.hasError('pattern')">
                                Please enter a valid email address
                            </mat-error>
                            <mat-error *ngIf="emailFormControl.hasError('required')">
                                Email is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div id="card" *ngIf="stripePaymentIntentSuccess()">
                        <div id="card-instruction" class="instruction">Enter your credit card information.</div>
                        <div id="card-element"></div>
                      <ngx-stripe-elements [stripe]="stripe" [elementsOptions]="elementsOptions" >
                        <ngx-stripe-payment (change)="validateStripe($event)" />
                      </ngx-stripe-elements>
                    </div>

                    <div id="card-errors" class="element-errors" role="alert"></div>

                </form>

                <div *ngIf="loggedInUser">
                    <mat-checkbox class="checkbox" [(ngModel)]="saveNewPaymentMethod">Save this payment method.
                    </mat-checkbox>
                </div>

            </div>
        </div>

        <div id="payment-request" [hidden]="!paymentMethodTypeGooglePay && !paymentMethodTypeApplePay">

            <br><br>
            <div class="flex flex-col gap-8">
                <label *ngIf="paymentMethodTypeGooglePay" class="section-title">Complete your Payment with Google Pay:</label>
                <label *ngIf="paymentMethodTypeApplePay" class="section-title">Complete your Payment with Apple Pay:</label>
                <label *ngIf="orderArray.length > 1">
                    You must complete both payments before your order will be submitted.</label>
                <div *ngFor="let order of orderArray; let i = index" class="flex gap-5">
                    <button mat-button [attr.id]="getPaymentRequestButtonId(order)"
                            class="checkout-button payment-request-unmounted"
                            [disabled]="true"
                    >COMPLETE PAYMENT FOR ORDER #{{i + 1}}
                    </button>
                    <i *ngIf="orderIsQueued(order)" class="fa fa-check green-check"></i>
                </div>
            </div>

        </div>


    </div>
    <div class="flex flex-col w-full md:w-1/4">
        <div class="checkout-grey-box" appMobileView>

            <app-checkout-summary [orderArray]="orderArray"></app-checkout-summary>

            <div class="checkout-sidebar-section action ">
                <button mat-button id="submitButton" class="checkout-button" (click)="pay()"
                        [disabled]="!validateInputs() || paying()">COMPLETE
                    ORDER
                </button>
            </div>

            <div class="disclaimer">
                <strong>A friendly reminder:</strong><br>
                Once placed, orders cannot be cancelled.
                Please review all information carefully before clicking "Complete Order."
            </div>
        </div>

        <div class="checkout-grey-box" appMobileView>
            <div class="promo-container flex flex-col justify-center gap-4">
                <div class="flex flex-col justify-center">
                    <label class="promo-active" *ngIf="orderArray && orderArray[0]  && orderArray[0].promoCode">Promo
                        Code {{orderArray[0].promoCode}} is currently active. Want to use a different one?</label>
                    <label class="promo-active" *ngIf="!orderArray || !orderArray[0]  || !orderArray[0].promoCode">Have
                        a promo code? </label>
                    <label class="promo-active">Please go back to Shopping Cart to apply it.</label>
                </div>
                <div class="flex justify-center">
                    <button mat-flat-button color="accent" (click)="return()">RETURN TO CART</button>
                </div>
            </div>
        </div>

        <div class="checkout-grey-box checkout-help-box flex flex-col" appMobileView>
            <label class="checkout-summary-title">Need Assistance?</label>
            <div class="flex flex-col gap-2">
                <label>Email:<br>
                    <a class="checkout-email checkout-assistance" href="mailto:Humveeparts@amgeneral.com">Humveeparts@amgeneral.com</a></label>
                <label>Phone:<br>
                    <a class="checkout-email checkout-assistance" href="tel:800-348-8833">(800) 348-6833</a></label>
            </div>
        </div>
    </div>
</div>
