/**
 * Error Codes
 */
export class ErrorCode {

    // S2K Errors
    public static ORDER_ALREADY_IN_S2K = 20;
    public static S2K_SUBMIT_FAILED    = 23;

    // Stristaticrors
    public static ORDER_ALREADY_CHARGED = 30;
    public static CHARGE_FAILED         = 31;

    public static NO_ERROR_CODE_AVAILABLE = 999;

}
