import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {Feature} from '../../core/models/feature.model';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {Store} from '@ngrx/store';
import {AppStore} from '../../core/models/state.model';
import {Product} from '../../core/models/product.model';
import {Category} from '../../core/models/category.model';
import {Router} from '@angular/router';
import {ContentPage} from '../../core/models/content-page.model';
import {isPlatformBrowser} from "@angular/common";
import {FeatureTypeEnum} from "../../core/enums/feature-type.enum";

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations: [
    trigger('swipeChange', [
      transition('* => left', animate(200, keyframes([
        style({transform: 'translate3d(0, 0, 0)'}),
        style({transform: 'translate3d(-150%, 0, 0)', opacity: 0})
      ]))),
      transition('* => right', animate(200, keyframes([
        style({transform: 'translate3d(0, 0, 0)'}),
        style({transform: 'translate3d(150%, 0, 0)', opacity: 0})
      ])))
    ])
,
    trigger('completedAnimationState', [
      transition('* => left', animate(200, keyframes([
        style({transform: 'translate3d(-150%, 0, 0)', opacity: .75}),
        style({transform: 'translate3d(0, 0, 0)'})
      ]))),
      transition('* => right', animate(200, keyframes([
        style({transform: 'translate3d(150%, 0, 0)', opacity: .75}),
        style({transform: 'translate3d(0, 0, 0)'})
      ]))),
    ])
  ]
})
export class CarouselComponent implements OnInit  {

  @Input() carouselItems: Feature[] = [];
  chosenItemIndex: number = 0;
  nextItemIndex: number | null;
  timer: any;
  milliseconds: number = 7000; // 7 seconds
  imageNotFound: boolean[] = [];
  animationState: string = '';
  completedAnimationState: string = '';
  products: Product[];
  categories: Category[];
  contentPages: ContentPage[];

  constructor(@Inject(PLATFORM_ID) private platformId: any,
              private router: Router,
              private store: Store<AppStore>) {
    store.subscribe(store => {
      const state = store.state;
      this.products = state.products;
      this.categories = state.categories;
      this.contentPages = state.contentPages;
    })
  }

  ngOnInit() {
	  if (isPlatformBrowser(this.platformId)) {
		  this.startTimer();
	  }
  }

  unescapeHtml(html: string) {

      let uHtml = html;
      if(uHtml.length > 7) {
          uHtml = html.substr(3, html.length - 7);
          uHtml = uHtml.replace(/&lt;/g, '<');
          uHtml = uHtml.replace(/&gt;/g, '>');
      }
      return uHtml;
  }
  get chosenItem(): Feature | null {
    if (this.carouselItems && this.carouselItems.length) {
		return this.carouselItems[this.chosenItemIndex];
    }
    return null;
  }

  moveRight() {
    if (this.nextItemIndex) {
      this.chosenItemIndex = this.nextItemIndex;
      this.nextItemIndex = null;
    } else {
      if (this.chosenItemIndex === this.carouselItems.length - 1) {
        this.chosenItemIndex = 0;
      } else {
        this.chosenItemIndex++;
      }
    }
  }

  get link(): string {
    let link = '';
    if (this.chosenItem) {
      switch (this.chosenItem.type) {
        case FeatureTypeEnum.CATEGORY:
          const category = this.categories[this.chosenItem.featuredId];
          if (category) {
            link = `/products/${category.url}`;
          }
          break;
        case FeatureTypeEnum.PRODUCT:
          const product = this.products.find(prod => prod.itemNumber === this.chosenItem?.featuredId.toString());
          if (product) {
            link = `/product/${product.url}`;
          }
          break;
        case FeatureTypeEnum.CONTENT:
          const contentPage = this.contentPages[this.chosenItem.featuredId];
          link = contentPage.url;
          break;
      }
    }
    return link;
  }

  goToRelated() {
    if (!this.link) {
      return;
    }
    this.router.navigateByUrl(this.link);
  }

  moveLeft() {
    if (this.nextItemIndex) {
      this.chosenItemIndex = this.nextItemIndex;
      this.nextItemIndex = null;
    } else {
      if (this.chosenItemIndex === 0) {
        this.chosenItemIndex = this.carouselItems.length - 1;
      } else {
        this.chosenItemIndex--;
      }
    }
  }

  startAnimation(state: string) {
    if (!this.animationState) {
      this.animationState = state;
    }
  }

  resetAnimationState() {
    switch (this.animationState) {
      case 'left':
		  this.moveRight();
		  this.startCompletedAnimation('right');
        break;
      case 'right':
		  this.moveLeft();
		  this.startCompletedAnimation('left');
        break;
    }
    this.animationState = '';
  }

  startCompletedAnimation(state: string) {
    if (!this.completedAnimationState) {
      this.completedAnimationState = state;
    }
  }

  resetCompletedAnimationState() {
    this.completedAnimationState = '';
  }

  startTimer() {
    if (this.carouselItems.length <= 1) {
      return;
    }
    // create a new timer that calls this function
    this.timer = setTimeout(() => {
      this.timeOutFinished();
    }, this.milliseconds);
  }

  timeOutFinished() {
    // first thing clear out the previous timer
    clearTimeout(this.timer);
    // then start the animation
    if (this.nextItemIndex && this.nextItemIndex > this.chosenItemIndex) {
      this.startAnimation('right');
    } else {
      this.startAnimation('left');
    }
    // create a new timer that calls this function
    this.timer = setTimeout(() => {
      this.timeOutFinished();
    }, this.milliseconds);
  }

  imageError(image: Feature | null) {
    if (image instanceof Feature) {
      this.imageNotFound[image.id] = true;
    }
  }

  chooseItem(index: number) {
    this.nextItemIndex = index;
    this.timeOutFinished();
  }

  clearTimeOut() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
}
