import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {AuthService} from './core/services/auth.service';
import {User} from './core/models/user.interface';
import {Banner} from "./core/models/banner.model";
import {DomSanitizer} from "@angular/platform-browser";
import {MatIconRegistry} from "@angular/material/icon";
import {isPlatformServer} from "@angular/common";
import {AppStore} from './core/models/state.model';
import {Store} from '@ngrx/store';
import {AppInitService} from './core/services/app-init.service';
import {LoadInitialState} from './core/actions/state.actions';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'kogent-ce-ui';
	user: User | null = null;
	reset: boolean;

  smallHeader:boolean = false;
  banner: Banner;

  pageTitle: string;

	constructor(@Inject(PLATFORM_ID) private platformId: any,
              private authService: AuthService,
              private store: Store<AppStore>,
              private iconRegistry: MatIconRegistry,
              private appInitService: AppInitService,
              private sanitizer: DomSanitizer,) {
    this.loadIcons();
    store.subscribe(store => {
      const state = store.state;
      // this.user = state.user;
      if (state.banner && state.banner.isActive && state.banner.onTime) {
        this.banner = state.banner;
      }
    });
		this.authService.user.subscribe((value) => {
			this.user = value;
		});
		this.authService.resetPassword.subscribe((value) => {
			this.reset = value;
		});
    this.initialLoad();
	}

  loadIcons() {
    // domain and port for SSR in this example is static. Use i.e. environment files to use appropriate dev/prod domain:port
    const domain = (isPlatformServer(this.platformId)) ? 'http://localhost:4200/' : '';
    console.log(domain)
    this.iconRegistry.addSvgIcon(
      'cart',
      this.sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/layout/cart.svg'));
    this.iconRegistry.addSvgIcon(
      'add-to-cart',
      this.sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/add-to-cart.svg'));
    this.iconRegistry.addSvgIcon(
      'grid',
      this.sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/layout/grid1.svg'));
    this.iconRegistry.addSvgIcon(
      'grid-grey',
      this.sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/layout/grid-grey.svg'));
    this.iconRegistry.addSvgIcon(
      'list',
      this.sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/layout/list1.svg'));
    this.iconRegistry.addSvgIcon(
      'list-grey',
      this.sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/layout/list-grey.svg'));
    this.iconRegistry.addSvgIcon(
      'close',
      this.sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/layout/close.svg'));
  }

  initialLoad() {
    const data = this.appInitService.appData;
    if (typeof data !== 'undefined') {
      if (data) {
        // this.newsletterIsDisabled = data.result.newsletterIsDisabled;
        // this.storageService.setItem(StorageEnum.NEWSLETTER_IS_DISABLED, data.result.newsletterIsDisabled ? 'true' : 'false');
        // this.quantityIsDisabled = data.result.quantityIsDisabled;
        // this.addr2IsDisabled = data.result.addr2IsDisabled;
      }

      if (data.user) {
        // this.storageService.setItem(StorageEnum.IS_USER_LOGGED_IN, 'true');
        // this.loggedInUser = data.result.user;
      }
      this.store.dispatch(new LoadInitialState(data));
    }

  }

}
