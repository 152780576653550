import { Directive, ElementRef, Renderer2, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appMobileView]'
})
export class MobileViewDirective implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    this.breakpointObserver
      .observe(['(max-width: 767px)'])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state) => {
        if (state.matches) {
          this.renderer.addClass(this.elementRef.nativeElement, 'mobile-view');
        } else {
          this.renderer.removeClass(this.elementRef.nativeElement, 'mobile-view');
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
