<div [ngClass]="{'no-image' : imageNotFound}" class="featured-container-category"
     appMobileView *ngIf="featureItem.type === 'CATEGORY'">

    <img [src]="featureItem.imageFilename" *ngIf="!imageNotFound" class="featured-image relative"
         appMobileView (error)="imageError()">
    <div class="gradient-overlay"></div>
    <div class="category-content">
    <label class="featured-title">{{featureItem.title | uppercase}}</label>
    <label class="header-label" [innerHtml]="featureItem ? (featureItem.description  | safeHtml) : ''"></label>
    <a [routerLink]="link">
        <button>Shop {{featureItem.title}}</button>
    </a>
    </div>
</div>
<div [routerLink]="link" [ngClass]="{'clickable': link, 'no-image' : imageNotFound}" class="featured-container-product"
     appMobileView *ngIf="featureItem.type === 'PRODUCT'">
        <img [src]="featureItem.imageFilename" *ngIf="!imageNotFound" class="featured-image"
             appMobileView (error)="imageError()">
        <label class="featured-price">{{featureItem.product?.productPrice?.price | currency:'USD':'symbol':'0.2-2'}}</label>
        <label class="featured-title">{{featureItem.product?.displayName}}</label>
        <label class="featured-detail">ITEM #{{featureItem.product?.itemNumber}}</label>
        <label class="featured-detail">NSN {{featureItem.product?.shortDescription}}</label>
        <label class="featured-detail">WEIGHT {{featureItem.product?.itemWeight}} Pound</label>
</div>
