<div class="order-confirmation flex flex-col justify-center items-stretch">
	<div class="flex flex-col gap-4">
		<h1>Your package is on its way!</h1>
		<h2>
			We’ve received your order and will be sending a confirmation e-mail shortly.<br />
			Keep an eye out for updates and tracking info and we’ll have your package delivered in no time.
		</h2>

		<div class="flex flex-col gap-2" *ngFor="let order of orderList">
			<div class="flex flex-col md:flex-row justify-between items-center">
				<h2>Web Order #{{ order.orderHeader.orderNumber }}</h2>
				<label
					>You placed this order on <strong>{{ order.orderHeader.orderDate | date : 'medium' }}</strong></label
				>
			</div>

			<div class="flex flex-col md:flex-row gap-4 md:gap-1 justify-between">
				<div class="flex flex-col gap-1">
					<h3>Contact Information</h3>
					<div class="flex flex-col gap-1">
						<label><strong>Email:</strong> {{ order.billTo.email }}</label>
					</div>
				</div>
				<div class="flex flex-col gap-1">
					<h3>Shipping Information</h3>
					<div class="flex flex-col gap-1">
						<label>{{ order.shipTo.name }}</label>
						<label>{{ order.shipTo.address1 }}</label>
						<label *ngIf="order.shipTo.address2 !== ''">{{ order.shipTo.address2 }}</label>
						<label>{{ order.shipTo.city }} {{ order.shipTo.state }}, {{ order.shipTo.zip }}</label>
						<label>{{ order.shipTo.country }}</label>
						<label><strong>Phone:</strong> {{ order.shipTo.phone }}</label>
					</div>
				</div>
			</div>

			<div class="flex flex-col gap-1">
				<h3>Items Ordered:</h3>
				<div class="flex">
					<table class="table">
						<thead>
							<tr>
								<th></th>
								<th>Item</th>
								<th>QTY</th>
								<th>Price</th>
								<th>Ext Price</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let orderItem of order.orderDetailList" style="height: 100px">
								<td>
									<img
										class="mobile-image"
										alt="Product item"
										src="{{ orderItem.imageUrl ? orderItem.imageUrl : '../../../assets/image-not-found.500.jpg' }}"
									/>
								</td>
								<td>
									<div class="flex flex-col">
										<span>{{ orderItem.itemDescription }}</span>
										<span>#{{ orderItem.itemNumber }}</span>
										<span>NSN {{ orderItem.shortDescription }}</span>
									</div>
								</td>
								<td>{{ orderItem.quantity }}</td>

								<td>
									<div class="flex" *ngIf="orderItem.discountPrice === 0.0 && orderItem.discountPercent === 0.0">
										<span>{{ orderItem.price | currency : 'USD' : 'symbol' : '0.2-2' }}</span>
									</div>
									<div class="flex" *ngIf="orderItem.discountPrice > 0.0 || orderItem.discountPercent > 0.0">
										<span
											><del>{{ orderItem.price | currency : 'USD' : 'symbol' : '0.2-2' }}</del></span
										>
										<span>{{ orderItem.discountPrice | currency : 'USD' : 'symbol' : '0.2-2' }}</span>
									</div>
								</td>

								<td>
									<div class="flex" *ngIf="orderItem.discountPrice === 0.0 && orderItem.discountPercent === 0.0">
										<span>{{ orderItem.price * orderItem.quantity | currency : 'USD' : 'symbol' : '0.2-2' }}</span>
									</div>
									<div class="flex" *ngIf="orderItem.discountPrice > 0.0 || orderItem.discountPercent > 0.0">
										<span
											><del>{{ orderItem.price * orderItem.quantity | currency : 'USD' : 'symbol' : '0.2-2' }}</del></span
										>
										<span>{{ orderItem.discountPrice * orderItem.quantity | currency : 'USD' : 'symbol' : '0.2-2' }}</span>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div class="flex flex-col gap-1">
				<label><strong>Subtotal:</strong> {{ order.orderHeader.productTotal | currency : 'USD' : 'symbol' : '0.2-2' }}</label>
				<label><strong>Sales Tax:</strong> {{ order.orderHeader.taxTotal | currency : 'USD' : 'symbol' : '0.2-2' }}</label>
				<label
					><strong>Shipping:</strong>
					{{
						order.orderHeader.freight - (order.orderHeader.shippingDiscountAmount ? order.orderHeader.shippingDiscountAmount : 0)
							| currency : 'USD' : 'symbol' : '0.2-2'
					}}</label
				>
				<label><strong>Ship via:</strong> {{ order.orderHeader.shipVia }}</label>
				<label
					><strong>Shipping Method:</strong>
					{{
						order.orderHeader.shipMethod === '03'
							? 'Ground'
							: order.orderHeader.shipMethod === '02'
							? '2nd Day Air'
							: order.orderHeader.shipMethod === '01'
							? 'Next Day Air'
							: order.orderHeader.shipMethod
					}}</label
				>
				<label
					><strong>Total: {{ order.orderHeader.orderTotal | currency : 'USD' : 'symbol' : '0.2-2' }}</strong></label
				>
			</div>
		</div>
	</div>
</div>
