<div class="bread-crumb-options flex flex-col justify-between gap-4">
  <div class="flex justify-between">
    <div class="bread-crumbs flex gap-2">
      <label class="section-title mb-2">Account Details</label>
    </div>
  </div>
</div>
<mat-divider></mat-divider>

<div class="account-section account-section-first flex flex-col gap-8">
  <div class="flex flex-col gap-6">
    <label class="section-title mt-4 leading-6">Manage your account details:</label>

    <div class="login-input account-input-container flex flex-col" appMobileView>
      <span class="input-title">First Name</span>
      <mat-form-field class="login-input-box">
        <input matInput [(ngModel)]="newFirstName" (ngModelChange)="firstNameFormControl.markAsTouched()"
               [formControl]="firstNameFormControl" />
        <mat-error *ngIf="firstNameFormControl.hasError('required')">
          First Name is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="firstNameFormControl.hasError('pattern')">
          First Name cannot contain special characters
        </mat-error>
      </mat-form-field>
    </div>

    <div class="login-input account-input-container flex flex-col" appMobileView>
      <span class="input-title">Last Name</span>
      <mat-form-field class="login-input-box">
        <input matInput [(ngModel)]="newLastName" (ngModelChange)="lastNameFormControl.markAsTouched()"
               [formControl]="lastNameFormControl" />
        <mat-error *ngIf="lastNameFormControl.hasError('required')">
          Last Name is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="lastNameFormControl.hasError('pattern')">
          Last Name cannot contain special characters
        </mat-error>
      </mat-form-field>
    </div>

    <div class="login-input account-input-container flex flex-col" appMobileView>
      <span class="input-title">Email</span>
      <mat-form-field class="login-input-box">
        <input matInput [(ngModel)]="newEmail" (ngModelChange)="emailFormControl.markAsTouched()"
               (keyup)="newEmail = newEmail.replace(' ','')" [formControl]="emailFormControl" />
        <mat-error *ngIf="emailFormControl.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('pattern')">
          Invalid email format
        </mat-error>
      </mat-form-field>
    </div>

    <div>
      <button mat-flat-button color="primary" class="blue-button mb-2" (click)="updateAccount()" [disabled]="!validateAccountDetails()">
        Update Account
      </button>
    </div>
  </div>
</div>

<mat-divider></mat-divider>


<div class="account-section flex flex-col gap-3">
  <label class="section-title mt-4 leading-6">Manage your saved addresses:</label>
  <div id="addresses" class="flex flex-wrap gap-4">
    <div *ngFor="let address of addresses">
      <div class="tile-container flex flex-col">
        <div class="tile-body flex flex-col">
          <span>{{address.name}}</span>
          <span>{{address.line1}}</span>
          <span>{{address.line2}}</span>
          <span>{{address.city}}, {{address.state}} {{address.postalCode}}</span>
          <span>{{address.phone}}</span>
        </div>
        <div class="tile-buttons flex justify-evenly gap-2">
          <button class="tile-button" (click)="editAddress(address)"> <i class="fa fa-pencil"
                                                                         aria-hidden="true"></i> Edit</button>
          <button class="tile-button" (click)="removeAddress(address)"> <i class="fa fa-times"
                                                                           aria-hidden="true"></i> Remove</button>
        </div>

        <div class="tile-footer flex justify-center">
          <label *ngIf="address.default">Default Shipping Address</label>
        </div>
      </div>
    </div>
    <button mat-flat-button color="primary" class="blue-button add-button mb-2" (click)="createAddress()">Add a New Address</button>
  </div>
</div>

<mat-divider></mat-divider>

<!--<div>-->
<!--  <div class="account-section filter-section flex flex-col gap-3" *ngIf="availableTags.length > 0">-->
<!--    <label class="section-title">Manage your filter preferences:</label>-->
<!--    <div class="flex flex-wrap justify-evenly">-->
<!--      <div class="tag-container flex flex-col" *ngFor="let tag of availableTags">-->
<!--        <mat-checkbox class="checkbox"-->
<!--                      [checked]="isThisTagChecked(tag)" (change)="updateSelectedTags($event.checked, tag)">{{tag.name}}-->
<!--        </mat-checkbox>-->
<!--        <label class="tag-description" [innerHtml]="tag.description  | safeHtml"></label>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->


<!--<div *ngIf="!newsLetterIsDisabled">-->
<!--  <div id="newsletter" class="account-section flex flex-col gap-3">-->
<!--    <label class="section-title">Opt in or out of news letters:</label>-->
<!--    <mat-checkbox class="checkbox" [checked]="loggedInUser?.gettingNewsLetters"-->
<!--                  (change)="updateNewsLetter($event.checked)">Subscribed-->
<!--    </mat-checkbox>-->
<!--  </div>-->
<!--</div>-->

<!--<div>-->
<!--  <div id="data" class="account-section flex flex-col gap-3" >-->
<!--    <label class="section-title">Opt in or out of data sharing:</label>-->
<!--    <mat-checkbox class="checkbox" [checked]="loggedInUser?.dataOptIn" (change)="updateDataOptIn($event.checked)">-->
<!--      Allow AM General to share data with third parties-->
<!--    </mat-checkbox>-->
<!--  </div>-->
<!--</div>-->

<div class="account-section flex flex-col gap-8">
  <div class="flex flex-col gap-3">
    <label class="section-title mt-4">Change Password:</label>
    <div class="login-input password-input-container flex flex-col" appMobileView>
      <span class="input-title">Old Password</span>
      <mat-form-field class="login-input-box">
        <input matInput [(ngModel)]="oldPassword" type="password" [formControl]="passwordFormControl" />
        <mat-error *ngIf="passwordFormControl.hasError('required')">
          Password is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="login-input password-input-container flex flex-col" appMobileView>
      <span class="input-title">New Password</span>
      <mat-form-field class="login-input-box">
        <input matInput [(ngModel)]="newPassword" type="password" [formControl]="newPasswordFormControl" />
        <mat-error *ngIf="!newPassword">
          Password is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="!checkPasswordLength(newPassword)">
          Password must be at least <strong>8</strong> characters
        </mat-error>
        <mat-error *ngIf="!containsNumber(newPassword)">
          Password must contain at least <strong>1</strong> number
        </mat-error>
        <mat-error *ngIf="!containsOneUpperCase(newPassword)">
          Password must contain at least <strong>1</strong> uppercase character
        </mat-error>
      </mat-form-field>
    </div>
    <div class="login-input password-input-container flex flex-col" appMobileView>
      <span class="input-title">Re-Enter New Password</span>
      <mat-form-field class="login-input-box">
        <input matInput [(ngModel)]="confirmNewPassword" (ngModelChange)="checkForMatch()" type="password"
               [formControl]="newPasswordConfirmFormControl" />
        <mat-error *ngIf="!confirmNewPassword">
          Password is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="newPasswordConfirmFormControl.hasError('doesNotMatch')">
          Passwords do not match!
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <button mat-flat-button color="primary" class="blue-button mb-2" (click)="changePassword()" [disabled]="verifyChangePassword()">
        Change Password
      </button>
    </div>
  </div>
</div>

<mat-divider></mat-divider>

<div>
  <div id="delete" class="account-section flex flex-col gap-3">
    <label class="section-title mt-4">Delete Account:</label>
    <button mat-flat-button color="warn" class="red-button" (click)="deleteAccount(loggedInUser)">
      Delete Account
    </button>
  </div>
</div>
