import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Cart} from '../models/cart.model';
import {AppStore} from '../models/state.model';
import {Store} from '@ngrx/store';
import {UpdateCartItems} from '../actions/state.actions';
import {ShoppingCartItem} from '../models/shopping-cart.model';
import {Product} from '../models/product.model';

@Injectable()
export class CartService {

	readonly baseUrl = `${environment.url}/cart`;

	constructor(private http: HttpClient,
				private store: Store<AppStore>) {
	}

	addToCart(product: Product, qty?: number): Observable<ShoppingCartItem[]> {
		// fbq('track', 'AddToCart');
		const cart: Cart = {
			itemNumber: product.itemNumber,
			quantity: qty ? qty : 1
		} as Cart;
		return this.http.post<ShoppingCartItem[]>((`${this.baseUrl}`), cart);
	}

	editInCart(cartItem: ShoppingCartItem): Observable<ShoppingCartItem[]> {
		const cart: Cart = {
      id: cartItem.id,
			itemNumber: cartItem.itemNumber,
			quantity: cartItem.quantity,
		} as Cart;
		return this.http.put<ShoppingCartItem[]>((`${this.baseUrl}/${cart.id}/quantity`), cart);
	}

	deleteFromCart(id: number): Observable<ShoppingCartItem[]> {
		return this.http.post<ShoppingCartItem[]>(`${this.baseUrl}/${id}/remove`, {});
	}

	getShoppingCartItems(): Observable<ShoppingCartItem[]> {
		return this.http.get<ShoppingCartItem[]>(`${this.baseUrl}`);
	}

	dispatchCartUpdate(response: ShoppingCartItem[]) {
		this.store.dispatch(new UpdateCartItems(response));
	}

}
