import { Injectable, EventEmitter, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';

@Injectable()
export class SeoService {

    constructor(private title: Title,
        private meta: Meta,
        @Inject(DOCUMENT) private dom: any) { }

    setTitle(title: string) {
        this.title.setTitle(title);
    }

    setMetaTitle(title: string) {
        this.meta.updateTag({ content: title }, 'name=title');
    }

    setMetaDescription(description: string) {
        this.meta.updateTag({ content: description }, 'name=description');
    }

    setMetaKeywords(content: string) {
        this.meta.updateTag({ content }, 'name=keywords');
    }

    createCanonicalURL() {
        // grab the current url
        let location = this.dom.URL;
        // find and replace the :// with ://www.
        const newLocation = location.replace(/^http:\/\//, 'https://')
            .replace(/:\/\/www./, '://');
        // find the previous link

        let link: HTMLLinkElement = this.dom.getElementById('canonical');

        if (!link) {
            // create a new link
            link = this.dom.createElement('link');
            link.setAttribute('rel', 'canonical');
            link.setAttribute('id', 'canonical');
            this.dom.head.appendChild(link);
        }

        // update the href
        link.setAttribute('href', newLocation);
    }

}
