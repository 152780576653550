import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Image} from "../../core/models/image.model";
import {animate, keyframes, transition, trigger, style} from "@angular/animations";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
	selector: 'app-image-viewer',
	templateUrl: './image-viewer.component.html',
	styleUrls: ['./image-viewer.component.scss'],
	animations: [
		trigger('swipeChange', [
			transition('* => left', animate(300, keyframes([
				style({transform: 'translate3d(0, 0, 0)'}),
				style({transform: 'translate3d(-150%, 0, 0)', opacity: 0})
			]))),
			transition('* => right', animate(300, keyframes([
				style({transform: 'translate3d(0, 0, 0)'}),
				style({transform: 'translate3d(150%, 0, 0)', opacity: 0})
			])))
		]),
		trigger('completedAnimationState', [
			transition('* => left', animate(300, keyframes([
				style({transform: 'translate3d(-150%, 0, 0)', opacity: .75}),
				style({transform: 'translate3d(0, 0, 0)'})
			]))),
			transition('* => right', animate(300, keyframes([
				style({transform: 'translate3d(150%, 0, 0)', opacity: .75}),
				style({transform: 'translate3d(0, 0, 0)'})
			]))),
		])]
})
export class ImageViewerComponent implements OnChanges {

	@Input() images: Image[];
	@Output() currentImageUpdate: EventEmitter<Image> = new EventEmitter<Image>();
	chosenImage: Image = new Image();
	safeVideoUrl: SafeResourceUrl;
	imageNotFound: boolean[] = [];
	noImages: boolean = false;
	sidebarImages: Image[] = [];
	animationState: string = '';
	completedAnimationState: string = '';

	constructor(private sanitizer: DomSanitizer) {
	}

	ngOnChanges() {
		if (this.images) { // none truthy === '', undefined, null, 0
		  if (this.images.length) {
        this.images.sort((a, b) => a.displaySeq - b.displaySeq);
        this.chooseImage(this.images[0]);
        this.noImages = false;
		  } else {
		    this.noImages = true;
      }
		} else {
      this.chosenImage = new Image();
      this.sidebarImages = [];
      this.noImages = true;
    }
	}

	chooseImage(image: Image) {
		if (!image) {
			return;
		}
		if (image.video) {
			this.safeVideoUrl = this.getSafeEmbeddedUrl(image);
		}
		this.chosenImage = image;
		this.currentImageUpdate.emit(this.chosenImage);
		this.createSidebarImages();
	}

	createSidebarImages() {
		this.sidebarImages = this.images.filter(img => img.id !== this.chosenImage.id);
	}


	imageError(image: Image) {
		this.imageNotFound[image.id] = true;
	}

	isImageActive(image: Image) {
		return this.chosenImage.id === image.id;
	}

	moveLeft() {
		const index = this.images.findIndex(image => this.chosenImage.id === image.id);
		let newIndex = 0;
		if (this.images) {
      if (index === this.images.length - 1) {
        newIndex = 0;
      } else {
        newIndex = index + 1;
      }
      this.chosenImage = this.images[newIndex];
    }
	}

	moveRight() {
		const index = this.images.findIndex(image => this.chosenImage.id === image.id);
		let newIndex = 0;
		if (index === 0) {
      if (this.images) {
        newIndex = this.images.length - 1;
      }
		} else {
			newIndex = index - 1;
		}
		this.chosenImage = this.images[newIndex];
	}

	startAnimation(state: any) {
		if (!this.animationState) {
			this.animationState = state;
		}
	}

	resetAnimationState() {
		switch (this.animationState) {
			case 'left':
				this.moveLeft();
				this.startCompletedAnimation('right');
				break;
			case 'right':
				this.moveRight();
				this.startCompletedAnimation('left');
				break;
		}
		this.animationState = '';
	}

	startCompletedAnimation(state: string) {
		if (!this.completedAnimationState) {
			this.completedAnimationState = state;
		}
	}

	resetCompletedAnimationState() {
		this.completedAnimationState = '';
	}

	generateThumbNailFromVideo(image: Image): string {

		let url = null;

		if (image.url.includes('/embed/')) {
			url = image.url.split('/').pop();
		} else {
      const urlParts = image.url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);

      if (urlParts && urlParts[1]) {
        url = urlParts[1];
      } else {
        console.error("Invalid YouTube URL:", image.url);
      }

    }

		return 'http://img.youtube.com/vi/' + url + '/default.jpg';
	}

	getSafeEmbeddedUrl(image: Image): SafeResourceUrl {
		return this.sanitizer.bypassSecurityTrustResourceUrl(image.url);
	}
}
