import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {FavoriteProduct} from "../models/favorite-product.model";
import {Store} from "@ngrx/store";
import {AppStore} from "../models/state.model";
import {UpdateFavoriteProducts} from "../actions/state.actions";
import {Product} from "../models/product.model";
import {MatDialog} from "@angular/material/dialog";
import {Account} from "../models/account.model";
import {Router} from "@angular/router";
import {ConfirmationDialogComponent} from "../../shared/components/confirmation-dialog/confirmation-dialog.component";
import {User} from "../models/user.interface";

@Injectable()
export class FavoritesService {

  readonly favoriteProductBaseUrl = `${environment.url}/api/account-product`;
  readonly favoriteRecipeBaseUrl = `${environment.url}/api/account-recipe`;
  loggedInUser: User;

  constructor(private http: HttpClient,
              private dialog: MatDialog,
              private router: Router,
              private store: Store<AppStore>) {
    store.subscribe(store => {
      const state = store.state;
      this.loggedInUser = state.user!;
    })
  }

  getFavoriteProducts(): Observable<{success: boolean, result: {products: Product[], favoriteProducts: FavoriteProduct[]}}> {
    return this.http.get<{success: boolean, result: {products: Product[], favoriteProducts: FavoriteProduct[]}}>(this.favoriteProductBaseUrl);
  }

  addFavoriteProduct(itemNumber: string) {
    // fbq('track', 'AddToWishlist');

    if (!this.loggedInUser) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: 'With a user account you can save products for later. Would you like to login / sign up?'
      });

      dialogRef.afterClosed().subscribe(goToLogin => {
        if (!goToLogin) {
          return;
        }

        this.router.navigateByUrl('login');
      });
      return;
    }

    const payload = {
      itemNumber: itemNumber
    };
    this.http.post<{ success: boolean, result: FavoriteProduct[] }>(this.favoriteProductBaseUrl, payload).subscribe(success => this.handleFavoriteProductUpdate(success))
  }

  deleteFavoriteProduct(itemNumber: string) {
    this.http.delete<{ success: boolean, result: FavoriteProduct[] }>(`${this.favoriteProductBaseUrl}/${itemNumber}`)
      .subscribe(success => this.handleFavoriteProductUpdate(success));
  }

  private handleFavoriteProductUpdate(response: { success: boolean, result: FavoriteProduct[] }) {
    this.store.dispatch(new UpdateFavoriteProducts(response.result));
  }
}
