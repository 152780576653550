import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { CartView }  from '../core/enums/checkout.component.enum';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {isPlatformBrowser} from "@angular/common";
import {SeoService} from '../core/services/seo.service';

@Component({
  selector   : 'app-cart',
  templateUrl: './checkout.component.html',
  styleUrls  : ['./checkout.component.scss']
})

export class CheckoutComponent implements OnInit{

  cartView: CartView        = CartView.VIEW_CART;
  CartView: typeof CartView = CartView;

  constructor(
              @Inject(PLATFORM_ID) private platformId: any,
              private route: ActivatedRoute,
              private router: Router,
              private seoService: SeoService) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showViewCart();
      }
    })
  }

  ngOnInit() {
    this.route.data.subscribe((resolve) => {
      if (resolve) {
        const verified = resolve['resolve'];
        if (verified) {
          this.showShippingInformation();
        }
      }
    });
    const title = `Checkout | Grow Generation`;
    this.seoService.setTitle(title);
    this.seoService.setMetaTitle(title);
    this.seoService.setMetaDescription('Grow Generation Checkout');
    this.seoService.setMetaKeywords(`Content ${title}`);
  }

  showViewCart() {
    this.cartView = CartView.VIEW_CART;
    this.scrollToTop();
  }

  showShippingInformation() {
    this.cartView = CartView.SHIPPING_INFORMATION;
    this.scrollToTop();
  }

  showShippingOptions() {
    this.cartView = CartView.SHIPPING_OPTIONS;
    this.scrollToTop();
  }

  showPaymentOptions() {
    this.cartView = CartView.PAYMENT_OPTIONS;
    this.scrollToTop();
  }

  completeOrder() {
    this.cartView = CartView.PAYMENT_COMPLETE;
    this.scrollToTop();
  }

  scrollToTop() {
    if (isPlatformBrowser(this.platformId)) {
      // this.window.scrollTo({
      //   top: 0,
      //   behavior: "smooth"
      // });
    }
  }

}
