import { Injectable }        from '@angular/core';
import { environment }       from '../../../environments/environment';
import { HttpClient }        from '@angular/common/http';
import { Observable }        from 'rxjs';
import { Address }           from '../models/address.model';
import { ShippingRates }     from '../models/shipping-rates.model';
import { AddressValidation } from '../models/address-validation.model';


@Injectable()
export class FedexService {

    private baseUrl = `${environment.url}`;

    readonly validateAddressUrl  = (`${this.baseUrl}/shipping/validate-address`);
    readonly getShippingRatesUrl = (`${this.baseUrl}/shipping/rates`);

    constructor(private http: HttpClient) {
    }

    validateAddress(address: Address): Observable<AddressValidation> {
        return this.http.post<AddressValidation>(`${this.validateAddressUrl}`, address);
    }

    getRates(): Observable<{ perishable: ShippingRates, nonPerishable: ShippingRates, combined: ShippingRates }> {
        return this.http.get<{ perishable: ShippingRates, nonPerishable: ShippingRates, combined: ShippingRates }>(`${this.getShippingRatesUrl}`);
    }
}
