import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Account} from '../models/account.model';
import {
    StripeCharge,
    StripeChargeInput,
    StripeCustomer,
    StripeCustomerInput,
    StripeCustomerSourceInput,
    StripeExpirationDateInput,
    StripeSource
} from '../models/stripe.model';
import {Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {isPlatformBrowser} from "@angular/common";
import {Store} from "@ngrx/store";
import {AppStore} from "../models/state.model";
import {PaymentIntent, StripeElementsOptions} from "@stripe/stripe-js";
import {Order} from "../models/order.model";

/**
 * StripeService
 */
@Injectable()
export class PaymentService {

    readonly baseUrl = `${environment.url}`;

    public stripe = null;

    /**
     * constructor
     * @param {HttpClient} http
     * @param {any} platformId
     * @param {Store<AppStore>} store
     */
    constructor(private http: HttpClient,
                @Inject(PLATFORM_ID) private platformId: any,
                private store: Store<AppStore>) {
        store.subscribe(store => {
            const state = store.state;
            if (!this.stripe) {
				// this.stripe = isPlatformBrowser(platformId) ? Stripe(state.stripePublishableKey) : null;
            }
        });
    } // constructor()


    createPaymentIntent(paymentIntentObj: StripeElementsOptions, orderTotal: number): Observable<PaymentIntent> {
      const body = {
        paymentIntentObj,
        orderTotal
      }
      return this.http.post<PaymentIntent>(`${this.baseUrl}/stripe/create-payment-intent`, body)
    }







    /**
     * Get the Stripe Customer for the given Account
     * @param {Account} user
     * @returns {Observable<{success: boolean; result: StripeCustomer}>}
     */
    getStripeCustomer(user: Account): Observable<{ success: boolean, result: StripeCustomer }> {
        if (user.stripeId) {
            return this.http.post<{ success: boolean, result: StripeCustomer }>
            (`${this.baseUrl}/stripe-integration/get-customer`, user.stripeId);
        } else {
          return of({success:false, result: {} as StripeCustomer})
        }
    } // getStripeCustomer()



    /**
     * Charge a Stripe Source
     * @param {StripeChargeInput} stripeChargeInput
     * @returns {Observable<{success: boolean; result: StripeCharge}>}
     */
    chargeStripeSource(stripeChargeInput: StripeChargeInput): Observable<{ success: boolean, result: StripeCharge }> {
        console.log('chargeStripeSource()');
        console.log('stripeChargeInput.sourceId = ' + stripeChargeInput.sourceId);

        if (stripeChargeInput) {
            return this.http.post<{ success: boolean, result: StripeCharge }>
            (`${this.baseUrl}/stripe-integration/charge-source`, stripeChargeInput);
        } else {
          return of({success:false, result: {} as StripeCharge})
        }
    } // chargeStripeSource()


    /**
     * Create a Stripe Customer
     * @param {StripeCustomerInput} stripeCustomerInput
     * @returns {Observable<{success: boolean; result: StripeCustomer}>}
     */
    createStripeCustomer(stripeCustomerInput: StripeCustomerInput): Observable<{ success: boolean, result: StripeCustomer }> {

        if (stripeCustomerInput) {
			console.log('createStripeCustomer()');
			console.log('stripeCustomerInput.sourceId = ' + stripeCustomerInput.sourceId);
			console.log('stripeCustomerInput.email = ' + stripeCustomerInput.email);

            return this.http.post<{ success: boolean, result: StripeCustomer }>
            (`${this.baseUrl}/stripe-integration/create-customer`, stripeCustomerInput);
        } else {
          return of({success: false, result: {} as StripeCustomer})
        }

    } // createStripeCustomer()


    /**
     * Add Source To Customer
     * @param {StripeCustomerSourceInput} stripeCustomerSourceInput
     * @returns {Observable<{success: boolean; result: StripeCustomer}>}
     */
    addSourceToCustomer(stripeCustomerSourceInput: StripeCustomerSourceInput): Observable<{ success: boolean, result: StripeCustomer }> {

      try {
        if (stripeCustomerSourceInput) {

          console.log('addSourceToCustomer()');
          console.log('stripeCustomerSourceInput.customerId = ' + stripeCustomerSourceInput.customerId);

          return this.http.post<{ success: boolean, result: StripeCustomer }>
          (`${this.baseUrl}/stripe-integration/add-source-to-customer`, stripeCustomerSourceInput);
        } else {
          return of({success: false, result: {} as StripeCustomer})
        }
      } catch(e) {
        console.error(e);
        return of({success: false, result: {} as StripeCustomer})
      }
    } // addSourceToCustomer()


    /**
     * Detach Source from Customer
     * @param {StripeCustomerSourceInput} stripeCustomerSourceInput
     * @returns {Observable<{success: boolean; result: StripeCustomer}>}
     */
    detachSourceFromCustomer(stripeCustomerSourceInput: StripeCustomerSourceInput): Observable<{ success: boolean, result: StripeCustomer }> {
        if (stripeCustomerSourceInput) {
			console.log('detachSourceFromCustomer()');
			console.log('stripeCustomerSourceInput.customerId = ' + stripeCustomerSourceInput.customerId);


            return this.http.post<{ success: boolean, result: StripeCustomer }>
            (`${this.baseUrl}/stripe-integration/detach-source-from-customer`, stripeCustomerSourceInput);
        }
      return of({success: false, result: {} as StripeCustomer})
    } // detachSourceFromCustomer()


    /**
     * Update a Stripe Source Expiration Date
     * @param {StripeExpirationDateInput} stripeExpirationDateInput
     * @returns {Observable<{success: boolean; result: StripeSource}>}
     */
    updateSourceExpirationDate(stripeExpirationDateInput: StripeExpirationDateInput): Observable<{
        success: boolean, result: StripeSource
    }> {
        // console.log('updateSourceExpirationDate()');
        // console.log('stripeCustomerInput.exp_month = ' + stripeExpirationDateInput.exp_month);

        if (stripeExpirationDateInput) {
            return this.http.post<{ success: boolean, result: StripeSource }>
            (`${this.baseUrl}/stripe-integration/update-source-expiration-date`, stripeExpirationDateInput);
        }
        return of({success: false, result: {} as StripeSource})
    } // createStripeCustomer()

    /**
     * Log the given Object
     * @param objectName
     * @param object
     */
    logObject(objectName: string, object:  Record<string, any>) {
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                const value = object[key];
                console.log(objectName + '.' + key + ' -> ' + value + '\n');
                if (value instanceof Object) {
                    this.logObject(key.toString(), value);
                }
            }
        }
    } // logObject()

}

