<div class="header-body flex flex-col max-md:mobile-view">

    <div class="mobile-header hidden max-md:flex justify-between">
        <a class="mobile-a menu" (click)="triggerMobileMenu()">
            <i class="fa fa-bars" aria-hidden="true"></i>
        </a>
        <div class="flex" *ngIf="mobileMenuOpened" (click)="triggerMobileMenu()">
            <div class="mobile-menu flex flex-col">
                <div class="flex justify-between">
                <div class="grey-divider"></div>
                <a class="blue-banner-text login-text" routerLink="/login" *ngIf="!loggedInUser">Login / Signup</a>
                <div *ngIf="loggedInUser" class="menu my-garage" (click)="removeHover($event)">
                    <a class="blue-banner-text menu-header" routerLink="/account-details">My Account</a>
                </div>
                <div class="grey-divider"></div>
                <button class="mobile-menu-item menu-close" mat-button>
                    <mat-icon svgIcon="close"></mat-icon>
                </button>
                </div>
                <div (click)="$event.stopPropagation()" class="mobile-menu-item search">
                    <mat-form-field class="mobile-search">
                        <input
                            matInput
                            placeholder="Search"
                            autocomplete="off"
                            [matAutocomplete]="mobileAuto"
                            [formControl]="searchBoxFormControl"
                            (keyup.enter)="onSearchBoxEnterKeyUp($event); triggerMobileMenu()"
                        />
                        <i class="fa fa-search header-background-icon" aria-hidden="true"></i>
                    </mat-form-field>
                    <mat-autocomplete #mobileAuto="matAutocomplete"
                                      (optionSelected)="searchSelected($event); triggerMobileMenu()"
                                      class="autocomplete">
                        <mat-optgroup *ngIf="searchMessage" [label]="searchMessage">
                            <mat-option class="displayNone"></mat-option>
                        </mat-optgroup>
                        <mat-optgroup *ngIf="searchResult.categories && searchResult.categories.length > 0"
                                      label="CATEGORY MATCHES">
                            <mat-option *ngFor="let category of searchResult.categories" [value]="category">
                                {{category.name}}
                            </mat-option>
                        </mat-optgroup>
                        <mat-optgroup *ngIf="searchResult.products && searchResult.products.length > 0"
                                      label="PRODUCT MATCHES">
                            <mat-option *ngFor="let product of searchResult.products" [value]="product">
                                {{product.displayName}}
                            </mat-option>
                        </mat-optgroup>
                    </mat-autocomplete>
                </div>

                <label class="mobile-menu-header">Shop</label>
                <ng-container *ngFor="let category of primaryCategories">
                <button *ngFor="let child of getCategoryChildren(category)" class="mobile-menu-item shop" mat-button
                        (click)="openInternalLink('/products/' + child.url)">{{child.name}}
                </button>
                </ng-container>

                <label class="mobile-menu-header">Information</label>
                <button mat-button class="mobile-menu-item" *ngIf="loggedInUser"
                        (click)="openInternalLink('/account-details')">Account
                </button>
                <button mat-button class="mobile-menu-item" *ngIf="loggedInUser"
                        (click)="openInternalLink('/order-history')">Order History
                </button>
                <button mat-button class="mobile-menu-item" *ngIf="loggedInUser"
                        (click)="openInternalLink('/favorites')">Saved for Later
                </button>

                <button mat-button class="mobile-menu-item" routerLink="/returns-and-refunds">Returns & Exchanges</button>
                <button mat-button class="mobile-menu-item" routerLink="/shipping">Shipping & Handling</button>
                <button mat-button class="mobile-menu-item" target="_blank" href="http://kogentservices.com/">Corporate Site</button>
                <button mat-button class="mobile-menu-item" routerLink="/privacy-policy">Privacy Policy</button>
                <button mat-button class="mobile-menu-item" routerLink="/terms-of-use">Terms of Use</button>
                <button class="mobile-menu-item" mat-button
                        (click)="openInternalLink('/contact-us')">Contact Us
                </button>

                <!--<button mat-button class="mobile-menu-item" *ngIf="loggedInUser" (click)="openInternalLink('/help')">Help</button>-->
                <button mat-button color="accent" class="mobile-menu-item" *ngIf="loggedInUser" (click)="logout()">Logout</button>


            </div>
            <div class="greyed-out"></div>
        </div>

        <!--<div class="image-container mobile" fxLayout="column" (click)="openInternalLink('/')">
          <img src="../../../branding/picture-logo.svg" class="seal-image small">
          <img src="../../../branding/text-logo.svg" class="logo-image small mobile">
        </div>-->

        <!--<div class="image-container mobile" fxLayout="column" (click)="openInternalLink('/')">
          <img src=".../../../branding/picture-logo.svg" class="seal-image small">
          <img src="../../../branding/text-logo.svg" class="logo-image small mobile">
        </div>-->

        <a class="image-container mobile flex items-center" (click)="openInternalLink('/')">
            <img src="../../../assets/growGen-logo.png" alt="Grow Gen logo" class="logo-image small mobile items-center">
            <!--      <img src="../../../branding/text-logo.svg" class="logo-image small mobile">-->
        </a>

        <a class="mobile-a cart" routerLink="/checkout">
            <div class="relative">
            <mat-icon svgIcon="cart" class="custom-icon"></mat-icon>
            <div class="cart-count mobile flex flex-col items-center">
                <span class="count-span mobile">{{cartTotal}}</span>
            </div>
            </div>
        </a>
        <!--<mat-icon svgIcon="cart" class="custom-icon"></mat-icon>-->
    </div>

    <!--<div class="spacer" fxFlex="266px"></div>-->
    <!-- <img src="../../../branding/picture-logo.jpg" class="seal-image" [ngClass]="{'small': smallHeader}">-->
    <!--<button mat-button (click)="handleLogoClick()" class="button">
      <img src="../../../branding/picture-logo.jpg" class="seal-image" [ngClass]="{'small': smallHeader}">
    </button>-->
    <div class="image-search flex justify-between max-md:hidden gap-3">

        <div class="search-container flex flex-col items-start justify-center w-3/4"
             [ngClass]="{'small': smallHeader}">
            <div class="image-container">
                <img src="../../../assets/growGen-logo.png" alt="Kogent logo" class="seal-image"
                     [ngClass]="{'small': smallHeader}" (click)="handleLogoClick()">
            </div>
        </div>
        <div class="flex flex-col justify-center w-1/4 pr-4">
            <div class="cart-and-menu flex gap-3 max-md:hidden">
                <div class="grey-divider"></div>
                <a class="blue-banner-text login-text" routerLink="/login" *ngIf="!loggedInUser">Login / Signup</a>
                <div *ngIf="loggedInUser" class="menu my-garage" (click)="removeHover($event)">
                    <a class="blue-banner-text menu-header" routerLink="/account-details">My Account</a>
                    <div class="menu-contents">
                        <a class="menu-option" routerLink="/account-details">Account Details</a>
                        <a class="menu-option" routerLink="/order-history">Order History</a>
                        <!--            <a class="menu-option" routerLink="/recipes/recipe-box">My Recipe Box</a>-->
                        <a class="menu-option" routerLink="/favorites">Saved For Later</a>
                        <!--<a class="menu-option" routerLink="/help">Help</a>-->
                        <a class="menu-option" (click)="logout()">Logout</a>
                    </div>
                </div>
                <div class="grey-divider"></div>
                <a #cartLink class="cart flex gap-2" routerLink="/checkout">
                    <div class="relative">
                    <mat-icon svgIcon="cart" class="custom-icon"></mat-icon>
                    <!--<i class="fa fa-shopping-cart" aria-hidden="true"></i>-->
                    <div class="cart-count flex items-center">
                        <span class="count-span">{{cartTotal}}</span>
                    </div>
                    </div>
                    Cart
                    <!--<div  fxLayout="column" fxLayoutAlign="space-around">-->
                    <!---->
                    <!--</div>-->
                </a>
            </div>
        </div>
    </div>

    <div class="header-menu flex justify-between gap-3 max-md:hidden p-2 pr-4">
        <div class="flex flex-col w-3/4">
            <div class="menu-options flex flex-col items-start max-md:hidden" [ngClass]="{'small': smallHeader}">
                <div class="menu-options-contents flex flex-wrap items-center">
                    <div *ngFor="let category of primaryCategories" class="flex items-center">
                        <div class="menu category" (click)="removeHover($event)">
                            <span class="menu-header header-menu-item-text">{{category.name}} <i class="fa fa-angle-down"></i></span>
                            <div class="menu-contents" [ngClass]="{'row': getCategoryChildren(category).length > 4}">
                                <!-- <a class="menu-option parent" routerLink="/products/{{category.url}}">{{category.name | uppercase}}</a> -->
                                <div class="flex flex-col gap-1"
                                     *ngFor="let child of getCategoryChildren(category)">
                                    <a class="menu-option child"
                                       routerLink="/products/{{child.url}}">{{child.name | uppercase}}</a>
                                    <a class="menu-option child-of-child"
                                       *ngFor="let superChild of getCategoryChildren(child)"
                                       routerLink="/products/{{superChild.url}}">{{superChild.name | uppercase}}</a>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="flex items-center">
                        <div class="menu category information" (click)="removeHover($event)">
                            <span class="menu-header header-menu-item-text">Resources <i class="fa fa-angle-down"></i></span>
                            <div class="menu-contents">
                                <div class="flex flex-col gap-1">
                                    <a class="menu-option child-of-child" routerLink="/returns-and-refunds">Returns & Exchanges</a>
                                    <a class="menu-option child-of-child" routerLink="/shipping">Shipping & Handling</a>
                                    <a class="menu-option child-of-child" target="_blank" href="http://kogentservices.com/">Corporate Site</a>
                                    <a class="menu-option child-of-child" routerLink="/privacy-policy">Privacy Policy</a>
                                    <a class="menu-option child-of-child" routerLink="/terms-of-use">Terms of Use</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center">
                        <a routerLink="/contact-us" class="header-menu-item-text">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col w-1/4 m-auto">
            <mat-form-field class="search" [ngClass]="{'small': smallHeader}" subscriptSizing="dynamic">
                <i matSuffix id="searchSpinnerIcon" *ngIf="isSearchActive" class="fa fa-spinner fa-spin"
                   aria-hidden="true"></i>
                <input
                    matInput
                    placeholder="Search"
                    autocomplete="off"
                    [minLength]="3"
                    [matAutocomplete]="auto"
                    [formControl]="searchBoxFormControl"
                    (keyup.enter)="onSearchBoxEnterKeyUp($event)"
                />
                <i class="fa fa-search header-background-icon" aria-hidden="true"></i>
              <ng-container *ngIf="showSearchMessage">
                <span class="search-error">Please enter at least <strong>three</strong> characters to search.</span>
              </ng-container>
            </mat-form-field>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="searchSelected($event);"
                              [displayWith]="autoCompleteRenderFn" class="autocomplete">
                <mat-optgroup *ngIf="searchMessage" [label]="searchMessage">
                    <mat-option class="displayNone"></mat-option>
                </mat-optgroup>
                <mat-optgroup *ngIf="searchResult.categories && searchResult.categories.length > 0"
                              label="CATEGORY MATCHES">
                    <mat-option *ngFor="let category of searchResult.categories" [value]="category">
                        {{category.name}}
                    </mat-option>
                </mat-optgroup>
                <mat-optgroup *ngIf="searchResult.products && searchResult.products.length > 0" label="PRODUCT MATCHES">
                    <mat-option *ngFor="let product of searchResult.products" [value]="product">
                        {{product.displayName}}
                    </mat-option>
                </mat-optgroup>
            </mat-autocomplete>
        </div>
    </div>

    <div *ngIf="banner && banner.contentUrl && banner.isActive && banner.onTime" class="promoBanner flex flex-col gap-2">
        <label><a routerLink="/{{banner.contentUrl}}/">{{banner.displayText}}</a></label>
    </div>
    <div class="menu-options flex flex-col items-center max-md:hidden" [ngClass]="{'small': smallHeader}">
        <div class="menu-options-contents flex flex-nowrap items-center">


        </div>
    </div>
</div>
