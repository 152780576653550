import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { environment } from "../../../environments/environment";
import {Observable} from "rxjs";
import {ContentPage} from "../models/content-page.model";

@Injectable()
export class ContentPageService {

  readonly baseUrl = `${environment.url}/content`;

  constructor(private http: HttpClient) { }

  getContent(url: string): Observable<ContentPage> {
    return this.http.get<ContentPage>(`${this.baseUrl}/${url}`);
  }


}
