<h2 mat-dialog-title>Review for Product #{{orderedProduct.itemNumber}}</h2>
<mat-dialog-content >
  <form [formGroup]="reviewForm" class="pt-2 flex-wrap">
    <!--  Review Name-->
    <mat-form-field class="w-full">
      <mat-label>Review Name</mat-label>
      <input matInput formControlName="name" />
      <mat-error *ngIf="reviewForm.get('name')?.hasError('maxlength')">
        Name cannot exceed 255 characters.
      </mat-error>
    </mat-form-field>
    <!--  Star Rating-->
    <div class="flex items-center justify-start">
      <label class="mr-2">Rating:</label>
      <app-star-rating (ratingUpdated)="updateStarRating($event)" [rating]="starRating"></app-star-rating>
    </div>
    <!--  Review Input-->
    <mat-form-field class="w-full">
      <mat-label>Write your review here...</mat-label>
      <textarea matInput formControlName="review"></textarea>
      <mat-error *ngIf="reviewForm.get('review')?.hasError('maxlength')">
        Review cannot exceed 512 characters.
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-flat-button color="" mat-dialog-close>Cancel</button>
	<button mat-flat-button color="primary" [mat-dialog-close]="true" (click)="submit()" [disabled]="reviewForm.invalid">Submit</button>
</mat-dialog-actions>
