import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {Product} from "../core/models/product.model";
import {UpdateCategory, UpdateProducts} from "../core/actions/state.actions";
import {Category} from "../core/models/category.model";
import {TagGroup} from "../core/models/tag-group.model";
import {BreadCrumbEnum} from "../core/enums/bread-crumb.enum";
import {Store} from "@ngrx/store";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {AccountFilter} from "../core/models/account-filter.model";
import {AppStore} from "../core/models/state.model";
import {CategoryViewEnum} from "../core/enums/category-view.enum";
import {StorageService} from "../core/services/storage.service";
import {MatDialog} from "@angular/material/dialog";
import {StorageEnum} from "../core/enums/storage.enum";
import {SeoService} from "../core/services/seo.service";
import {CategoryService} from "../core/services/category.service";
import {Tag} from "../core/models/tag.model";
import {DOCUMENT} from '@angular/common';

import {
  MobileCategorySubCategoriesComponent
} from "./dialogs/mobile-category-sub-categories/mobile-category-sub-categories.component";
import {TagRelation} from "../core/models/tag-relation.model";
import {
  MobileCategoryFilterDialogComponent
} from "../shared/components/mobile-category-filter-dialog/mobile-category-filter.dialog.component";

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  products: Product[];
  categoryUrl: string;
  categories: Category[];
  currentView: CategoryViewEnum;
  CategoryViewEnum: typeof CategoryViewEnum = CategoryViewEnum;
  selectedFilters: number[] = [];
  imageNotFound: boolean = false;
  chosenSortOrder: string;
  storageKey = StorageEnum.CATEGORY_LIST_TYPE;
  filterStorageKey = StorageEnum.SELECTED_FILTERS;
  breadCrumbType = BreadCrumbEnum.CATEGORY;
  sortOptions = [
    // 'Category',
    'Price: Low to High',
    'Price: High to Low',
    'New Arrival',
    // 'Top Rated'
  ];

  tagGroups: TagGroup[];
  tags: Tag[];
  accountFilters: AccountFilter[];

  filterGroups: { title: string, groupId: number, tags: Tag[] }[] = [];

  category: Category = {} as Category;
  categoryProducts: Product[] = [];
  filteredAndSortedProducts: Product[] = [];
  endDisplay: number = 12;

  constructor(private categoryService: CategoryService,
              private route: ActivatedRoute,
              private router: Router,
              private storageService: StorageService,
              private store: Store<AppStore>,
              private dialog: MatDialog,
              @Inject(DOCUMENT) private document: any,
              private seoService: SeoService) {
    store.subscribe(subscribedStore => {
      const state = subscribedStore.state;
      this.tagGroups = state.tagGroups;
      this.tags = state.tags;
      this.categories = state.categories;
      this.accountFilters = state.accountFilters;
      this.products = state.products;
      this.getFilterGroups();
    });

    this.currentView = parseInt(<string>storageService.getItem(this.storageKey));
    if (!this.currentView) {
      this.changeView(CategoryViewEnum.GALLERY);
    }

  }

  @HostListener('window:scroll')
  onScroll() {
    // if it is a primary category then bounce
    if (this.category.primaryCategory) {
      return;
    }

    // if all of the products are already showing then also bounce
    if (this.endDisplay >= this.filteredAndSortedProducts.length) {
      return;
    }

    const categoryElement = this.document.getElementById('category');
    const categoryHeight = categoryElement.clientHeight;
    const clientRect = this.document.body.getBoundingClientRect();
    const scrollPosition = Math.abs(clientRect.top) + clientRect.height;

    if (categoryHeight <= scrollPosition) {
      this.endDisplay = this.endDisplay + 12;
    }
  }


  get subCategories(): Category[] {

    let categories: Category[] = [];
    if (this.category && this.categories && this.category.primaryCategory && this.category.categoryRelations?.length) {
      categories = this.categories.filter(cat => this.category.categoryRelations.findIndex(cr => cr.categoryId === cat.id) > -1)
        .sort((catA, catB) => {
          const catAIndex = this.category.categoryRelations.findIndex(cr => cr.categoryId === catA.id);
          const catBIndex = this.category.categoryRelations.findIndex(cr => cr.categoryId === catB.id);

          return this.category.categoryRelations[catAIndex].displaySeq - this.category.categoryRelations[catBIndex].displaySeq;
        });
    }

    return categories;
  }


  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.categoryUrl = params['id'];
      if (!this.categoryUrl) {
        this.notFound();
      } else {
        this.getCategoryById();
      }
    });
  }

  notFound() {
    this.router.navigateByUrl('/category/not-found', { skipLocationChange: true });
  }

  getCategoryById() {
    this.route.data.subscribe(resolve => {

      const category: Category = resolve['resolve'];

      if (!category) {
        this.notFound();
        return
      }

      const title = `${category?.name} | Grow Generation`
      const description = `${category.name} ${category.description ? category.description.replace( /(<([^>]+)>)/ig, '') : ''}`
      this.seoService.setTitle(title);
      this.seoService.setMetaTitle(title);
      this.seoService.setMetaDescription(description);
      const keywords = `${category.name},Humvee ${category.name},HMMWV ${category.name},HMMWV,Humvee,Grow Generation${category.metaKeyword ? ', ' + category.metaKeyword : ''},` +
        this.tags.map((t) =>
        {
          let keyword = t.description?.replace(/<p>Includes:?<\/p>/g, '');
          keyword = keyword?.replace(/<p>/g, ',');
          keyword = keyword?.replace(/<\/p>/g, ' ' + category.name.toLowerCase());
          return keyword;
        });
      this.seoService.setMetaKeywords(keywords);

      // handle setting up the filters here
      // we have a few choices
      // the first is account filters are the highest priority and then the session is lowest
      // or the session is the highest and then the account is lowest
      // i think it make more sense for the session to be the highest since that is what the user was just playing with

      // first run though
      this.selectedFilters = this.accountFilters ? this.accountFilters?.map(af => af.tagId) : [];

      const storageFilters = this.storageService.getItem(this.filterStorageKey);

      // second run through
      if (storageFilters) {
        this.selectedFilters = storageFilters.split(',').map(i => parseInt(i));
      }

      this.category = category;
      if(category.products) {
        this.products = category.products;
        this.categoryProducts = category.products;
        this.store.dispatch(new UpdateProducts(category.products));
      }
      this.store.dispatch(new UpdateCategory(category));
      this.getFilteredAndSortedProducts();
      this.getFilterGroups();

    }, error => {
      console.error(error);
    });
  }

  getCategoryProducts(): Product[] {
    let products: Product[] = [];
    if (this.category && this.category.productCategories && this.products) {
      products = this.products
        .filter(prod => this.category.productCategories.findIndex(pc => pc.itemNumber === prod.itemNumber) > -1);
    }

    return products;
  }

  get headerImageUrl(): string {
    return this.category?.breadcrumbs[0]?.image?.url ?? '';
  }

  get sortOrder(): string {
    return this.chosenSortOrder;
  }

  set sortOrder(newOrder: string) {
    this.chosenSortOrder = newOrder;
    this.getFilteredAndSortedProducts();
  }

  get longDescription(): boolean {
    return this.category?.description ? (this.category.description.length > 150) : false;
  }

  getFilteredAndSortedProducts() {
    let products: Product[] = [];

    if (this.category && this.categoryProducts) {

      products = this.categoryProducts
        .filter(prod => this.category.productCategories?.findIndex(pc => pc.itemNumber === prod.itemNumber) > -1)
        .filter(prod => this.selectedFilters?.length ? this.selectedFilters.every(filter => prod.tagRelations?.findIndex(tag => tag.tagId === filter) > -1) : true)
        .sort((a, b) => this.productSort(a, b));
    }

    this.filteredAndSortedProducts = products;
  }

  get breadcrumbTitle(): string {
    if (this.filteredAndSortedProducts && this.filteredAndSortedProducts.length > 0) {
      return `${this.filteredAndSortedProducts.length} Items`;
    }

    return '';
  }

  bottomRow(items: number): number {
    const totalRows = this.filteredAndSortedProducts.length / items;

    const secondToLastRow = Math.ceil(totalRows - 1);

    const numberOfItemsInRows = secondToLastRow * items;

    return numberOfItemsInRows - 1;
  }

  getFilterGroups() {
    this.filterGroups = [];

    if (this.tagGroups && this.tags && this.categoryProducts) {
      const filterTagGroups = this.tagGroups.filter((tg: TagGroup) => tg.filter);

      //FIXME any type
      const tagRelations: TagRelation[] = this.categoryProducts.flatMap(prod => prod.tagRelations);

      const filters = this.tags.filter((tag: Tag) => filterTagGroups.findIndex((tg: TagGroup) => tg.id === tag.tagGroupId) > -1)
        .filter((tag: Tag) => tagRelations.findIndex((tr: TagRelation) => tr.tagId === tag.id) > -1 || this.selectedFilters?.includes(tag.id));

      filters.forEach((filter) => {
        const index = this.filterGroups.findIndex(fg => fg.groupId === filter.tagGroupId);

        if (index > -1) {
          this.filterGroups[index].tags.push(filter);
          this.filterGroups[index].tags.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            } else if (a.name > b.name) {
              return 1;
            } else {
              return 0;
            }
          });
        } else {
          const tagGroup = filterTagGroups.find(tg => tg.id === filter.tagGroupId);
          if(tagGroup){
            this.filterGroups.push({
              title: tagGroup.name,
              groupId: filter.tagGroupId,
              tags: [filter]
            });
          }
        }
      });

      this.filterGroups.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        } else {
          return 0;
        }
      });
      this.getFilteredAndSortedProducts();
    }
  }

  isFilterSelected(filter: Tag): boolean {
    return this.selectedFilters?.includes(filter.id);
  }

  filterIsSelected(filter: Tag) {
    const index = this.selectedFilters?.indexOf(filter.id);
    if (index > -1) {
      this.selectedFilters.splice(index, 1);
    } else {
      this.selectedFilters.push(filter.id);
    }

    this.storageService.setItem(this.filterStorageKey, this.selectedFilters.join(',').toString());

    this.getFilteredAndSortedProducts();
  }

  changeView(view: CategoryViewEnum) {
    if (this.currentView === view) {
      return;
    }

    this.storageService.setItem(this.storageKey, view.toString());
    this.currentView = view;

    // reset the end number.
    this.endDisplay = 12;

    // this.store.dispatch(new UpdateCategoryView(view));
  }

  productSort(productA: Product, productB: Product): number {
    switch (this.sortOrder) {
      // case 'Category':
      //   return 0;
      case 'Price: Low to High':
        return productA.productPrice.price - productB.productPrice.price;
      case 'Price: High to Low':
        return productB.productPrice.price - productA.productPrice.price;
      case 'New Arrival':
        const dateA = new Date(productA.createdTs);
        const dateB = new Date(productB.createdTs);

        if (dateA < dateB) {
          return 1;
        } else if (dateA > dateB) {
          return -1;
        } else {
          return 0;
        }

      // case 'Top Rated':
      //   break;
      default:
        if (productA.displayName < productB.displayName) {
          return -1;
        } else if (productA.displayName > productB.displayName) {
          return 1;
        } else {
          return 0;
        }
    }
  }

  imageError() {
    this.imageNotFound = true;
  }

  openFilterDialog() {
    const dialogRef = this.dialog.open(MobileCategoryFilterDialogComponent, {
      width: '80%',
      data: {
        filterGroups: this.filterGroups.slice(),
        selectedFilters: this.selectedFilters.slice()
      }
    });

    dialogRef.afterClosed().subscribe(newFilters => {
      if (!newFilters) {
        return;
      }

      this.selectedFilters = newFilters;
      this.getFilteredAndSortedProducts();
    });
  }

}
