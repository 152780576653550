<div class="flex flex-col">
	<div class="flex flex-wrap justify-center items-stretch">
		<div class="checkout-main flex flex-col w-full md:w-3/4" appMobileView>
			<div *ngIf="shoppingCart.items?.length && shoppingCart.items.length > 0; then fullCart; else emptyCart"></div>

			<ng-template #emptyCart>
				<h2 class="pl-2">No Items</h2>
			</ng-template>

			<ng-template #fullCart>
				<div class="flex flex-col">
					<app-product-card-cart
						*ngFor="let item of shoppingCart.items"
						[item]="item"
						(updateShoppingCartItem)="delayUpdate($event)"
						(deleteShoppingCartItem)="delete($event)"
					></app-product-card-cart>
				</div>
			</ng-template>
		</div>

		<div class="flex flex-col w-full md:w-1/4">
			<div appMobileView>
				<div class="flex flex-col justify-center promo-container">
					<div class="flex" *ngIf="activePromoCode">
						<label class="promo-active">Promo Code {{ activePromoCode }} is currently active.</label>
					</div>

					<div class="flex">
						<mat-form-field class="promo" subscriptSizing="dynamic">
							<input matInput placeholder="Promo Code" [(ngModel)]="promoCode" (keyup.enter)="applyPromoCode()" />
						</mat-form-field>
						<div *ngIf="!promoWaiting" class="flex justify-center">
							<i *ngIf="promoSuccess === true" class="fa fa-check green promo-icon"></i>
							<i *ngIf="promoSuccess === false" class="fa fa-times red promo-icon"></i>
							<button mat-button *ngIf="!promoSuccess" class="promo-button" (click)="applyPromoCode()">Apply</button>
						</div>
						<div *ngIf="promoWaiting" class="flex justify-center">
							<mat-spinner [diameter]="22"></mat-spinner>
						</div>
					</div>
					<div *ngIf="promoMessages.length > 0" class="flex flex-col justify-center">
						<label class="promo-error red" *ngFor="let message of promoMessages">{{ message }}</label>
					</div>
				</div>
			</div>
			<div class="checkout-grey-box" appMobileView>
				<app-checkout-summary [shoppingCart]="shoppingCart"></app-checkout-summary>
				<div class="checkout-sidebar-section action flex flex-col justify-center">
					<button
						mat-button
						class="checkout-button"
						(click)="next()"
						[disabled]="checkoutDisabled || promoWaiting || (shoppingCart.items && shoppingCart.items.length < 1)"
					>
						CHECKOUT
					</button>
					<label class="promo-error red" *ngIf="showCheckoutDisabledMessage && shoppingCart.items?.length && shoppingCart.items.length > 0"
						>Please log in to checkout.</label
					>
				</div>
			</div>

			<!-- <div class="checkout-grey-box checkout-help-box" [ngClass.lt-md]="'mobile-view'" fxLayout="column">
                <label class="checkout-summary-title">Need Assistance?</label>
                <div fxLayout="column" fxLayoutGap="8px">
                    <label>Email:<br>
                        <a class="checkout-email checkout-assistance" href="mailto:noreply@kodriver.com">noreply@kodriver.com</a></label>
                    <label>Phone:<br>
                        <a class="checkout-email checkout-assistance" href="tel:800-348-8833">(800) 348-6833</a></label>
                </div>
            </div> -->
		</div>
	</div>

	<!-- app-related-products [itemNumbers]="itemNumbers"></app-related-products -->
</div>
