import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Address} from "../models/address.model";
import {Observable} from "rxjs";

@Injectable()
export class AddressService {
  readonly baseUrl = `${environment.url}/address`;

  constructor(private http: HttpClient) {
  }

  create(address: Address): Observable<Address[]> {
    return this.http.post<Address[]>(this.baseUrl, address);
  }

  update(address: Address): Observable<Address[]> {
    return this.http.put<Address[]>(`${this.baseUrl}/${address.id}`, address);
  }

  delete(address: Address): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${address.id}`);
  }
}
