import {APP_INITIALIZER, Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {State} from '../models/state.model';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';

@Injectable()
export class AppInitService {
	constructor(private authService: AuthService, private location: Location, private router: Router, private http: HttpClient) {}
	readonly baseUrl: string = `${environment.url}/initial-load`;
	appData: State;

	async initialLoad(): Promise<State> {
		return await firstValueFrom(this.http.get<State>(this.baseUrl))
      .then((initialLoad: State) => (this.appData = initialLoad));
		// this.authService.getCurrentUser().subscribe({
		// 	next: (response) => {
		//     console.log(response)
		// 		this.authService.userLoggedIn = true;
		// 		this.authService.userDataSource.next(response.user);
		// 		resolve();
		// 	},
		// 	error: (err) => {
		//     // TODO: session management
		//     // allow unauth access for now
		// 		//this.authService.redirectUrl = this.location.path();
		// 		//this.router.navigate(['/login']);
		// 		resolve();
		// 	}
		// });
	}
}

export function app_init(appInitService: AppInitService): Function {
	return () => appInitService.initialLoad();
}

export const AppInitProvider = {provide: APP_INITIALIZER, useFactory: app_init, deps: [AppInitService], multi: true};
