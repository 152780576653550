import {Injectable}         from '@angular/core';
import {HttpClient}         from '@angular/common/http';
import {Observable} from 'rxjs';
import {Account}            from '../models/account.model';
import {environment}        from '../../../environments/environment';
import {User} from "../models/user.interface";
import {Login} from "../models/login.model";
import {ShoppingCartItem} from "../models/shopping-cart.model";
import {LoginResponse} from "../models/login-response.interface";

@Injectable()
export class LoginService {
    readonly baseUrl = `${environment.url}/login`;
    readonly logoutUrl = `${environment.url}/logout`;

    constructor(private http: HttpClient) {
    }

    getSignUpDisableStatus(): Observable<{ success: boolean, result: boolean }> {
      return this.http.get<{ success: boolean, result: boolean }>(`${this.baseUrl}/signUpDisabled`);
    }

    getCheckoutDisableStatus(): Observable<{ success: boolean, result: boolean }> {
      return this.http.get<{ success: boolean, result: boolean }>(`${this.baseUrl}/checkOutDisabled`);
    }

    forgotPassword(user: User): Observable<{ success: boolean, result: string }> {
        return this.http.post<{ success: boolean, result: string }>(`${this.baseUrl}/forgot-password`, user);
    }

    signIn(user: User): Observable<LoginResponse> {
      const loginCreds: Login = new Login(user.email, user.password);
        return this.http.post<LoginResponse>(`${this.baseUrl}`, loginCreds);
    }

    signUp(newUser: User): Observable<User> {
        return this.http.post<User>(`${this.baseUrl}/new`, newUser);
    }

    signOut(): Observable<boolean> {
        return this.http.get<boolean>(this.logoutUrl);
    }
}
