import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {ProductsService} from "../services/products.service";
import {ObservableInput, of, tap} from "rxjs";
import {catchError} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {AppStore} from "../models/state.model";
import { UpdateProducts } from '../actions/state.actions';

@Injectable()
export class ProductResolver implements Resolve<any> {
	constructor(private categoryService: ProductsService, private router: Router, private store: Store<AppStore>) {}

	resolve(route: ActivatedRouteSnapshot) {
		const url = route.params['id'];
		if (!url) {
			return this.notFound();
		}
		return this.categoryService.getProduct(url).pipe(
      tap(value => this.store.dispatch(new UpdateProducts([value]))),
			catchError(err => this.handleError(err)));
	}

	handleError(err: any): ObservableInput<null> {
		console.log(err);
		this.notFound();
		return of(err)
	}

	notFound() {
		this.router.navigateByUrl('/not-found', {skipLocationChange: true});
	}
}
