<div class="flex flex-col" appMobileView>

    <div class="bread-crumb-options product-header flex md:flex-col justify-between gap-4">
        <div class="mobile-divider md:hidden"></div>
        <div class="bread-crumbs flex">
<!--            <app-breadcrumbs [type]="breadCrumbType" [relatedId]="product.itemNumber"-->
<!--                             [breadcrumbs]="product.breadcrumbs"></app-breadcrumbs>-->
        </div>
        <!-- <div class="gradient-grey-border" fxHide.lt-md="true" fxFlex></div> -->
    </div>

    <div class="product-container justify-between hidden md:flex">
        <div class="detail-container flex flex-col items-start w-3/4">
            <div class="product-details flex">
                <app-image-viewer [images]="images"
                                  (currentImageUpdate)="updateCurrentImage($event)"></app-image-viewer>

                <div class="product-info flex flex-col justify-between gap-2">
                    <!-- <span class="product-brand">{{product.brandName | titlecase}}</span> -->
                    <div class="flex gap-2 justify-between">
                        <div class="flex flex-col">
                            <label class="product-name">{{product.displayName}}</label>
                            <!--  review here-->
                          <div class="pb-2 flex items-center">
                            <span class="mr-1 product-item-number">Avg. Rating:</span>
                            <i *ngIf="product.reviews && product.reviews.length == 0">No reviews yet</i>
                            <app-star-rating *ngIf="product.reviews && product.reviews.length > 0" [displayMode]="true" [rating]="averageRating"></app-star-rating>
                          </div>

                            <span class="product-item-number">ITEM #{{product.itemNumber}}</span>
                            <span class="product-item-number">NSN {{product.shortDescription}}</span>
                            <span
                                class="product-item-number">WEIGHT {{product.itemWeight}} {{product.itemWeightUom ? product.itemWeightUom : 'lbs'}}</span>
                            <span class="product-item-number"
                                  *ngIf="product.productAvailability.availableQuantity && (product.productAvailability.availableQuantity <= 0) && !showStockIsDisabled">OUT OF STOCK</span>
                        </div>

                    </div>


                    <div class="product-description" *ngIf="product.longDescription"
                         [innerHtml]="product.longDescription  | safeHtml"></div>


                </div>
            </div>
            <div class="flex flex-col gap-2 md:hidden">

                <div class="flex flex-col gap-2 justify-center">
                    <app-image-viewer [images]="product.images"
                                      (currentImageUpdate)="updateCurrentImage($event)"></app-image-viewer>
                </div>

                <div class="flex flex-col gap-2">
                    <span class="product-brand">{{product.brandName | titlecase}}</span>
                    <label class="product-name-large">{{product.displayName}}</label>
                    <div class="flex gap-4 justify-between">
                        <div class="flex flex-col">
                            <span class="product-item-number">ITEM #{{product.itemNumber}}</span>
                            <span
                                class="product-item-number">WEIGHT {{product.itemWeight}} {{product.itemWeightUom ? product.itemWeightUom : 'lbs'}}</span>
                            <span class="product-item-number" *ngIf="product.productAvailability.availableQuantity <= 0">OUT OF STOCK</span>

                            <!--          <div class="grey-divider"></div>-->
                            <span class="product-size">{{package}}</span>
                        </div>
                        <div class="flex favorite-container">
                            <a class="product-button heart-icon" *ngIf="!isFavorite" matTooltip="Save for Later"
                               (click)="addToFavorites()">
                                <i class="fa fa-heart-o" aria-hidden="true"></i>
                            </a>
                            <a class="product-button heart-icon" *ngIf="isFavorite" matTooltip="Remove from Saved"
                               (click)="deleteFromFavorites()">
                                <i class="fa fa-heart" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                    <div class="add-to-cart-container flex gap-2"
                         *ngIf="product.productAvailability.availableQuantity > 0 || quantityDisabled">
                        <div class="flex gap-2">
                            <div class="flex gap-2">
                                <label class="qty-label">Qty</label>

                                <div class="qty-control flex">
                                    <a class="product-button quantity-icon left" (click)="subtractOne()">
                                        -
                                    </a>
                                    <div class="blue-border"></div>
                                    <label class="product-quantity">{{quantity}}</label>
                                    <div class="blue-border"></div>
                                    <a class="product-button quantity-icon right" (click)="addOne()">
                                        +
                                    </a>
                                </div>
                            </div>
                            <label class="product-price">{{product.productPrice.price | currency:'USD':'symbol':'0.2-2'}}</label>
                        </div>
                        <app-add-to-cart [style]="addToCartStyle" [product]="product" [qty]="quantity"
                                         [isDisabled]="(product.productAvailability.availableQuantity <= 0 && !quantityDisabled) || product.productPrice.price <= 0"></app-add-to-cart>
                    </div>
                    <!--      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center">-->
                    <!--        <app-social-media-share [name]="product.displayName" [image]="currentImage"></app-social-media-share>-->
                    <!--        <a class="product-button" *ngIf="!isFavorite" (click)="addToFavorites()">-->
                    <!--          <i class="fa fa-heart-o" aria-hidden="true"></i>-->
                    <!--        </a>-->
                    <!--        <a class="product-button" *ngIf="isFavorite" (click)="deleteFromFavorites()">-->
                    <!--          <i class="fa fa-heart" aria-hidden="true"></i>-->
                    <!--        </a>-->
                    <!--      </div>-->

                    <div class="attributes flex flex-col">
                        <span class="product-attribute list-item" *ngFor="let tag of attributes">{{tag.name}}</span>
                    </div>
                    <div class="product-perishable" *ngIf="product.perishable">* NOTE: This item is perishable.
                        Overnight
                        shipping is required.
                    </div>
                    <div class="product-perishable" *ngIf="displayCatchWeight">* NOTE: This item is cut from bulk
                        portions. The
                        actual weight and price charged may vary from the listed information depending on cuts available
                        at the
                        time of shipping.
                    </div>
                    <div class="product-description" [innerHtml]="product.longDescription  | safeHtml"></div>
                </div>
            </div>
          <app-product-reviews
            *ngIf="product.reviews"
            [reviews]="product.reviews"
            class="w-full"
          ></app-product-reviews>
        </div>
        <div class="purchase-container w-1/4 flex flex-col justify-start" >
            <div class="favorite-container flex">
                <button class="product-button heart-icon" *ngIf="!isFavorite" matTooltip="Save for Later"
                        (click)="addToFavorites()">
                    <i class="fa fa-heart-o" aria-hidden="true"></i>
                </button>
                <button class="product-button heart-icon" *ngIf="isFavorite" matTooltip="Remove from Saved"
                        (click)="deleteFromFavorites()">
                    <i class="fa fa-heart" aria-hidden="true"></i>
                </button>
                Save for Later
            </div>
            <div class="product-pq flex">
                <label class="product-price">{{product.productPrice.price | currency:'USD':'symbol':'0.2-2'}}</label>
                <!--<label class="product-size">{{product.productAvailability.availableQuantity}} Available</label>-->
                <div *ngIf="product.productAvailability.availableQuantity > 0 || quantityDisabled"
                     class="add-to-cart-container flex gap-2 justify-between">
                    <div class="flex gap-2">
                        <div class="qty-control flex" >
                            <a class="product-button quantity-icon left" (click)="subtractOne()">
                                -
                            </a>
                            <div class="blue-border"></div>
                            <label class="product-quantity">{{quantity}}</label>
                            <div class="blue-border"></div>
                            <a class="product-button quantity-icon right" (click)="addOne()">
                                +
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-add">
                <app-add-to-cart [style]="addToCartStyle" [product]="product" [qty]="quantity"
                                 [isDisabled]="(product.productAvailability.availableQuantity <= 0 && !quantityDisabled) || product.productPrice.price <= 0"></app-add-to-cart>
            </div>
        </div>

    </div>

    <div class="product-container flex flex-col justify-between md:hidden">
        <app-image-viewer [images]="images"
                          (currentImageUpdate)="updateCurrentImage($event)"></app-image-viewer>
        <div class="product-details flex">

            <div class="product-info flex flex-col gap-2 justify-between">
                <!-- <span class="product-brand">{{product.brandName | titlecase}}</span> -->
                <div class="flex gap-2 justify-between">
                    <div class="flex flex-col" >
                        <label class="product-name">{{product.displayName}}</label>
                      <div class="pb-2 flex items-center">
                        <span class="mr-1 product-item-number">Avg. Rating:</span>
                        <i *ngIf="product.reviews && product.reviews.length == 0">No reviews yet</i>
                        <app-star-rating [displayMode]="true" [rating]="averageRating" *ngIf="product.reviews && product.reviews.length > 0"></app-star-rating>
                      </div>
                        <div class="flex justify-between">
                            <div class="flex flex-col">
                                <span class="product-item-number">ITEM #{{product.itemNumber}}</span>
                                <span class="product-item-number">NSN {{product.shortDescription}}</span>
                                <span
                                    class="product-item-number">WEIGHT {{product.itemWeight}} {{product.itemWeightUom ? product.itemWeightUom : 'lbs'}}</span>
                                <span class="product-item-number"
                                      *ngIf="product.productAvailability.availableQuantity <= 0 && !showStockIsDisabled">OUT OF STOCK</span>
                            </div>
                            <div class="favorite-container flex">
                                <button class="product-button heart-icon" *ngIf="!isFavorite" matTooltip="Save for Later"
                                        (click)="addToFavorites()">
                                    <i class="fa fa-heart-o" aria-hidden="true"></i>
                                </button>
                                <button class="product-button heart-icon" *ngIf="isFavorite" matTooltip="Remove from Saved"
                                        (click)="deleteFromFavorites()">
                                    <i class="fa fa-heart" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="product-description" *ngIf="product.longDescription"
                     [innerHtml]="product.longDescription  | safeHtml"></div>
            </div>
        </div>
        <div class="purchase-container ">
            <div class="product-pq flex justify-between">
                <label class="product-price">{{product.productPrice.price | currency:'USD':'symbol':'0.2-2'}}</label>
                <!--<label class="product-size">{{product.productAvailability.availableQuantity}} Available</label>-->
                <div *ngIf="product.productAvailability.availableQuantity > 0 || quantityDisabled" class="add-to-cart-container flex gap-2 justify-between">
                    <div class="flex gap-2">
                        <div class="qty-control flex">
                            <a class="product-button quantity-icon left" (click)="subtractOne()">
                                -
                            </a>
                            <div class="blue-border"></div>
                            <label class="product-quantity">{{quantity}}</label>
                            <div class="blue-border"></div>
                            <a class="product-button quantity-icon right" (click)="addOne()">
                                +
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <app-add-to-cart class="w-full" [style]="addToCartStyle" [product]="product" [qty]="quantity"
                                 [isDisabled]="(product.productAvailability.availableQuantity <= 0 && !quantityDisabled) || product.productPrice.price <= 0"></app-add-to-cart>
        </div>
      <app-product-reviews
        *ngIf="product.reviews"
        [reviews]="product.reviews"
        class="w-full"
      ></app-product-reviews>
    </div>
</div>
