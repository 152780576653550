<div class="footer-container flex flex-col">
    <div class="footer-body flex flex-row gap-4 justify-between w-full max-md:hidden">

        <div class="copyright flex flex-col w-1/5 ">
            <!-- <span>© Copyright 2019 KOgent Systems  -  All Rights Reserved</span> -->
          <img src="../../../assets/growGen-logo.png" alt="GrowGen logo" class="seal-image">
        </div>

        <div *ngFor="let category of primaryCategories" [ngClass]="{'hidden': !primaryCategories || !primaryCategories.length}"
             class="flex flex-col w-1/5">
            <label class="blue-text category-parent">Shop</label>
            <div class="flex flex-col justify-between">
                <a *ngFor="let child of getCategoryChildren(category)" class="category-child"
                   routerLink="/products/{{child.url}}">{{child.name | titlecase}}</a>
            </div>
            <a class="blue-text pad" routerLink="/manuals">Manuals</a>
        </div>

        <div class="flex flex-col w-2/5">
            <label class="blue-text">Information</label>
            <div class="flex flex-col justify-between">
                <a routerLink="/login" *ngIf="!loggedInUser">Login</a>
                <a routerLink="/account-details" *ngIf="loggedInUser">Account</a>
                <a routerLink="/returns-and-refunds">Returns & Exchanges</a>
                <a routerLink="/shipping">Shipping & Handling</a>
                <a target="_blank" href="https://amgeneral.com">Corporate Site</a>
                <a routerLink="/privacy-policy">Privacy Policy</a>
                <a routerLink="/terms-of-use">Terms of Use</a>
            </div>
            <a class="blue-text pad" routerLink="/contact-us">Contact Us</a>
        </div>

        <div class="footer-social-container flex flex-col w-1/5 justify-between">
            <label class="blue-text">Follow Grow Generation</label>
            <div class="social-media-row flex flex-row gap-2">
                <a class="social-media-container no-default flex flex-row justify-around"
                   target="_blank"
                   href="https://www.linkedin.com/company/kogent-systems-llc">
                    <div class="white-circle">
                        <i class="fa fa-linkedin social-media-icon"></i>
                    </div>
                </a>

                <!--a class="social-media-container no-default flex flex-row justify-around"
                   target="_blank"
                   href="https://www.facebook.com/">
                    <div class="white-circle">
                        <i class="fa fa-facebook  social-media-icon" aria-hidden="true"></i>
                    </div>
                </a-->

                <a class="social-media-container no-default flex flex-row justify-around"
                   target="_blank"
                   href="https://www.youtube.com/@kogentservicesllc5292">
                    <div class="white-circle">
                        <i class="fa fa-youtube-play  social-media-icon" aria-hidden="true"></i>
                    </div>
                </a>

                <!--a class="social-media-container no-default flex flex-row justify-around"
                   target="_blank"
                   href="https://twitter.com/">
                    <div class="white-circle">
                        <i class="fa fa-twitter social-media-icon" aria-hidden="true"></i>
                    </div>
                </a-->

                <!-- <a fxLayout="row" class="social-media-container no-default" fxLayoutAlign="space-around" target="_blank"
                  href="https://www.flickr.com/photos/145273635@N08/">
                  <div class="white-circle">
                    <i class="fa fa-flickr  social-media-icon" aria-hidden="true"></i>
                  </div>
                </a> -->
            </div>
            <div class="copyright-info">
                <div class="copyright-line">
                    <span>©2023 KOgent Systems, LLC. All rights reserved.</span>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-body mobile flex-col hidden max-md:flex">

        <div class="social-media-container-mobile mobile flex flex-row gap-3 items-center">
            <div class="copyright flex flex-col">
                <!-- <span>© Copyright 2019 KOgent Systems  -  All Rights Reserved</span> -->
                FOLLOW KOGENT SERVICES
                <div class="flex flex-row items-center">
                    <a class="social-media-container no-default flex flex-row justify-around"
                       target="_blank"
                       href="https://www.linkedin.com/company/kogent-systems-llc">
                        <div class="white-circle">
                            <i class="fa fa-linkedin social-media-icon"></i>
                        </div>
                    </a>

                    <!--a class="social-media-container no-default flex flex-row justify-around"
                       target="_blank"
                       href="https://www.facebook.com/">
                        <div class="white-circle">
                            <i class="fa fa-facebook  social-media-icon" aria-hidden="true"></i>
                        </div>
                    </a-->

                    <a class="social-media-container no-default flex flex-row justify-around"
                       target="_blank"
                       href="https://www.youtube.com/@kogentservicesllc5292">
                        <div class="white-circle">
                            <i class="fa fa-youtube-play  social-media-icon" aria-hidden="true"></i>
                        </div>
                    </a>

                    <!--a class="social-media-container no-default flex flex-row justify-around"
                       target="_blank"
                       href="https://twitter.com/">
                        <div class="white-circle">
                            <i class="fa fa-twitter social-media-icon" aria-hidden="true"></i>
                        </div>
                    </a-->

                    <!-- <a fxLayout="row" class="social-media-container no-default" fxLayoutAlign="space-around" target="_blank"
                      href="https://www.flickr.com/photos/">
                      <div class="white-circle">
                        <i class="fa fa-flickr  social-media-icon" aria-hidden="true"></i>
                      </div>
                    </a> -->
                </div>
            </div>
        </div>

        <div class="flex flex-row justify-around">

                <div class="flex flex-col gap-1 justify-between">
                    <ng-container *ngFor="let category of primaryCategories">
                        <label class="blue-text category-parent">Shop</label>
                        <div class="flex flex-col justify-between">
                            <a *ngFor="let child of getCategoryChildren(category)" class="category-child"
                               routerLink="/products/{{child.url}}">{{child.name | titlecase}}</a>
                        </div>
                    </ng-container>
                    <a class="blue-text pad" routerLink="/manuals">Manuals</a>
                    <div class="copyright-info">
                    <div class="copyright-line">
                        <span>©2023 KOgent Systems, LLC. All rights reserved.</span>
                    </div>
                    </div>
                </div>

                <div class="flex flex-col gap-1">
                    <label class="blue-text">Information</label>
                    <div class="flex flex-col justify-between">
                        <a routerLink="/login" *ngIf="!loggedInUser">Login</a>
                        <a routerLink="/account-details" *ngIf="loggedInUser">Account</a>
                        <a routerLink="/returns-and-refunds">Returns & Exchanges</a>
                        <a routerLink="/shipping">Shipping & Handling</a>
                        <a target="_blank" href="https://amgeneral.com">Corporate Site</a>
                        <a routerLink="/privacy-policy">Privacy Policy</a>
                        <a routerLink="/terms-of-use">Terms of Use</a>
                    </div>
                    <a class="blue-text pad" routerLink="/contact-us">Contact Us</a>
                </div>
        </div>



        <!--    <div fxLayout="row" fxLayoutAlign="end" class="copyright">-->
        <!--        &lt;!&ndash; <span>© Copyright 2019 KOgent Systems  -  All Rights Reserved</span> &ndash;&gt;-->
        <!--        <img src="../../../branding/footer-logo.jpg" class="footer-logo" />-->
        <!--    </div>-->
    </div>
</div>
