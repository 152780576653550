import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppStore} from '../../models/state.model';
import {Store} from '@ngrx/store';
import {StripeCustomer, StripeSource} from '../../models/stripe.model';
import {AccountDetailsService} from '../../services/account-details.service';
import {Router} from '@angular/router';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {PaymentService} from '../../services/payment.service';
import {EditAddressDialogComponent} from '../../../shared/components/edit-address-dialog/edit-address-dialog.component';
import {AddressService} from '../../services/address.service';
import {TagGroup} from '../../models/tag-group.model';
import {Tag} from '../../models/tag.model';
import {AccountFilter} from '../../models/account-filter.model';
import {AccountFilterService} from '../../services/account-filter.service';
import {ConfirmationDialogComponent} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {Address} from '../../models/address.model';
import {LogoutUser, UpdateAccountDetail, UpdateAddresses} from '../../actions/state.actions';
import {FormControl, Validators} from '@angular/forms';
import {NewsLetterService} from '../../services/news-letter.service';
import {Constants} from '../../../shared/config/constants.config';
import {LoginService} from '../../services/login.service';
import {StorageService} from '../../services/storage.service';
import {StorageEnum} from '../../enums/storage.enum';
import {SeoService} from '../../services/seo.service';
import {User} from "../../models/user.interface";
// import {UpdateSourceDialogComponent} from './dialogs/update-source.dialog.component';
// import {DeletePaymentMethodDialogComponent} from './dialogs/delete-payment-method.dialog.component';
// import {CreateSourceDialogComponent} from './dialogs/create-source.dialog.component';


@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit, OnDestroy {

  loggedInUser: User;
  stripeCustomer: StripeCustomer;

  tags: Tag[];
  tagGroups: TagGroup[];
  accountFilters: AccountFilter[];
  addresses: Address[];
  selectedTags: number[] = [];
  smallScreen: boolean = false;
  newFirstName = '';
  newLastName = '';
  newEmail = '';
  oldPassword = '';
  newPassword = '';
  confirmNewPassword = '';
  newsLetterIsDisabled = false;

  firstNameFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(Constants.nameAndAddressRegex)
  ]);

  lastNameFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(Constants.nameAndAddressRegex)
  ]);

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(Constants.emailRegex)
  ]);

  passwordFormControl = new FormControl('', [
    Validators.required
  ]);

  newPasswordFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$')
  ]);

  newPasswordConfirmFormControl = new FormControl('', [
    Validators.required
  ]);

  snackBarConfig = new MatSnackBarConfig();
  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  constructor(private accountDetailsService: AccountDetailsService,
              private router: Router,
              private store: Store<AppStore>,
              private dialog: MatDialog,
              private storageService: StorageService,
              private accountFilterService: AccountFilterService,
              private addressService: AddressService,
              private paymentService: PaymentService,
              private newsLetterService: NewsLetterService,
              private loginService: LoginService,
              private seoService: SeoService,
              private snackBar: MatSnackBar) {

    store.subscribe(subscribedStore => {
      const state = subscribedStore.state;
      this.loggedInUser = state.user!;
      this.newFirstName = this.loggedInUser === undefined ? '' : this.loggedInUser.firstName;
      this.newLastName = this.loggedInUser === undefined ? '' : this.loggedInUser.lastName;
      this.newEmail = this.loggedInUser === undefined ? '' : this.loggedInUser.email;
      // this.tags = state.tags.sort((a, b) => {
      //   if (a.name < b.name) {
      //     return -1;
      //   } else if (a.name > b.name) {
      //     return 1;
      //   } else {
      //     return 0;
      //   }
      // });
      this.tagGroups = state.tagGroups;
      this.accountFilters = state.accountFilters;
      this.addresses = state.addresses;
      // if (this.selectedTags.length === 0) {
      //   this.selectedTags = this.accountFilters.map(filter => filter.tagId);
      // }

      // fetch the Stripe Customer
      this.getStripeCustomer();
    });

    this.newsLetterIsDisabled = storageService.getItem(StorageEnum.NEWSLETTER_IS_DISABLED) === 'true';


    this.snackBarConfig.duration = 1200;
    this.snackBarConfig.horizontalPosition = 'right';
    this.snackBarConfig.panelClass = 'snackbarSaveSuccess';
  }

  ngOnInit() {
    const title = `Account Details | Grow Generation`;
    this.seoService.setTitle(title);
    this.seoService.setMetaTitle(title);
    this.seoService.setMetaDescription('Grow Generation Account Details');
    this.seoService.setMetaKeywords(`Content ${title}`);
  }

  ngOnDestroy() {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
  }

  // updateSource(stripeSource: StripeSource) {
  //   const dialogRef = this.dialog.open(UpdateSourceDialogComponent, {
  //     data: {...stripeSource},
  //     width: !this.smallScreen ? '50%' : '90%'
  //   });
  //
  //   dialogRef.afterClosed().subscribe(updatedStripeSource => {
  //     if (!updatedStripeSource) {
  //       return;
  //     }
  //   });
  // } // updateSource()


  /**
   * Open a dialog to Create a Stripe Source
   */
  // createSource() {
  //   const dialogRef = this.dialog.open(CreateSourceDialogComponent, {
  //     data: {},
  //     width: !this.smallScreen ? '50%' : '90%'
  //   });
  //
  //   dialogRef.afterClosed().subscribe(stripeCustomer => {
  //     if (!stripeCustomer) {
  //       return;
  //     }
  //
  //     this.store.dispatch(new UpdateStripeCustomer(stripeCustomer));
  //     if (!this.stripeCustomer) {
  //       this.stripeCustomer = new StripeCustomer();
  //     }
  //     this.stripeCustomer.sources = stripeCustomer.sources;
  //   });
  // } // createSource()


  detachSource(source: StripeSource) {
    // this.showPaymentMethodDeleteConfirmation().subscribe(confirmationResult => {
    //   if (confirmationResult) {
    //
    //     const stripeCustomerSourceInput: StripeCustomerSourceInput = {
    //       sourceId: source.id,
    //       customerId: this.loggedInUser.stripeId
    //     };
    //
    //     this.paymentService.detachSourceFromCustomer(stripeCustomerSourceInput)
    //       .subscribe(result => {
    //         if (!result.success) {
    //           return;
    //         }
    //
    //         const index = this.stripeCustomer.sources.data.findIndex(thisSource => thisSource.id === source.id);
    //         this.stripeCustomer.sources.data.splice(index, 1);
    //
    //         const stripeCustomer: StripeCustomer = result.result;
    //         // this.store.dispatch(new UpdateStripeCustomer(stripeCustomer));
    //
    //       }, error => {
    //         // @todo handle error
    //       });
    //
    //   }
    // });

  } // detachSource()


  get availableTags(): Tag[] {
    let tags: Tag[] = [];
    if (this.tags && this.tagGroups) {
      // get the group of the user preference tag groups
      const userPreference = this.tagGroups.find(tg => tg.section === 'USER_PREFERENCE');
      tags = this.tags.filter(tag => tag.tagGroupId === userPreference?.id);
    }
    return tags;
  }

  updateAccount() {
    const updateUser: Partial<User> = {
      id: this.loggedInUser.id,
      firstName: this.newFirstName ?? this.loggedInUser.firstName,
      lastName: this.newLastName ?? this.loggedInUser.lastName,
      email: this.newEmail ?? this.loggedInUser.email,
    }

    this.accountDetailsService.updateAccount(updateUser)
      .subscribe(response => {
        if (!response) {
          this.snackBarRef =
            this.snackBar.open('There has been an unexpected error', 'Close', this.snackBarConfig);
          return;
        }

        this.loggedInUser = response;

        this.store.dispatch(new UpdateAccountDetail(this.loggedInUser));
        this.snackBarRef =
          this.snackBar.open('Account detail update was successful', 'Close', this.snackBarConfig);
      }, error => {
        this.snackBarRef =
          this.snackBar.open('Account detail update was unsuccessful', 'Close', this.snackBarConfig);
      });
  }

  validateAccountDetails() {
    const doesFirstNameHaveErrors =
      this.firstNameFormControl.hasError('required') || this.firstNameFormControl.hasError('pattern');
    const doesLastNameHaveErrors =
      this.lastNameFormControl.hasError('required') || this.lastNameFormControl.hasError('pattern');
    const doesEmailHaveErrors =
      this.emailFormControl.hasError('required') || this.emailFormControl.hasError('pattern');
    const hasNotChanged =
      this.newFirstName.localeCompare(this.loggedInUser === undefined ? '' : this.loggedInUser.firstName) === 0 &&
      this.newLastName.localeCompare(this.loggedInUser === undefined ? '' : this.loggedInUser.lastName) === 0 &&
      this.newEmail.localeCompare(this.loggedInUser === undefined ? '' : this.loggedInUser.email) === 0;

    return !doesFirstNameHaveErrors && !doesLastNameHaveErrors && !doesEmailHaveErrors && !hasNotChanged;
  }

  isThisTagChecked(tag: Tag): boolean {
    return this.selectedTags.includes(tag.id);
  }

  updateSelectedTags(checked: boolean, tag: Tag) {
    if (checked) {
      this.createAccountFilter(tag);
    } else {
      this.deleteAccountFilter(tag);
    }
  }

  createAccountFilter(tag: Tag) {
    this.selectedTags.push(tag.id);
    this.accountFilterService.createFilter(tag);
  }

  deleteAccountFilter(tag: Tag) {
    const selectedIndex = this.selectedTags.indexOf(tag.id);
    this.selectedTags.splice(selectedIndex, 1);
    this.accountFilterService.deleteFilter(tag);
  }

  createAddress() {
    const dialogRef = this.dialog.open(EditAddressDialogComponent, {
      width: !this.smallScreen ? '65%' : '95%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.store.dispatch(new UpdateAddresses(result));
    });
  }

  editAddress(address: Address) {
    const dialogRef = this.dialog.open(EditAddressDialogComponent, {
      data: {...address},
      width: !this.smallScreen ? '65%' : '95%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.store.dispatch(new UpdateAddresses(result));
    });
  }

  removeAddress(address: Address) {

    this.showDeleteConfirmation().subscribe(confirmationResult => {
      if (confirmationResult) {
        this.addressService.delete(address)
          .subscribe(success => {
            const newAddresses = [...this.addresses];
            const index = newAddresses.findIndex(addr => addr.id === address.id);
            newAddresses.splice(index, 1);
            this.store.dispatch(new UpdateAddresses(newAddresses));
          },
          err => {
            console.error(err);
          });
      }
    });
  }

  protected showDeleteConfirmation() {
    const newDialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '30%',
      data:
        {
          title: 'Delete address',
          message: 'Are you sure you want to delete this address?'
        }
    });

    return newDialog.afterClosed();
  }

  protected showPaymentMethodDeleteConfirmation() {
    // const newDialog = this.dialog.open(DeletePaymentMethodDialogComponent, {width: '50%', height: '200px'});

    // return newDialog.afterClosed();
  }

  protected showAccountDeleteConfirmation() {
    const newDialog = this.dialog.open(ConfirmationDialogComponent, {width: '80%', data: {
        title: 'Delete Account?',
        message: 'Are you sure you want to permanently delete your account? This cannot be undone!'
      }});

    return newDialog.afterClosed();
  } // showDeleteConfirmation()

  checkPasswordLength(password: string) {
    let isValid;

    if (!password) {
      isValid = false;
    } else {
      isValid = password.length > 8;
    }

    return isValid;
  }

  containsNumber(password: string) {
    return /\d/.test(password);
  }

  containsOneUpperCase(password: string) {
    return /[A-Z]/.test(password);
  }

  checkIfPasswordIsInvalid(password: string) {
    return !password || password.length < 8 || !/\d/.test(password) || !/[A-Z]/.test(password);
  }

  checkForMatch() {
    if (this.newPassword !== this.confirmNewPassword) { // or some other test of the value
      this.newPasswordConfirmFormControl.setErrors({'doesNotMatch': true});
    } else {
      this.newPasswordConfirmFormControl.setErrors(null);
    }
  }

  changePassword() {
    this.accountDetailsService.changePassword(this.oldPassword, this.newPassword, this.confirmNewPassword)
      .subscribe(response => {

        this.passwordFormControl.reset();
        this.newPasswordFormControl.reset();
        this.newPasswordConfirmFormControl.reset();

        this.snackBarRef =
          this.snackBar.open('Your password has been updated', 'Close', this.snackBarConfig);
      }, error => {
        this.snackBarRef =
          this.snackBar.open('Your password is incorrect', 'Close', this.snackBarConfig);
      });
  }

  verifyChangePassword() {
    return !this.oldPassword
      || this.checkIfPasswordIsInvalid(this.newPassword)
      || this.checkIfPasswordIsInvalid(this.confirmNewPassword)
      || (this.newPassword !== this.confirmNewPassword);
  }

  /**
   *
   */
  getStripeCustomer() {
    // if (this.stripeCustomer != null || this.loggedInUser == null || this.loggedInUser.stripeId == null || this.loggedInUser.stripeId === '') {
    //   return;
    // }
    // this.paymentService.getStripeCustomer(this.loggedInUser)
    //   .subscribe(stripeCustomerResponse => {
    //     if (!stripeCustomerResponse.success) {
    //       return;
    //     }
    //
    //     this.stripeCustomer = stripeCustomerResponse.result;
    //
    //     this.store.dispatch(new UpdateStripeCustomer(stripeCustomerResponse.result));
    //
    //   }, error => {
    //     console.error(error.error);
    //   });
  } // getStripeCustomer()

  updateNewsLetter(subscribe:any) {

    // this.newsLetterService.updateSubscribe(subscribe)
    //   .subscribe(success => {
    //     if (!success.success) {
    //       return;
    //     }
    //     this.loggedInUser.gettingNewsLetters = subscribe;
    //   });
  }

  updateDataOptIn(isOptIn: boolean) {
    this.loggedInUser.dataOptIn = isOptIn;
    this.updateAccount();
  }

  deleteAccount(account: User) {
    this.showAccountDeleteConfirmation().subscribe(confirmationResult => {
      if (confirmationResult) {

        this.accountDetailsService.deleteAccount(account).subscribe(success => {
          if (!success) {
            return;
          }
        });

        this.loginService.signOut()
          .subscribe(success => {
            if (!success) {
              return;
            }

            this.store.dispatch(new LogoutUser());

            this.router.navigate(['/']);
          }, error => {
            console.error(error.error);
          });
      }

    });
  }
}
