import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ProductsService } from '../core/services/products.service';
import { Product } from '../core/models/product.model';
import { CartService } from '../core/services/cart.service';
import { FavoritesService } from '../core/services/favorites.service';
import { Store } from '@ngrx/store';
import { AppStore } from '../core/models/state.model';
import { FavoriteProduct } from '../core/models/favorite-product.model';
import { Tag } from '../core/models/tag.model';
import { TagGroup } from '../core/models/tag-group.model';
import { UpdateProduct } from '../core/actions/state.actions';
import { BreadCrumbEnum } from '../core/enums/bread-crumb.enum';
import { AddToCartEnum } from '../core/enums/add-to-cart.enum';
import { Image } from "../core/models/image.model";
import { SeoService } from '../core/services/seo.service';
import { Category } from '../core/models/category.model';
import {TagRelation} from "../core/models/tag-relation.model";
import {ProductReview} from "../core/models/product-review.model";

@Component({
	selector: 'app-product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
	productUrl: string;
	favoriteProducts: FavoriteProduct[] = [];
	tags: Tag[];
	products: Product[];
	tagGroups: TagGroup[];
	quantity: number = 1;
	breadCrumbType = BreadCrumbEnum.PRODUCT;
	addToCartStyle = AddToCartEnum.BUTTON;
	currentImage: Image = new Image();
	quantityDisabled: boolean = false;
	showStockIsDisabled: boolean = false;
	categories: Category[] = [];
	vehicleList: Tag[] = [];
  averageRating: number = 0;

	constructor(private route: ActivatedRoute,
		private router: Router,
		private cartService: CartService,
		private store: Store<AppStore>,
		private favoritesService: FavoritesService,
		private productsService: ProductsService,
		private seoService: SeoService) {
		store.subscribe(store => {
			const state = store.state;
			this.favoriteProducts = state.favoriteProducts;
			this.tags = state.tags;
			this.tagGroups = state.tagGroups;
			this.products = state.products;
			this.quantityDisabled = state.quantityIsDisabled;
			this.showStockIsDisabled = state.showStockIsDisabled;
			this.categories = state.categories;
		});
	}

	ngOnInit() {
		this.route.params.subscribe((params: Params) => {
			this.productUrl = params['id'];
			if (!this.productUrl) {
				this.notFound();
			} else {
				this.getProductById();
			}
		});
	}

	get product(): Product {
		let product = null;

		if (this.products && this.productUrl) {
			product = this.products
				.find(prod => prod.url === this.productUrl);
		}
		return product ? product : new Product();
	}

	get images(): Image[] {
    if (this.product && this.product.images) {
      return this.product.images.sort((a, b) => a.displaySeq - b.displaySeq);
    }

		return [];
	}

	get isFavorite(): boolean {
		if (this.favoriteProducts && this.product.itemNumber) {
			const index = this.favoriteProducts.findIndex(fp => fp.itemNumber === this.product.itemNumber);
			return index > -1;
		}
    return false
	}

	get displayCatchWeight(): boolean {
		if (this.product.catchWeight) {
			return this.product.catchWeight.toLocaleLowerCase() === 'c';
		}
    return false
	}

	get attributes(): Tag[] {
		if (this.tags && this.tagGroups && this.product.itemNumber && this.product.tagRelations && this.product.tagRelations.length > 0) {
			const attributeGroup = this.tagGroups.find(tg => tg.section === 'ATTRIBUTE');
			if (!attributeGroup) {
			  return [];
      }
			return this.tags.filter(tag => tag.tagGroupId === attributeGroup.id)
				.filter(tag => this.product.tagRelations ? this.product.tagRelations.findIndex(tr => tr.tagId === tag.id) > -1 : false);
		}
    return [];
	}

	get package(): string {
		let packageSize = null;
		let packageType = null;
		if (this.tags && this.tagGroups && this.product.itemNumber && this.product.tagRelations && this.product.tagRelations.length > 0) {
			const packageSizeGroup = this.tagGroups.find(tg => tg.section === 'PACKAGE_SIZE');
			if (packageSizeGroup) {
        packageSize = this.tags.filter(tag => tag.tagGroupId === packageSizeGroup.id)
          .find(tag => this.product.tagRelations ? this.product.tagRelations.findIndex(tr => tr.tagId === tag.id) > -1 : false);
      }

			const packageTypeGroup = this.tagGroups.find(tg => tg.section === 'PACKAGE_TYPE');
			if (packageTypeGroup) {
        packageType = this.tags.filter(tag => tag.tagGroupId === packageTypeGroup.id)
          .find(tag => this.product.tagRelations ? this.product.tagRelations.findIndex(tr => tr.tagId === tag.id) > -1 : false);
      }
		}
		return `${packageSize ? packageSize.name : ''} ${packageType ? packageType.name : ''}`;
	}


	notFound() {
		this.router.navigateByUrl('/not-found', { skipLocationChange: true });
	}

  calculateAverageRating(reviews: ProductReview[]) {
    const ratings: number[] = [];
    for( let review of reviews ) {
      ratings.push(+review.rating!)
    }
    const average = ratings.reduce((acc, curr) => acc + curr, 0) / ratings.length;
    this.averageRating = Math.round(average);
  }

	getProductById() {
		this.route.data.subscribe(resolve => {
			const response = resolve['resolve'];

			if (!response) {
				this.notFound();
        return
			}

      this.calculateAverageRating(response.reviews);

			const category = this.categories.filter(c => c.productCategories).find(c => c.productCategories.findIndex(pc => pc.itemNumber === response.itemNumber) > -1);

			const tags = this.tags.filter(t => response?.tagRelations?.findIndex((tr: TagRelation) => tr.tagId === t.id) > -1).map(t => t.name);
			const title = `${response.displayName} (${response.itemNumber}) ${category ? ' - ' + category.name : ''} | Grow Generation`;
			const description = `${tags.join(' ')} - ${response.itemNumber} - ${response.displayName} - ${response.longDescription ? response.longDescription.replace( /(<([^>]+)>)/ig, '') : response.shortDescription}`;
			this.seoService.setTitle(title);
			this.seoService.setMetaTitle(title);
			this.seoService.setMetaDescription(description);
			const keywords = `${response.displayName},Humvee ${response.displayName},HMMWV ${response.displayName},${response.itemNumber},${category ? category.name + ', ' : ''}HMMWV,Humvee,Grow Generation` +
                this.tags.filter(t => response?.tagRelations?.findIndex((tr: TagRelation) => tr.tagId === t.id) > -1).map((t) =>
                {
                    this.vehicleList.push(t);
                    let keyword = t.description?.replace(/<p>Includes:?<\/p>/g, '') ?? '';
                    keyword = keyword.replace(/<p>/g, ',');
                    keyword = keyword.replace(/<\/p>/g, ' ' + response.displayName.toLowerCase());
                    return keyword;
                });
			this.seoService.setMetaKeywords(keywords);

			this.store.dispatch(new UpdateProduct(response));
		}, error => {
			if (error.status === 404) {
				this.notFound();
			} else {
				console.error(error);
			}
		});
	}

	addToFavorites() {
		this.favoritesService.addFavoriteProduct(this.product.itemNumber);
	}

	deleteFromFavorites() {
		this.favoritesService.deleteFavoriteProduct(this.product.itemNumber);
	}

	updateCurrentImage(newImage: Image) {
		this.currentImage = newImage;
	}

	subtractOne() {
		const currentQuantity = this.quantity;
		if (currentQuantity - 1 <= 0) {
			return;
		}

		this.quantity = currentQuantity - 1;
	}

	addOne() {
		const maxQuantity = this.product.productAvailability.availableQuantity;
		const currentQuantity = this.quantity;
		if (currentQuantity + 1 > maxQuantity && !this.quantityDisabled) {
			return;
		}

		this.quantity = currentQuantity + 1;
	}

}
