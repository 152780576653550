import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Category} from "../models/category.model";

@Injectable()
export class CategoryService {

  readonly baseUrl = `${environment.url}/category`;

  constructor(private http: HttpClient) { }

  getCategories(): Observable<{success: boolean, result: Category[]}> {
    return this.http.get<{success: boolean, result: Category[]}>(this.baseUrl);
  }

  getCategory(url: string): Observable<Category> {
    return this.http.get<Category>(`${this.baseUrl}/${url}`);
  }
}
