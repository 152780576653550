import { Component, Input } from '@angular/core';
import { State }            from '@ngrx/store';
import { Router }           from '@angular/router';
import {Category} from '../../core/models/category.model';
import {AppStore} from '../../core/models/state.model';
import {Image} from '../../core/models/image.model';

@Component({
    selector   : 'app-category-card',
    templateUrl: './category-card.component.html',
    styleUrls  : ['./category-card.component.scss']
})
export class CategoryCardComponent {

    @Input() category: Category;
    @Input() smallImage: boolean;
             categories: Category[];
             imageNotFound: boolean = false;

    constructor(private router: Router,
        private store: State<AppStore>) {
        store.subscribe(store => {
            const state     = store.state;
            this.categories = state.categories;
        });
    }

    get images(): Image[] {
      return this.category ? [this.category.image] : [];
    }

    imageError() {
        this.imageNotFound = true;
    }

}
