import { Injectable } from '@angular/core';
import { Resolve} from '@angular/router';
import {SitemapService} from '../services/sitemap.service';

@Injectable()
export class SitemapResolver implements Resolve<any> {
  constructor(private sitemapService: SitemapService) {}

  resolve() {
    return this.sitemapService.getSitemap();
  }
}
