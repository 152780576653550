import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppStore } from '../../core/models/state.model';
import { OrderService } from '../../core/services/order.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ConfirmedOrder, OrderDetail } from '../../core/models/confirmed-order.model';
import { UpdateShippingAddress } from '../../core/actions/state.actions';
import { isPlatformBrowser } from '@angular/common';
import {Address} from "../../core/models/address.model";

@Component({
    selector: 'app-payment-complete',
    templateUrl: './payment-complete.component.html',
    styleUrls: ['./payment-complete.component.scss']
})

export class PaymentCompleteComponent implements OnInit {

    orderList: ConfirmedOrder[] = [];
    snackBarConfig = new MatSnackBarConfig();

    constructor(private store: Store<AppStore>,
        private orderService: OrderService,
        @Inject(PLATFORM_ID) private platformId: any,
        private snackBar: MatSnackBar) {
    }

    ngOnInit() {
        this.getConfirmedOrder();

        if (isPlatformBrowser(this.platformId)) {
            // ga('set', 'page', 'checkout-completed');
            // ga('send', 'pageview');
            // fbq('track', 'ViewContent');
        }

        this.snackBarConfig.horizontalPosition = 'center';
        this.snackBarConfig.verticalPosition = 'top';
        this.snackBarConfig.panelClass = 'snackBar';

    } // ngOnInit()


    /**
     * get the confirmed order
     */
    getConfirmedOrder() {
        this.orderService.getConfirmOrders()
            .subscribe(response => {
                if (!response) {
                    // this.snackBar.open('Unknown Error has occurred ', 'Close', this.snackBarConfig);
                    return;
                }
                // get the total of the orders
                // const value = response.map(r => r.orderHeader.orderTotal).reduce((total, num) => total + num);
                // const tax = response.map(r => r.orderHeader.taxTotal).reduce((total, num) => total + num);
                // const shipping = response.map(r => r.orderHeader.freight).reduce((total, num) => total + num);
                // const id = response.map(r => r.orderHeader.orderId).join('-');
                // const items = response.map(r => r.orderDetailList)
                //     .reduce((array, v) => array.concat(v), [])
                //     .map(((r: OrderDetail, i: number) => {
                //         return {
                //             "id": r.itemNumber,
                //             "name": r.itemDescription,
                //             "list_position": i,
                //             "quantity": r.quantity,
                //             "price": r.price
                //         };
                //     }));

                // fbq('track', 'Purchase', { value, currency: 'USD' });
                // gtag('event', 'purchase', {
                //     transaction_id: id,
                //     affiliation: 'AMG E-Commerce Store',
                //     value,
                //     currency: 'USD',
                //     tax,
                //     shipping,
                //     items
                // });

                this.store.dispatch(new UpdateShippingAddress(new Address()));
                this.orderList = [response];
            }, error => {
                // this.snackBar.open(error.error, 'Close', this.snackBarConfig);
            });
    } // getConfirmedOrder()

}
