<div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
  <label>{{data.title}}</label>
</div>

<div class="dialog-body" fxLayout="column" fxLayoutGap="16px">
  <div fxLayout="column">
    <span>{{data.message}}</span>
  </div>

  <div fxLayout="row" fxFlexAlign="end" fxLayoutGap="8px">
    <button mat-button class="blue-button" (click)="close()">Ok</button>
  </div>
</div>
