import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {StorageEnum} from "../enums/storage.enum";
import {isPlatformBrowser} from "@angular/common";

@Injectable()
export class StorageService {

  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  setItem(key: StorageEnum, value: string) {
    if (this.isBrowser) {
      localStorage.setItem(key, value);
    }
  }

  getItem(key: StorageEnum): string | null {
    if (this.isBrowser) {
      return localStorage.getItem(key);
    }
    return null;
  }

  removeItem(key: StorageEnum) {
    if (this.isBrowser) {
      localStorage.removeItem(key);
    }
  }
}
