import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {CatalogSearchService} from "../services/catalog-search.service";

@Injectable()
export class SearchResultsResolver implements Resolve<any> {
	constructor(private catalogSearchService: CatalogSearchService) {}

	resolve(route: ActivatedRouteSnapshot) {
		const url = route.params['id'];
		return this.catalogSearchService.performSearch(url);
	}
}
