import {Component, Inject, Input} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface ConfirmationDialogData {
	title: string;
	message: string;
}

@Component({
	selector: 'app-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
	notificationMode: boolean;
  yesNoMode: boolean = false;

	constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) {
		if (!data.title) {
			data.title = 'Confirm';
		}
		if (!data.message) {
			data.message = 'Are you sure?';
		}
	}
}
