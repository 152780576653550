<a class="gallery-container flex flex-col gap-1 md:mobile-view relative" [routerLink]="'/product/' + product.url">
	<div class="product-grid-image">
		<!--    <img [appImageLazyLoad]="primaryImage.thumb100" [alt]="primaryImage.alt"-->
		<img [src]="primaryImage.url" [alt]="primaryImage.alt" class="center-horizontal clickable" *ngIf="!imageNotFound" (error)="imageError()" />
		<img
			class="center-horizontal product-image-small clickable"
			aria-hidden="true"
			*ngIf="imageNotFound"
			alt="Not Found"
			src="./assets/image-not-found.500.jpg"
		/>
		<!-- <span class="product-weight clickable" fxFlex="12px">{{package}}</span> -->
	</div>
	<div class="flex flex-wrap justify-between md:mobile-product-info cart-favorite">
		<label class="product-name clickable">{{ product.displayName }}</label>
		<div class="price-cart flex flex-col justify-end">
			<span class="product-price clickable self-end">
				{{ product.productPrice.price | currency : 'USD' : 'symbol' : '0.2-2' }}
			</span>
			<app-add-to-cart
				class="self-end"
				[style]="addToCartStyle"
				[product]="product"
				[isDisabled]="disableAddToCart()"
			></app-add-to-cart>
		</div>
	</div>
</a>
