import { Injectable } from '@angular/core';
import {Resolve, Router} from '@angular/router';
import {OrderService} from '../services/order.service';
import {ObservableInput, of} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable()
export class OrderHistoryResolver implements Resolve<any> {
	constructor(private orderService: OrderService,private router: Router) {}

	resolve() {
		return this.orderService.getHistory().pipe(
      catchError(err => this.handleError(err))
    );
	}

  handleError(err: any): ObservableInput<null> {
    console.log(err);
    this.notFound();
    return of(err)
  }

  notFound() {
    this.router.navigateByUrl('/not-found', {skipLocationChange: true});
  }
}
