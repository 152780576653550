import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {AutoFocusDirective} from './directives/auto-focus.directive';
import {IsDecimalDirective} from './directives/is-decimal-directive.directive';
import {UserCardComponent} from './components/user-card/user-card.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ConfirmationDialogComponent} from './components/confirmation-dialog/confirmation-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ValidationErrorsComponent} from './components/validation-errors/validation-errors.component';
import {CommaRemovalPipe} from './pipes/comma-removal.pipe';
import {SortPipe} from './pipes/sort.pipe';
import {StatePipe} from './pipes/state.pipe';
import {PhonePipe} from './pipes/phone.pipe';
import {ArchivePipe} from './pipes/archive.pipe';
import {UnderscoreRemove} from './pipes/underscore-remove.pipe';
import {CodeConfirmationDialogComponent} from './components/code-confirmation-dialog/code-confirmation-dialog.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {WhitespacePipe} from './pipes/whitespace.pipe';
import { AddToCartComponent } from './components/add-to-cart/add-to-cart.component';
import { ImageLazyLoadDirective } from './directives/image-lazy-load.directive';
import {SafeHtmlPipe} from "./pipes/safe-html.pipe";
import {
  MobileCategoryFilterDialogComponent
} from "./components/mobile-category-filter-dialog/mobile-category-filter.dialog.component";
import {MultiImageComponent} from './components/multi-image/multi-image.component';
import { MobileViewDirective } from './directives/mobile-view.directive';
import { EditAddressDialogComponent } from './components/edit-address-dialog/edit-address-dialog.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import {CheckEmailComponent} from "./components/check-email/check-email.component";
import { StarRatingComponent } from './components/star-rating/star-rating.component';


@NgModule({
	declarations: [
		AutoFocusDirective,
		IsDecimalDirective,
		UserCardComponent,
		ConfirmationDialogComponent,
    MobileCategoryFilterDialogComponent,
		ValidationErrorsComponent,
    MultiImageComponent,
		CommaRemovalPipe,
		SortPipe,
		StatePipe,
		ArchivePipe,
		PhonePipe,
		UnderscoreRemove,
		CodeConfirmationDialogComponent,
    SafeHtmlPipe,
		WhitespacePipe,
    AddToCartComponent,
    ImageLazyLoadDirective,
    MobileViewDirective,
    EditAddressDialogComponent,
    ErrorDialogComponent,
    MessageDialogComponent,
    CheckEmailComponent,
    StarRatingComponent
	],
    exports: [
        AutoFocusDirective,
        IsDecimalDirective,
        ImageLazyLoadDirective,
        UserCardComponent,
        ValidationErrorsComponent,
        MultiImageComponent,
        StatePipe,
        PhonePipe,
        SortPipe,
        ArchivePipe,
        SafeHtmlPipe,
        UnderscoreRemove,
        WhitespacePipe,
        AddToCartComponent,
        MobileViewDirective,
        ConfirmationDialogComponent,
        StarRatingComponent
    ],
	imports: [
		CommonModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		ReactiveFormsModule,
		MatProgressSpinnerModule,
		MatIconModule,
		MatButtonModule,
		MatAutocompleteModule,
		MatChipsModule,
		MatCheckboxModule,
		MatCardModule,
		MatTooltipModule,
		MatDialogModule,
		FormsModule,
		MatDatepickerModule
	],
	providers: [CommaRemovalPipe, SortPipe, UnderscoreRemove, SafeHtmlPipe]
})
export class SharedModule {}
