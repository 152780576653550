import {Image} from "./image.model";
import {Tag} from "./tag.model";

export class ShoppingCart {
    count: number = 0;
    subtotal: number = 0.00;
    items: ShoppingCartItem[] = [];
}

export class ShoppingCartItem {
    id: number;
    itemNumber: string;
    url: string;
    displayName: string;
    shortDescription: string;
    perishable: boolean;
    quantity: number;
    price: number;
    productPrice: number;
    discountPrice: number;
    discountPercent: number;
    promoCode: string;
    availableQuantity: number;
    weight: number;
    weightUom: string;
    tags: Tag[];
    image: Image;
    // tags: { id: number; }[];
    // image: { title: string; alt: string; url: string; thumb100: string };
}
