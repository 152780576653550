<div id="login-container" class="flex flex-col">
  <div class="flex flex-col md:flex-row gap-8 justify-between">
    <div id="sign-in-container">
      <label class="header-title">Member Login</label>
      <div class="login-input flex flex-col" style="padding-top: 3.5%;">
        <mat-form-field class="login-input-box">
          <input matInput [(ngModel)]="user.email" type="email" [formControl]="userEmailFormControl" placeholder="Email"/>
          <mat-error *ngIf="userEmailFormControl.hasError('email') && !userEmailFormControl.hasError('required')">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="userEmailFormControl.hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="login-input flex flex-col">
        <mat-form-field class="login-input-box">
          <input matInput (keydown.enter)="onKeydownSignIn($event)" [(ngModel)]="user.password" type="password"
                 [formControl]="userPasswordFormControl" placeholder="Password"/>
          <mat-error *ngIf="userPasswordFormControl.hasError('required')">
            Password is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="my-2 flex flex-col md:flex-row justify-between items-center">
        <div>
          <mat-checkbox id="signed-in-checkbox" >
<!--            [(ngModel)]="user.staySignedIn"-->

            Keep me signed in
          </mat-checkbox>
        </div>
        <div id="forgot-password-container">
          <span id="forgot-password" (click)="forgotPassword()">I forgot my password</span>
        </div>
      </div>
      <button mat-flat-button color="primary" class=" sign-in-button" (click)="signIn()" [disabled]="!user.email || !user.password">Login</button>
      <span *ngIf="incorrectEmail" style="color: #f44336; align-self: center; font-size: 14px;">
				You have entered an incorrect email/password.
			</span>
      <span *ngIf="!isVerified" style="color: #f44336; align-self: center; font-size: 14px;">
				This account has not yet been verified, Please check your spam folder for a verification email.
			</span>
    </div>

    <div id="login-divider" *ngIf="!signUpDisabled"></div>

    <div id="sign-up-container" *ngIf="!signUpDisabled">
      <label class="header-title">Sign Up</label>
      <div class="login-input flex flex-col">
        <span class="input-title">First Name</span>
        <mat-form-field class="login-input-box">
          <input matInput [(ngModel)]="newUser.firstName" type="text" [formControl]="newFirstNameFormControl" />
          <mat-error *ngIf="newFirstNameFormControl.hasError('required')">
            First Name is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="login-input flex flex-col">
        <span class="input-title">Last Name</span>
        <mat-form-field class="login-input-box">
          <input matInput [(ngModel)]="newUser.lastName" type="text" [formControl]="newLastNameFormControl" />
          <mat-error *ngIf="newLastNameFormControl.hasError('required')">
            Last Name is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="login-input flex flex-col">
        <span class="input-title">Email</span>
        <mat-form-field class="login-input-box">
          <input matInput [(ngModel)]="newUser.email" type="text" [formControl]="newEmailFormControl" />
          <mat-error *ngIf="newEmailFormControl.hasError('email') && !newEmailFormControl.hasError('required')">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="newEmailFormControl.hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="login-input flex flex-col">
        <span class="input-title">Password</span>
        <mat-form-field class="login-input-box">
          <input matInput (keydown.enter)="onKeydownSignUp($event)" [(ngModel)]="newUser.password" (click)="tickPasswordForm()"
                 type="password" [formControl]="newPasswordFormControl" />
          <mat-error *ngIf="!newUser.password">
            Password is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="!checkPasswordLength()">
            Password must be at least <strong>8</strong> characters
          </mat-error>
          <mat-error *ngIf="!containsNumber()">
            Password must contain at least <strong>1</strong> number
          </mat-error>
          <mat-error *ngIf="!containsOneUpperCase()">
            Password must contain at least <strong>1</strong> uppercase character
          </mat-error>
        </mat-form-field>
      </div>
      <button mat-flat-button color="primary" *ngIf="!creatingAccount" class="blue-button sign-up-button" (click)="signUp()" [disabled]="checkIfNewUserIsInvalid()">
        Create Account
      </button>
      <mat-progress-spinner *ngIf="creatingAccount" mode="indeterminate" color="primary" class="progress-spinner"></mat-progress-spinner>
      <span *ngIf="!creatingAccount" style="color: $primaryThemeColor; align-self: center; font-size: 14px;">* All fields are required.</span>
    </div>
  </div>
</div>
