import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {ObservableInput, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {OrderService} from '../services/order.service';
import { AppStore } from '../models/state.model';
import { Account } from '../models/account.model';
import { Store } from '@ngrx/store';
import {User} from "../models/user.interface";

@Injectable()
export class CheckoutResolver implements Resolve<any> {
	loggedInUser: User;
	constructor(private orderService: OrderService, private router: Router, private store: Store<AppStore>) {
		store.subscribe((_store) => {
			const state = _store.state;
			this.loggedInUser = state.user!;
		})
	}

	resolve(route: ActivatedRouteSnapshot) {
		const code = route.queryParams['code'];
		if (!code) {
		  console.log('no code');
			return;
		}

		// there is a code but there isn't a user logged in lets force them to login then come back here
    if (!this.loggedInUser) {
      const routeSnapshot = route as ActivatedRouteSnapshot;
      const url = routeSnapshot.url;
      this.router.navigate(['/login'], { queryParams: { returnUrl: url }, skipLocationChange: true });
      return;
    }


		return this.orderService.verify(code).pipe(
			catchError(err => this.handleError(err)));
	}

	handleError(err: any): ObservableInput<null> {
		console.log(err);

		return of(err)
	}
}
