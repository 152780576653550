import {Component, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Address} from '../../../core/models/address.model';
import {FedexService} from '../../../core/services/fedex.service';
import {AddressValidation} from '../../../core/models/address-validation.model';


@Component({
	selector: 'address-validation-dialog',
	templateUrl: './address-validation.dialog.component.html',
	styleUrls: ['./address-validation.dialog.component.scss']
})

export class AddressValidationDialogComponent implements OnInit {

	suggestion: Address | null;
	preValidation: Address;

	loading: boolean | null = null;
	checked: boolean | null = null;
	validated: boolean | null = null;

	constructor(private fedexService: FedexService,
				private dialog: MatDialogRef<AddressValidationDialogComponent>) {
	}

	ngOnInit() {
		this.validateAddress();
	}

	validateAddress() {
		this.loading = true;
		this.fedexService.validateAddress(this.preValidation)
			.subscribe(
				(validateAddressResult: AddressValidation) => {
					this.loading = false;
					if (validateAddressResult) {
						this.suggestion = validateAddressResult.suggestion;
						this.checked = validateAddressResult.checked;
						this.validated = validateAddressResult.validated;
					} else {
						this.suggestion = null;
						this.checked = null;
						this.validated = null;
					}
				});
	}

	close(suggestion: Address | undefined = undefined) {
		this.dialog.close(suggestion);
	}
}
