import {NgModule} from '@angular/core';
import {CommonModule, TitleCasePipe} from '@angular/common';
import {CreateUserComponent} from './users/create-user/create-user.component';
import {UsersListComponent} from './users/users-list/users-list.component';
import {UsersListDetailComponent} from './users/users-list-detail/users-list-detail.component';
import {RouterModule} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {SharedModule} from '../shared/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {RolesListComponent} from './roles/roles-list/roles-list.component';
import {RolesListDetailComponent} from './roles/roles-list-detail/roles-list-detail.component';
import {CreateRoleComponent} from './roles/create-role/create-role.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CodesListComponent} from './codes/codes-list/codes-list.component';
import {CodesDialogComponent} from './codes/codes-dialog/codes-dialog.component';

@NgModule({
	declarations: [
		CreateUserComponent,
		UsersListComponent,
		UsersListDetailComponent,
		RolesListComponent,
		RolesListDetailComponent,
		CreateRoleComponent,
		CodesListComponent,
		CodesDialogComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		MatProgressSpinnerModule,
		MatDatepickerModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatTableModule,
		MatPaginatorModule,
		MatSnackBarModule,
		MatSortModule,
		SharedModule,
		MatRadioModule,
		MatDialogModule,
		MatSelectModule,
		MatCardModule,
		MatAutocompleteModule,
		MatSlideToggleModule,
		MatDividerModule,
		MatCheckboxModule
	],
	exports: [
		CreateUserComponent,
		UsersListComponent,
		UsersListDetailComponent
	],
  providers: [
    TitleCasePipe
  ]
})
export class MaintenanceModule {}
