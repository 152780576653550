import {ImageTypeEnum} from "../enums/image-type.enum"

export class Image {
  id:number;
  type: ImageTypeEnum;
  relatedId: string;
  filename: string;
  isPrimary: boolean;
  title: string;
  alt: string;
  video: boolean;
  displaySeq: number;
  url: string;
  thumb100: string;
}
