<a class="list-container clickable flex justify-between gap-1 md:gap-2"
   [routerLink]="'/product/' + item.url" *ngIf="!isDeleted" appMobileView>
<!--  <div class="remove-from-cart-icon" aria-hidden="true" (click)="delete($event)">X</div>-->
  <button class="remove-from-cart-icon" aria-hidden="true" (click)="delete($event)" mat-mini-fab color="primary"><mat-icon>close</mat-icon></button>
  <div class="product-information flex flex-col justify-start md:justify-center w-3/5 md:w-2/5" appMobileView>
    <div class="product-list-image md:hidden">
      <img *ngIf="item && item.image && item.image.url && !imageNotFound" [src]="item.image.url"
           [alt]="item.image.alt" (error)="imageError()" class="product-image-small center-vertical clickable"/>
      <img *ngIf="!item.image || !item.image.url || imageNotFound" alt="Not found"
           src="../../../../assets/image-not-found.500.jpg" class="product-image-small clickable"/>
    </div>
    <label class="product-name clickable">{{item.displayName}}</label>
    <span class="product-cart-price clickable" *ngIf="item.itemNumber">ITEM #{{item.itemNumber}}</span>
    <span class="product-cart-price clickable" *ngIf="item.shortDescription">NSN {{item.shortDescription}}</span>
    <span class="product-cart-price clickable" *ngIf="item.weight">WEIGHT {{item.weight}} Pound</span>
    <!--span class="product-cart-price clickable">Price: <span [ngClass]="{'product-price-strike-trough': showDiscountPrice}">{{item.price | currency:'USD':'symbol':'0.2-2'}}</span><span class="product-discount-price" *ngIf="showDiscountPrice">{{item.discountPrice | currency:'USD':'symbol':'0.2-2'}}</span></span -->
    <span class="product-instock clickable" *ngIf="!item.availableQuantity || item.availableQuantity < 1">Item is not currently in inventory.  Orders will ship when available and invoiced at that time.</span>
    <span class="product-instock clickable"
          *ngIf="item.availableQuantity && item.availableQuantity > 0 && item.availableQuantity >= item.quantity">In Stock</span>
    <span class="product-instock clickable"
          *ngIf="item.availableQuantity && 0 < item.availableQuantity && item.availableQuantity < item.quantity">The quantity requested is not available.  We will ship available stock and complete the order when available.</span>
  </div>
  <div class="w-full md:w-1/3 hidden md:flex justify-center">
    <div class="product-list-image">
      <img *ngIf="item.image && item.image.url && !imageNotFound" [src]="item.image.url"
           [alt]="item.image.alt" (error)="imageError()" class="product-image-small center-vertical clickable"/>
      <img *ngIf="!item.image || !item.image.url || imageNotFound" alt="Not found"
           src="../../../../assets/image-not-found.500.jpg" class="product-image-small clickable"/>
    </div>
  </div>
  <div class="flex-col md:flex justify-end items-center md:justify-center w-1/3">
    <span class="qty-control-wrapper flex" (click)="preventDefault($event)">
      <div class="qty-control do-not-go flex" appMobileView>
        <a class="quantity-icon left" (click)="subtractOne($event)">
          -
        </a>
        <div class="blue-border"></div>
        <label class="product-quantity">{{item.quantity}}</label>
        <div class="blue-border"></div>
        <a class="quantity-icon right" (click)="addOne($event)">
          +
        </a>
      </div>
    </span>
    <span class="flex flex-col justify-end md:justify-end" appMobileView>
        <span [ngClass]="{'product-price-strike-trough': showDiscountPrice}" class="product-price clickable">
          {{item.price * item.quantity | currency:'USD':'symbol':'0.2-2'}}
        </span>
        <label *ngIf="showDiscountPrice" class="product-price clickable" appMobileView>
            {{item.discountPrice * item.quantity | currency: 'USD':'symbol':'0.2-2'}}
        </label>
        </span>
  </div>
</a>

