<div class="dialog-body flex flex-col p-2">
  <div mat-dialog-title>
    <label>FILTERS</label>
  </div>

  <div class="filters" mat-dialog-content>
    <div class="filter-group flex flex-col" *ngFor="let filterGroup of data.filterGroups">
      <label class="filter-group-title">{{filterGroup.title}}</label>
      <mat-checkbox *ngFor="let filter of filterGroup.tags" class="checkbox" [checked]="isFilterSelected(filter)" (change)="filterIsSelected(filter)">{{filter.name}}</mat-checkbox>
    </div>
  </div>

  <div mat-dialog-actions align="end" >
    <button mat-flat-button (click)="close()">Cancel</button>
    <button mat-raised-button color="primary" (click)="submit()">Apply</button>
  </div>
</div>
