import { Injectable } from '@angular/core';
import { Resolve} from '@angular/router';
import {FavoritesService} from "../services/favorites.service";

@Injectable()
export class FavoritesResolver implements Resolve<any> {
	constructor(private favoritesService: FavoritesService) {}

	resolve() {
		return this.favoritesService.getFavoriteProducts();
	}
}