import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ShoppingCartItem} from "../../../core/models/shopping-cart.model";
import {Router} from "@angular/router";
import {Tag} from "../../../core/models/tag.model";
import {TagGroup} from "../../../core/models/tag-group.model";
import {Store} from "@ngrx/store";
import {AppStore} from "../../../core/models/state.model";

@Component({
  selector: 'app-product-card-cart',
  templateUrl: './product-card-cart.component.html',
  styleUrls: ['./product-card-cart.component.scss']
})
export class ProductCardCartComponent implements OnInit {

  @Input() item: ShoppingCartItem = {} as ShoppingCartItem;
  @Output() updateShoppingCartItem: EventEmitter<ShoppingCartItem> = new EventEmitter<ShoppingCartItem>();
  @Output() deleteShoppingCartItem: EventEmitter<ShoppingCartItem> = new EventEmitter<ShoppingCartItem>();

  imageNotFound: boolean = false;
  tags: Tag[];
  tagGroups: TagGroup[];
  isDeleted: boolean = false;
  quantityDisabled: boolean = false;


  constructor(private router: Router,
              private store: Store<AppStore>) {
    store.subscribe(store => {
      const state = store.state;
      this.tags = state.tags;
      this.tagGroups = state.tagGroups;
      this.quantityDisabled = state.quantityIsDisabled;
    });
  }

  ngOnInit() {

  }

  get package(): string {
    let packageSize = null;
    let packageType = null;
    if (this.tags && this.tagGroups && this.item && this.item.itemNumber && this.item.tags && this.item.tags.length > 0) {
      const packageSizeGroup = this.tagGroups.find(tg => tg.section === 'PACKAGE_SIZE');
      packageSize = this.tags.filter(tag => tag.tagGroupId === packageSizeGroup!.id)
        .find(tag => this.item.tags ? this.item.tags.findIndex(tr => tr.id === tag.id) > -1 : false);

      const packageTypeGroup = this.tagGroups.find(tg => tg.section === 'PACKAGE_TYPE');
      packageType = this.tags.filter(tag => tag.tagGroupId === packageTypeGroup!.id)
        .find((tag: Tag) => this.item.tags ? this.item.tags.findIndex((tr: Tag) => tr.id === tag.id) > -1 : false);
    }
    return `${packageSize ? packageSize.name : ''} ${packageType ? packageType.name : ''}`;
  }

  get showDiscountPrice(): boolean {
    return !!(this.item && (this.item.discountPrice || this.item.discountPercent));
  }

  preventDefault(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  imageError() {
    this.imageNotFound = true;

    console.log(this.imageNotFound)
  }

  update(updatedItem: ShoppingCartItem) {
    this.updateShoppingCartItem.emit(updatedItem);
  }

  delete(event: Event) {
    this.preventDefault(event);
    this.isDeleted = true;
    this.deleteShoppingCartItem.emit(this.item);
  }

  subtractOne(event: Event) {
    this.preventDefault(event);
    const currentQuantity = this.item.quantity;
    if (currentQuantity - 1 <= 0) {
      return;
    }

    this.update({
      ...this.item,
      quantity: currentQuantity - 1
    });
  }

  addOne(event: Event) {
    this.preventDefault(event);
    const maxQuantity = this.item.availableQuantity;
    const currentQuantity = this.item.quantity;
    if (currentQuantity + 1 > maxQuantity && !this.quantityDisabled) {
      return;
    }

    this.update({
      ...this.item,
      quantity: currentQuantity + 1
    });
  }
}
