import {Action} from '@ngrx/store';
import {State} from '../models/state.model';
import {Category} from '../models/category.model';
import {Account} from '../models/account.model';
//import {StripeCustomer} from '../models/stripe.model';
import {TagGroup} from '../models/tag-group.model';
import {FavoriteProduct} from '../models/favorite-product.model';
import {Tag} from '../models/tag.model';
import {Product} from '../models/product.model';
import {Feature} from '../models/feature.model';
import {ContentPage} from '../models/content-page.model';
import {ShoppingCartItem} from '../models/shopping-cart.model';
import {AccountFilter} from '../models/account-filter.model';
import {Address} from '../models/address.model';
import {CategoryViewEnum} from '../enums/category-view.enum';
import {User} from "../models/user.interface";
import {LoginResponse} from "../models/login-response.interface";

// define the types
export const LOAD_INITIAL_STATE = '[STATE] LOAD';
export const USER_LOGIN = '[USER] LOGIN';
export const USER_LOGOUT = '[USER] LOGOUT';
export const CATEGORIES_UPDATE = '[CATEGORIES] UPDATE';
export const CATEGORY_UPDATE = '[CATEGORY] UPDATE';
export const CART_ITEM_UPDATE = '[CART ITEM] UPDATE';
export const CART_ITEMS_UPDATE = '[CART ITEMS] UPDATE';
export const FAVORITE_PRODUCTS_UPDATE = '[FAVORITE PRODUCTS] UPDATE';
export const TAG_GROUPS_UPDATE = '[TAG GROUPS] UPDATE';
export const TAGS_UPDATE = '[TAG] UPDATE';
export const PRODUCTS_UPDATE = '[PRODUCTS] UPDATE';
export const PRODUCT_UPDATE = '[PRODUCT] UPDATE';
//export const STRIPE_CUSTOMER_UPDATE = '[STRIPE CUSTOMER] UPDATE';
export const FEATURED_ITEMS_UPDATE = '[FEATURED ITEMS] UPDATE';
export const ACCOUNT_FILTERS_UPDATE = '[ACCOUNT FILTERS] UPDATE';
export const CONTENT_PAGES_UPDATE = '[CONTENT PAGES] UPDATE';
export const ADDRESSES_UPDATE = '[ADDRESSES] UPDATE';
export const SHIPPING_ADDRESS_UPDATE = '[SHIPPING_ADDRESS] UPDATE';
export const SHIPPING_ADDRESS_SAME_AS_BILLING_UPDATE = '[SHIPPING_ADDRESS_SAME_AS_BILLING] UPDATE';
export const CATEGORY_VIEW_UPDATE = '[CATEGORY VIEW] UPDATE';
export const ACCOUNT_DETAIL_UPDATE = '[ACCOUNT_DETAIL] UPDATE';

// define actions
export class LoadInitialState implements Action {
	readonly type = LOAD_INITIAL_STATE;

	constructor(public payload: State) {}
}

export class LoginUser implements Action {
	readonly type = USER_LOGIN;

	constructor(
		public payload: LoginResponse
	) {}
}

export class LogoutUser implements Action {
	readonly type = USER_LOGOUT;

	constructor() {}
}

/*export class UpdateStripeCustomer implements Action {
  readonly type = STRIPE_CUSTOMER_UPDATE;

  constructor(public payload: StripeCustomer) {
  }
}*/

export class UpdateCategories implements Action {
	readonly type = CATEGORIES_UPDATE;

	constructor(public payload: Category[]) {}
}

export class UpdateCategory implements Action {
	readonly type = CATEGORY_UPDATE;

	constructor(public payload: Category) {}
}

export class UpdateCartItems implements Action {
	readonly type = CART_ITEMS_UPDATE;

	constructor(public payload: ShoppingCartItem[]) {}
}
export class UpdateCartItemQuantity implements Action {
	readonly type = CART_ITEM_UPDATE;

	constructor(public payload: ShoppingCartItem) {}
}

export class UpdateFavoriteProducts implements Action {
	readonly type = FAVORITE_PRODUCTS_UPDATE;

	constructor(public payload: FavoriteProduct[]) {}
}

export class UpdateTags implements Action {
	readonly type = TAGS_UPDATE;

	constructor(public payload: Tag[]) {}
}

export class UpdateProducts implements Action {
	readonly type = PRODUCTS_UPDATE;

	constructor(public payload: Product[]) {}
}

export class UpdateTagGroups implements Action {
	readonly type = TAG_GROUPS_UPDATE;

	constructor(public payload: TagGroup[]) {}
}

export class UpdateProduct implements Action {
	readonly type = PRODUCT_UPDATE;

	constructor(public payload: Product) {}
}

export class UpdateFeaturedItems implements Action {
	readonly type = FEATURED_ITEMS_UPDATE;

	constructor(public payload: Feature[]) {}
}

export class UpdateAccountFilters implements Action {
	readonly type = ACCOUNT_FILTERS_UPDATE;

	constructor(public payload: AccountFilter[]) {}
}

export class UpdateContentPages implements Action {
	readonly type = CONTENT_PAGES_UPDATE;

	constructor(public payload: ContentPage[]) {}
}

export class UpdateAddresses implements Action {
	readonly type = ADDRESSES_UPDATE;

	constructor(public payload: Address[]) {}
}

export class UpdateCategoryView implements Action {
	readonly type = CATEGORY_VIEW_UPDATE;

	constructor(public payload: CategoryViewEnum) {}
}

export class UpdateShippingAddress implements Action {
	readonly type = SHIPPING_ADDRESS_UPDATE;

	constructor(public payload: Address) {}
}

export class UpdateShippingAddressSameAsBilling implements Action {
	readonly type = SHIPPING_ADDRESS_SAME_AS_BILLING_UPDATE;

	constructor(public payload: boolean) {}
}

export class UpdateAccountDetail implements Action {
	readonly type = ACCOUNT_DETAIL_UPDATE;

	constructor(public payload: User) {}
}

// export actions
export type Actions =
	| LoadInitialState
	| LoginUser
	| LogoutUser
	//  | UpdateStripeCustomer
	| UpdateCategories
	| UpdateCategory
	| UpdateCartItems
	| UpdateCartItemQuantity
	| UpdateFavoriteProducts
	| UpdateTagGroups
	| UpdateTags
	| UpdateProducts
	| UpdateProduct
	| UpdateFeaturedItems
	| UpdateAccountFilters
	| UpdateContentPages
	| UpdateAddresses
	| UpdateCategoryView
	| UpdateShippingAddress
	| UpdateShippingAddressSameAsBilling
	| UpdateAccountDetail;
