import {Component, Input} from '@angular/core';
import {Image} from '../../../core/models/image.model';

@Component({
	selector: 'app-multi-image',
	templateUrl: './multi-image.component.html',
	styleUrls: ['./multi-image.component.scss']
})
export class MultiImageComponent {

	@Input() images: Image[] = [];
	defaultImage = '../../../../assets/image-not-found.500.jpg';
	constructor() {
	}


	imageError(event: any) {
		event.target.src = this.defaultImage;
	}
}
