// Stripe Model Classes

// import {Source, SourceData} from 'ngx-stripe';
// import {FlowTypes, UsageTypes} from 'ngx-stripe/src/interfaces/sources';

/**
 * StripeCustomer
 */
export class StripeCustomer {
    id: string;
    object: string;
    account_balance: number;
    created: number;
    currency: string;
    default_source: string;
    delinquent: boolean;
    description: string;
    discount: string;
    email: string;
    invoice_prefix: string;
    livemode: boolean;
    metadata: any;
    shipping: string;
    sources: StripeSources;
    subscriptions: StripeSubscriptions;
    tax_info: string;
    tax_info_verification: any;

} // StripeCustomer

/**
 * StripeSources
 */
export class StripeSources {
    object: string;
    data: StripeSource[];
    has_more: boolean;
    total_count: number;
    url: string;

} // StripeSources

/**
 * StripeSource
 */
export class StripeSource {
    id: string;
    object: string;
    amount: number;
    card: StripeCard;
    client_secret: string;
    created: number;
    currency: string;
    customer: string;
    flow: string;
    livemode: boolean;
    metadata: any;
    owner: StripeOwner;
    statement_descriptor: string;
    status: string;
    type: string;
    usage: string;
    // isSelected: boolean;

    /**
     * Create a StripeSource object from an NgxSource object
     * @param {Source} ngxSource
     * @returns {StripeSource}
     */
    public static createFromNgxSource(ngxSource: any): StripeSource {

            const stripeAddress = {
                line1: ngxSource.owner.address ? ngxSource.owner.address.line1 : '',
                line2: ngxSource.owner.address ? ngxSource.owner.address.line2 : '',
                city: ngxSource.owner.address ? ngxSource.owner.address.city : '',
                state: ngxSource.owner.address ? ngxSource.owner.address.state : '',
                postal_code: ngxSource.owner.address ? ngxSource.owner.address.postal_code : ''
            } as StripeAddress;

            const stripeOwner = {
                name: ngxSource.owner.name,
                email: ngxSource.owner.email,
                phone: ngxSource.owner.phone,
                address: stripeAddress
            } as StripeOwner;

            // const stripeSource = {
            //     id: ngxSource.id,
            //     card: null, // not availabie in ngxSource
            //     client_secret: ngxSource.client_secret,
            //     created: ngxSource.created,
            //     currency: ngxSource.currency,
            //     flow: ngxSource.flow,
            //     livemode: ngxSource.livemode,
            //     metadata: ngxSource.metadata,
            //     owner: stripeOwner,
            //     statement_descriptor: null, // not available in ngxSource
            //     status: ngxSource.status,
            //     type: ngxSource.type,
            //     usage: ngxSource.usage
            // } as StripeSource;

            // return stripeSource;

      return {} as StripeSource


    } // createFromNgxSource()

} // StripeSource

/**
 * StripeCard
 */
export class StripeCard {
    id: string;
    address_city: string;
    address_country: string;
    address_line1: string;
    address_line1_check: any;
    address_line2: string;
    address_state: string;
    address_zip: string;
    address_zip_check: any;
    brand: string;
    country: string;
    customer: string;
    cvc_check: string;
    dynamic_last4: any;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    funding: string;
    last4: string;
    metadata: {};
    name: string;
    tokenization_method: any;
    card_automatically_updated: false;
    three_d_secure: string;
}

/**
 *
 */
export class StripeOwner {
    address?: StripeAddress;
    email: string;
    name: string;
    phone: string;
    verified_address: StripeAddress;
    verified_email: string;
    verified_name: string;
    verified_phone: string;

    constructor() {
        this.address = new StripeAddress();
    } // constructor()

} // StripeOwner

export class StripeAddress {
    city: string;
    country: string;
    line1: string;
    line2: string;
    postal_code: string;
    state: string;
}

export class StripeSubscriptions {
    object: string;
    data: StripeSubscription[];
    has_more: boolean;
    total_count: number;
    url: string;
}

export class StripeSubscription {

}

export class StripeCharge {
    id: string;
    object: string;
    amount: number;
    amount_refunded: number;
    application: string;
    application_fee: any;
    balance_transaction: string;
    captured: boolean;
    created: number;
    currency: string;
    customer: string;
    description: string;
    destination: string;
    dispute: string;
    failure_code: string;
    failure_message: string;
    fraud_details: {};
    invoice: string;
    livemode: boolean;
    metadata: {};
    on_behalf_of: string;
    order: string;
    outcome: {
        network_status: string;
        reason: string;
        risk_level: string;
        seller_message: string;
        type: string;
    };
    paid: boolean;
    receipt_email: string;
    receipt_number: any;
    refunded: boolean;
    refunds: {
        object: string;
        data: {};
        has_more: boolean;
        total_count: number;
        url: string;
    };
    review: string;
    shipping: string;
    source: StripeSource;
    source_transfer: string;
    statement_descriptor: string;
    status: string;
    transfer_group: string;
}

export class StripeSourceData {
    type?: string;
    amount?: number;
    currency?: string;
    // flow?: FlowTypes;
    metadata?: {
        [key: string]: any;
    };
    owner?: StripeOwner;
    redirect?: {
        [key: string]: any;
        return_url: string;
    };
    token?: string;
    // usage?: UsageTypes;

    constructor() {
        this.owner = new StripeOwner();
    }

}

export class StripeChargeInput {
    amount: number;
    currency: string;
    description: string;
    capture: boolean;
    sourceId: string;
    customer: string;
}

export class StripeCustomerInput {
    email: string;
    sourceId: string;
}

export class StripeCustomerSourceInput {
    customerId: string;
    sourceId: string;
}

export class StripeExpirationDateInput {
    sourceId: string;
    exp_month: number;
    exp_year: number;
}

