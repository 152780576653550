import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {CategoryService} from "../services/category.service";
import {catchError} from "rxjs/operators";
import {ObservableInput, of} from "rxjs";

@Injectable()
export class CategoryResolver implements Resolve<any> {
	constructor(private categoryService: CategoryService, private router: Router) {}

	resolve(route: ActivatedRouteSnapshot) {
		const url = route.params['id'];
		if (!url) {
			return this.notFound();
		}
		return this.categoryService.getCategory(url).pipe(
			catchError(err => this.handleError(err)));
	}

	handleError(err: any): ObservableInput<null> {
		console.log(err);
		this.notFound();
		return of(err)
	}

	notFound() {
		this.router.navigateByUrl('/products/not-found', {skipLocationChange: true});
	}
}
