import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output, PLATFORM_ID} from '@angular/core';
import {AppStore} from '../../core/models/state.model';
import {Store} from '@ngrx/store';
import {Address} from '../../core/models/address.model';
import {ConfirmationDialogComponent} from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {AddressService} from '../../core/services/address.service';
import {MatDialog} from '@angular/material/dialog';
import {EditAddressDialogComponent} from '../../shared/components/edit-address-dialog/edit-address-dialog.component';
import {UpdateAddresses, UpdateShippingAddress} from '../../core/actions/state.actions';
import {FormControl, Validators} from '@angular/forms';
import {CartService} from '../../core/services/cart.service';
import {AddressValidationDialogComponent} from './address-validation-dialog/address-validation.dialog.component';
import {Order} from '../../core/models/order.model';
import {OrderService} from '../../core/services/order.service';
import {FedexService} from '../../core/services/fedex.service';
import {ErrorService} from '../../core/services/error.service';
import {Constants} from '../../shared/config/constants.config';
import {User} from '../../core/models/user.interface';

@Component({
	selector: 'app-shipping-information',
	templateUrl: './shipping-information.component.html',
	styleUrls: ['./shipping-information.component.scss']
})
export class ShippingInformationComponent implements OnInit, OnDestroy {
	@Output() completed = new EventEmitter();
	@Output() returning = new EventEmitter();

	orderArray: Order[];

	loggedInUser: User | null;
	formAddress: Address = new Address();
	addresses: Address[];
	shouldSaveAddress: boolean;
	selectedAddress: Address | null;
	fedexValidatedAddress: Address;
	smallScreen: boolean = false;
	addr2Disabled: boolean = false;
	orderAgreementAccepted: boolean = false;

	orderApiErrorMsg: string = 'We could not verify your order. Please try again.';
	addAddressToOrderErrorMsg: string = 'We could not add your address to your order. Please try again.';

	// form controls
	nameFormControl = new FormControl('', [Validators.required, Validators.pattern(Constants.nameAndAddressRegex)]);
	emailFormControl = new FormControl('', [Validators.required, Validators.pattern(Constants.emailRegex)]);
	addressLine1FormControl = new FormControl('', [Validators.required, Validators.pattern(Constants.nameAndAddressRegex)]);
	addressLine2FormControl = new FormControl('', [Validators.pattern(Constants.nameAndAddressRegex)]);
	cityFormControl = new FormControl('', [Validators.required, Validators.pattern(Constants.nameAndAddressRegex)]);
	stateFormControl = new FormControl('', [Validators.required, Validators.pattern(Constants.stateCodeRegex)]);
	postalCodeFormControl = new FormControl('', [Validators.required, Validators.pattern(Constants.postalCodeRegex)]);
	phoneNumberFormControl = new FormControl('', [Validators.required, Validators.pattern(Constants.phoneNumberRegex)]);

	constructor(
		private cartService: CartService,
		private fedexService: FedexService,
		private store: Store<AppStore>,
		private dialog: MatDialog,
		private addressService: AddressService,
		private orderService: OrderService,
		private errorService: ErrorService,
		@Inject(PLATFORM_ID) private platformId: any
	) {
		//removed media from constructor and @params
		store.subscribe((subscribedStore) => {
			const state = subscribedStore.state;
			this.loggedInUser = state.user;
			this.addresses = state.addresses;
			this.selectedAddress = JSON.parse(JSON.stringify(state.shippingAddress ?? new Address()));
			if (this.selectedAddress && this.selectedAddress.isFormAddress) {
				this.formAddress = this.selectedAddress;
			}
			this.addr2Disabled = state.addr2IsDisabled;
		});

		// set flag on form address
		this.formAddress.isFormAddress = true;
	}

	ngOnInit() {
		// orderArray needs to be reset here to avoid confusion for the summary display
		this.orderArray = [];

		this.orderService.getOrdersReadyForShippingDecisions().subscribe(
			(next: Order[]) => {
				if (!next) {
					this.errorService.showErrorMessage(this.orderApiErrorMsg);
					return;
				}

				this.orderArray = next;
				// get fedex validated address from order if it is complete
				// note that we never store a non-validated address in the order
				// so if there is an address there then it has been validated
				const firstOrder = this.orderArray[0];

				if (
					firstOrder &&
					firstOrder.shipToName &&
					firstOrder.shipToAddress1 &&
					firstOrder.shipToCity &&
					firstOrder.shipToState &&
					firstOrder.shipToZip &&
					firstOrder.shipToPhone
				) {
					// build the address
					const validatedAddress = new Address();
					validatedAddress.name = firstOrder.shipToName;
					validatedAddress.line1 = firstOrder.shipToAddress1;
					validatedAddress.line2 = firstOrder.shipToAddress2;
					validatedAddress.city = firstOrder.shipToCity;
					validatedAddress.state = firstOrder.shipToState;
					validatedAddress.postalCode = firstOrder.shipToZip;
					validatedAddress.phone = firstOrder.shipToPhone;
					validatedAddress.isValidatedAddress = true;

					this.fedexValidatedAddress = validatedAddress;
					this.selectedAddress = validatedAddress;

				}
			},
			(error: any) => {
				this.errorService.showErrorMessage(this.orderApiErrorMsg);
			}
		);
	}

	ngOnDestroy() {
		if (this.selectedAddress) this.store.dispatch(new UpdateShippingAddress(this.selectedAddress));
	}

	validateAddressInputs() {
		// if they have selected a saved address  then proceed
		if (!this.selectedAddress) {
			return false;
		}

		if (this.selectedAddress.id || this.selectedAddress.isValidatedAddress) {
			return true;
		} else {
			// check for validation errors
			const nameErrors = this.nameFormControl.hasError('required') || this.nameFormControl.hasError('pattern');
			const addressLine1Errors = this.addressLine1FormControl.hasError('required') || this.addressLine1FormControl.hasError('pattern');
			const cityErrors = this.cityFormControl.hasError('required') || this.cityFormControl.hasError('pattern');
			const stateErrors = this.stateFormControl.hasError('required') || this.stateFormControl.hasError('pattern');
			const postalCodeErrors = this.postalCodeFormControl.hasError('required') || this.postalCodeFormControl.hasError('pattern');
			const phoneNumberErrors = this.phoneNumberFormControl.hasError('required') || this.phoneNumberFormControl.hasError('pattern');
			return !nameErrors && !addressLine1Errors && !cityErrors && !stateErrors && !postalCodeErrors && !phoneNumberErrors;
		}
	}

	next() {
		if (this.selectedAddress?.isValidatedAddress) {
			this.completed.emit();
		} else {
			this.doAddressValidation();
		}
	} // next()

	/**
	 *
	 */
	doAddressValidation() {
		let address: Address;

		if (!this.orderAgreementAccepted) {
			return;
		}

		if (this.selectedAddress) {
			// a shipping address was just selected from the list of addresses
			address = this.selectedAddress;
		} else {
			// no shipping address was just selected, so use the form
			address = this.formAddress;
		}

		const addressToValidate: Address = {
			name: address.name,
			line1: address.line1,
			line2: address.line2,
			city: address.city,
			state: address.state,
			postalCode: address.postalCode,
			country: address.country,
			phone: address.phone,
			email: address.email
		} as Address;

		// begin address validation dialog

		const addressValidationDialog = this.dialog.open(AddressValidationDialogComponent, {
			width: !this.smallScreen ? '50%' : '90%'
		});

		addressValidationDialog.componentInstance.preValidation = addressToValidate;

		addressValidationDialog.afterClosed().subscribe((suggestedAddress?: Address) => {
			if (suggestedAddress === null || suggestedAddress === undefined) {
				return;
			}

			// need to backfill fields in suggestedAddress
			suggestedAddress.name = address.name;
			suggestedAddress.phone = address.phone;

			const orderWithAddress: Order = this.orderFromAddress(suggestedAddress);
			/* Add the address to the order */
			this.orderService.addShippingAddress(orderWithAddress, this.orderAgreementAccepted).subscribe(
				(updateOrderResult: boolean) => {
					if (!updateOrderResult) {
						this.errorService.showErrorMessage(this.addAddressToOrderErrorMsg);
						return;
					}
					if ((!this.selectedAddress || !this.selectedAddress.id) && this.shouldSaveAddress) {
						// save the address
						this.saveFormAddress();
					}
					this.completed.emit();
				},
				(error: any) => {
					this.errorService.showErrorMessage(this.addAddressToOrderErrorMsg);
				}
			);
		});
	}

	orderFromAddress(address: Address) {
		return {
			shipToName: address.name,
			shipToPhone: address.phone,
			shipToAddress1: address.line1,
			shipToAddress2: address.line2 ?? '',
			shipToCity: address.city,
			shipToState: address.state,
			shipToZip: address.postalCode,
			shipToCountry: address.country ?? '',
			shipToCareOf: address.careOf
		} as Order;
	}

	saveFormAddress() {
    //we only hit this function if we have a loggedInUser
    this.formAddress.userId = this.loggedInUser!.id;

    this.formAddress.default = !!this.formAddress.default;

		this.addressService.create(this.formAddress).subscribe((success) => {
			if (!success) {
				return;
			}
			this.store.dispatch(new UpdateAddresses(success));
		});
	}

	isAddressChosen(address: any): boolean {
		let returnValue = false;

		if (this.selectedAddress) {
			returnValue =
				(this.selectedAddress.id && this.selectedAddress.id === address.id) ||
				(this.selectedAddress.isFormAddress && address.isFormAddress) ||
				(this.selectedAddress.isValidatedAddress && address.isValidatedAddress);
		}

		return returnValue;
	}

	addressSelectionChanged(checked: boolean, checkboxAddress: Address) {
		if (checked) {
			// i want to make sure this isnt the same that is already up in the address
			// if (!this.selectedAddress || this.selectedAddress.id !== checkboxAddress.id) {

			this.selectedAddress = checkboxAddress;
			this.store.dispatch(new UpdateShippingAddress(checkboxAddress));
			// }
		} else {
			this.selectedAddress = null;
		}
	}

	editAddress(address: Address) {
		const editAddressDialog = this.dialog.open(EditAddressDialogComponent, {
			data: {...address},
			width: !this.smallScreen ? '65%' : '95%'
		});

		editAddressDialog.afterClosed().subscribe((result) => {
			if (!result) {
				return;
			}

			this.store.dispatch(new UpdateAddresses(result));
		});
	}

	removeAddress(address: Address) {
		this.showDeleteConfirmation().subscribe((confirmationResult) => {
			if (confirmationResult) {
				this.addressService.delete(address).subscribe((success) => {
          const newAddresses = [...this.addresses];
          const index = newAddresses.findIndex(addr => addr.id === address.id);
          newAddresses.splice(index, 1);
          this.store.dispatch(new UpdateAddresses(newAddresses));
					this.selectedAddress = null;
				},
          err => {
          console.error(err)
          });
			}
		});
	}

	protected showDeleteConfirmation() {
		const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '30%',
      data:
        {
          title: 'Delete address',
          message: 'Are you sure you want to delete this address?'
        }
    });

		return confirmationDialog.afterClosed();
	}

	// return to shopping cart
	return() {
		this.returning.emit();
	}
}
