import { Injectable } from '@angular/core';
import { Resolve} from '@angular/router';
import {LandingPageService} from '../services/landing-page.service';

@Injectable()
export class LandingPageResolver implements Resolve<any> {
	constructor(private landingPageService: LandingPageService) {}

	resolve() {
		return this.landingPageService.getFeatured();
	}
}