import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Address} from '../../../core/models/address.model';
import {AddressService} from '../../../core/services/address.service';
import {FormControl, Validators} from '@angular/forms';
import {Constants} from '../../config/constants.config';
import {Store} from "@ngrx/store";
import {AppStore} from "../../../core/models/state.model";

@Component({
  selector: 'app-edit-address-dialog',
  templateUrl: './edit-address-dialog.component.html',
  styleUrls: ['./edit-address-dialog.component.scss']
})
export class EditAddressDialogComponent {
  address: Address;
  replaceSpaceRegex: string = '\\s';
  addr2Disabled: boolean = false;

  nameFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(Constants.nameAndAddressRegex)
  ]);
  addressLine1FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(Constants.nameAndAddressRegex)
  ]);
  addressLine2FormControl = new FormControl('', [
    Validators.pattern(Constants.nameAndAddressRegex)
  ]);

  cityFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(Constants.nameAndAddressRegex)
  ]);
  stateFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(Constants.stateCodeRegex)
  ]);
  postalCodeFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(Constants.postalCodeRegex)
  ]);
  phoneNumberFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(Constants.phoneNumberRegex)
  ]);

  constructor(private dialog: MatDialogRef<EditAddressDialogComponent>,
              private addressService: AddressService,
              private store: Store<AppStore>,
              @Inject(MAT_DIALOG_DATA) public data: Address) {
    if (data) {
      this.address = data;
    } else {
      this.address = new Address();
    }

    store.subscribe(subscribedStore => {
      const state = subscribedStore.state;
      this.addr2Disabled = state.addr2IsDisabled;
      if(!this.address.userId) this.address.userId = state.user!.id;
    });
  }

  submit() {
    if (this.address.id) {
      this.addressService.update(this.address)
        .subscribe(createAddressResult => {
          this.dialog.close(createAddressResult);
        });
    } else {
      this.addressService.create(this.address)
        .subscribe(createAddressResult => {
          this.dialog.close(createAddressResult);
        });
    }
  }

  validateAddressInputs() {
    // check for validation errors
    // const nameErrors = this.nameFormControl.hasError('required') || this.nameFormControl.hasError('pattern');
    // const addressLine1Errors = this.addressLine1FormControl.hasError('required') || this.addressLine1FormControl.hasError('pattern');
    // const addressLine2Errors = this.addressLine2FormControl.hasError('pattern');
    // const cityErrors = this.cityFormControl.hasError('required') || this.cityFormControl.hasError('pattern');
    // const stateErrors = this.stateFormControl.hasError('required') || this.stateFormControl.hasError('pattern');
    // const postalCodeErrors = this.postalCodeFormControl.hasError('required') || this.postalCodeFormControl.hasError('pattern');
    // const phoneNumberErrors = this.phoneNumberFormControl.hasError('required') || this.phoneNumberFormControl.hasError('pattern');
    // return !nameErrors && !addressLine1Errors && !addressLine2Errors && !cityErrors && !stateErrors && !postalCodeErrors && !phoneNumberErrors;
    return true;
  }

  cancel() {
    this.dialog.close();
  }
}
