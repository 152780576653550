import { Injectable }           from '@angular/core';
import { MatDialog }            from '@angular/material/dialog';
import {ErrorDialogComponent} from "../../shared/components/error-dialog/error-dialog.component";

@Injectable()
export class ErrorService {

    constructor(private dialog: MatDialog) {
    }

    showErrorMessage(message?: string, title?: string) {
        const errorMessage = message ? message : 'An unknown error occured...';
        const errorTitle = title ? title : 'Error';
        this.dialog.open(ErrorDialogComponent, {width: '60%', data: { message: errorMessage, title: errorTitle }});
    }
}
