<div class="bread-crumb-options search-header flex flex-col gap-4">
    <div class="flex flex-row justify-between max-md:flex-col gap-2">
        <div class="flex flex-row">
            <div class="searchParamDisplay flex flex-col">
                <label class="title">Search Results</label>
                <div id="search-param" class="flex flex-row gap-2 pt-2" *ngIf="searchParam">
                    <p> You searched for: <i>{{searchParam}}</i></p>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="gradient-grey-border" fxFlex fxHide.lt-md="true"></div> -->
    <div class="mobile-options hidden max-md:flex flex-row justify-center gap-5">
      <button mat-flat-button color="primary" *ngIf="filterGroups.length > 0" (click)="openFilterDialog()">
          FILTERS
      </button>
              <!--<button mat-button class="white-button" (click)="openSortDialog()">FILTERS</button>-->
    </div>
</div>

<div class="flex flex-row gap-10">
    <div class="flex flex-col">
      <div class="sideview-container flex flex-col max-md:hidden" *ngFor="let filterGroup of filterGroups" >
        <div class="filters flex flex-col" >
          <div class="flex flex-col">
            <div class="filter-header">
              <label>Filter by {{filterGroup.title}}</label>
            </div>
            <div class="checkbox-container flex flex-col" *ngFor="let filter of filterGroup.tags">
              <mat-checkbox  class="checkbox"
                             [checked]="isFilterSelected(filter)" (change)="filterIsSelected(filter)" matTooltip="{{filter.description}}">
                {{filter.name}}
              </mat-checkbox>
              <!-- <label class="sub-models" [ngClass]="{sticky: isFilterSelected(filter)}" [innerHtml]="filter.description"></label> -->

            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col w-full">


        <div *ngIf="!searchResult" class="searchSpinnerDiv">
            <i *ngIf="isSearchActive" class="fa fa-spinner fa-spin" aria-hidden="true"></i>
            <div *ngIf="!isSearchActive && searchParam.length < 3" class="searchSectionDisplay">Please enter more than three characters to search off of.</div>
        </div>

        <div *ngIf="searchResult">

            <div class="searchSectionDisplay">Categories:</div>

            <div *ngIf="filteredCategories.length > 0 && selectedFilters.length === 0">
                <div class="gallery-view flex flex-row flex-wrap w-full max-md:mobile-view">
                    <app-category-card
                            *ngFor="let category of filteredCategories; let index = index"
                            class="flex w-1/3 max-md:w1/2"
                            [category]="category"
                            [smallImage]="true"
                            [ngClass]="{'bottom': index > categoryBottomRow}"
                    ></app-category-card>
                </div>
            </div>

            <div *ngIf="filteredCategories.length === 0 || (selectedFilters && selectedFilters.length > 0)">
                <div *ngIf="filteredCategories.length === 0">No categories found.</div>
                <div *ngIf="filteredCategories.length > 0">No categories match the current filter selection.</div>
            </div>

            <div class="searchSectionDisplay">Products</div>

            <div *ngIf="filteredAndSortedProducts.length > 0">
                <div class="gallery-view flex flex-row flex-wrap w-full max-md:mobile-view">
                    <app-product-card-gallery class="flex w-1/3 max-md:w-1/2"
                    *ngFor="let product of filteredAndSortedProducts; let index = index"
            [ngClass]="{'bottom': index > productBottomRow}" [product]="product"></app-product-card-gallery>
                </div>
            </div>

            <div *ngIf="filteredAndSortedProducts.length === 0">
                <div *ngIf="searchResult.products.length === 0">No products found.</div>
                <div *ngIf="searchResult.products.length > 0">No products match the current filter selection.</div>
            </div>

        </div>


    </div>
</div>
