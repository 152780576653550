import {Component, Input} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppStore} from '../../core/models/state.model';
import {Product} from '../../core/models/product.model';
import {Category} from '../../core/models/category.model';
import {ContentPage} from '../../core/models/content-page.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.scss']
})
export class SubCategoryComponent {

  @Input() subCategory: Category;
  imageNotFound: boolean = false;
  products: Product[];
  categories: Category[];
  contentPages: ContentPage[];

  constructor(private router: Router,
              private store: Store<AppStore>) {
    store.subscribe(store => {
      const state = store.state;
      this.products = state.products;
      this.categories = state.categories;
      this.contentPages = state.contentPages;
    });
  }

  imageError() {
    this.imageNotFound = true;
  }

  get link(): string | null {
    let link = null;
    /*if (this.subCategory) {
        switch (this.subCategory.type) {
            case Category.CATEGORY:
                const category = this.categories[this.subCategory.SubCategorydId];
                if (category) {
                    link = `/products/${category.url}`;
                }
                break;
            case Category.PRODUCT:
                const product = this.products.find(prod => prod.itemNumber === this.subCategory.SubCategorydId.toString());
                if (product) {
                    link = `/product/${product.url}`;
                }
                break;
            case Category.RECIPE:
                const recipe = this.recipes[this.subCategory.SubCategorydId];
                if (recipe) {
                    link = `/recipe/${recipe.url}`;
                }
                break;
            case Category.CONTENT:
                const contentPage = this.contentPages[this.subCategory.SubCategorydId];
                link = contentPage.url;
                break;
        }
    }*/
    return link;
  }

  get imageUrl(): string {
    return this.subCategory.image?.url ?? '';
  }

  goToRelated() {
    if (!this.link) {
      return;
    }
    this.router.navigateByUrl(this.link);
  }


}
