// import { WINDOW } from '@ng-toolkit/universal';
import { Component, OnInit, Inject } from '@angular/core';
import { LandingPageService } from "../core/services/landing-page.service";
import { Feature } from "../core/models/feature.model";
import { Instagram } from "../core/models/instagram.model";
import { Store } from "@ngrx/store";
import { AppStore } from "../core/models/state.model";
import {
  UpdateCategories,
  UpdateContentPages,
  UpdateFeaturedItems,
  UpdateProducts
} from "../core/actions/state.actions";
import { NewsLetterService } from "../core/services/news-letter.service";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { Banner } from "../core/models/banner.model";
import { SeoService } from '../core/services/seo.service';
import {Tag} from "../core/models/tag.model";
import {Category} from "../core/models/category.model";
import {StorageService} from "../core/services/storage.service";
import {StorageEnum} from "../core/enums/storage.enum";

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  featuredItems: Feature[] = [];
  instagram: Instagram;
  snackBarConfig = new MatSnackBarConfig();
  banner: Banner;
  mobileMenuOpened: boolean = false;
  tags: Tag[];
  categories: Category[] = [];
  filterStorageKey = StorageEnum.SELECTED_FILTERS;

  constructor(private landingPageService: LandingPageService,
              private newsLetterService: NewsLetterService,
              private snackBar: MatSnackBar,
              private store: Store<AppStore>,
              private route: ActivatedRoute,
              private storageService: StorageService,
              private seoService: SeoService) {
    store.subscribe(store => {
      const state = store.state;
      this.featuredItems = state.featuredItems;
      this.banner = state.banner;
      this.tags = state.tags;
      this.categories = state.categories;
    })
  }

  ngOnInit() {
    this.snackBarConfig.horizontalPosition = 'center';
    this.snackBarConfig.verticalPosition = 'top';
    this.snackBarConfig.duration = 2400;
    this.snackBarConfig.panelClass = 'snackBar';
    this.getFeaturedItems();

    const title = 'Grow Generation';
    const keywords = 'A0,A1,A2,ECV,Humvee,AM General,High Mobility Multipurpose Wheeled Vehicle,HMMWV,Humvee Parts,HMMWV parts,' +
      this.tags.map((t) =>
      {
        let keyword = t.description?.replace(/<p>Includes:?<\/p><p>/g, '') ?? '';
        keyword = keyword.replace(/<p>/g, ',');
        keyword = keyword.replace(/<\/p>/g, ' parts');
        return keyword;
      });

    this.seoService.setTitle(title);
    this.seoService.setMetaTitle(title);
    this.seoService.setMetaDescription(title);
    this.seoService.setMetaKeywords(keywords);
  }

  get carouselItems(): Feature[] {
    let items: Feature[] = [];
    if (this.featuredItems) {
      items = this.featuredItems.filter(fet => fet.carousel)
        .sort((a, b) => a.displaySeq - b.displaySeq);
    }
    return items;
  }

  get featured(): Feature[] {
    let items: Feature[] = [];
    if (this.featuredItems) {
      items = this.featuredItems.filter(fet => !fet.carousel && !fet.imageFilename)
        .sort((a, b) => a.displaySeq - b.displaySeq);
    }
    return items;
  }

  get featuredWithImages(): Feature[] {
    let items: Feature[] = [];
    if (this.featuredItems) {
      items = this.featuredItems.filter(fet => !fet.carousel && fet.imageFilename)
        .sort((a, b) => a.displaySeq - b.displaySeq);
    }
    return items;
  }

  get featuredCategories(): Feature[] {
    let items: Feature[] = [];
    if (this.featuredItems) {
      items = this.featuredItems.filter(fet => !fet.carousel && fet.imageFilename && fet.type === "CATEGORY")
        .sort((a, b) => a.displaySeq - b.displaySeq);
    }
    return items;

  }

  get featuredProducts(): Feature[] {
    let items: Feature[] = [];
    if (this.featuredItems) {
      items = this.featuredItems.filter(fet => !fet.carousel && fet.type === "PRODUCT")
        .sort((a, b) => a.displaySeq - b.displaySeq);
    }
    return items;
  }

  getFeaturedItems() {
    this.route.data.subscribe(resolve => {
      const featuredItems: Feature[] = resolve['resolve'];
      if (!featuredItems) {
        return;
      }

      this.store.dispatch(new UpdateFeaturedItems(featuredItems));
    }, error => {
      console.error(error);
    });
  }

  get primaryCategories(): Category[] {
    return this.categories.filter(cat => cat.primaryCategory)
      // make sure the list is unique
      .filter((cat, index, self) => self.findIndex(s => s.id === cat.id) === index)
      // we only want to show shop by category
      .filter((cat) => cat.name.toLocaleLowerCase() === 'shop by category')
      .sort((a, b) => a.displaySeq - b.displaySeq);
  }

  getCategoryChildren(category: Category): Category[] {
    if (category.categoryRelations) {
      return this.categories.filter(cat => category.categoryRelations.findIndex(cr => cr.categoryId === cat.id) > -1)
        .sort((catA, catB) => {
          const catAIndex = category.categoryRelations.findIndex(cr => cr.categoryId === catA.id);
          const catBIndex = category.categoryRelations.findIndex(cr => cr.categoryId === catB.id);

          return category.categoryRelations[catAIndex].displaySeq - category.categoryRelations[catBIndex].displaySeq;
        });
    } else {
      return [];
    }
  }

  setFilter(filter: Tag, category: Category) {
    this.storageService.setItem(this.filterStorageKey, filter.id.toString());

  }

  triggerMobileMenu() {
    this.mobileMenuOpened = !this.mobileMenuOpened;
  }

}
