/**
 *
 */
export class Constants {

    // state codes for validation
    // includes Puerto Rico (PR) and US Virgin Islands (VI)
    public static stateCodeRegex = '^AK|ak|AL|al|AZ|az|AR|ar|CA|ca|CO|co|CT|ct|DE|de|FL|fl|GA|ga|HI|hi'
        + '|ID|id|IL|il|IN|in|IA|ia|KS|ks|KY|ky|LA|la|ME|me|MD|md|MA|ma|MI|mi|MN|mn|MS|ms|MO|mo|MT|mt'
        + '|NE|ne|NV|nv|NH|nh|NJ|nj|NM|nm|NY|ny|NC|nc|ND|nd|OH|oh|OK|ok|OR|or|PA|pa|RI|ri|SC|sc'
        + '|SD|sd|TN|tn|TX|tx|UT|ut|VT|vt|VA|va|WA|wa|WV|wv|WI|wi|WY|wy$';


    // postal code validation regex
    public static postalCodeRegex = '^\\d{5}(?:-\\d{4})?$';

    // phone number validation regex
    public static phoneNumberRegex = '^(?:(?:(\\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))' +
        '|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\\)?\\s?(?:[.-]\\s*)?)([2-9]1[02-9]|[2-9][02-9]1|' +
        '[2-9][02-9]{2})\\s?(?:[.-]\\s?)?([0-9]{4})$';

    // words and connectors regex for names, addresses, etc.
    // includes: dash, underscore
    public static nameAndAddressRegex = '[\\w\\s\\-\\_\\/\\.\\&]+';

    // email validator regex
    public static emailRegex = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$';
}
