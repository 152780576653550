import {Component, OnInit} from '@angular/core';
import {VerifyService} from '../../services/verify.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  loading: boolean = true;
  verificationSuccess: boolean = false;
  constructor(private verifyService: VerifyService, private route: ActivatedRoute) {}

  ngOnInit() {
    const params = this.route.snapshot.queryParamMap;
    if (params) {
      const verificationCode = params.get('verificationCode');

      if (!verificationCode) {
        this.loading = false;
        throw new Error('Invalid URL: Missing verification code');
      }

      this.verifyService.verifyCode(verificationCode)
        .subscribe((success: boolean) => {
          if (!success) {
            this.loading = false;
            return;
          }
          this.loading = false;
          this.verificationSuccess = true;
        }, error => {
          this.loading = false;
          console.error(error);
        });
    }
  }
}
