import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Feature} from "../models/feature.model";
import {Category} from "../models/category.model";
import {Product} from "../models/product.model";
import {ContentPage} from "../models/content-page.model";

@Injectable()
export class LandingPageService {

  readonly baseUrl = `${environment.url}/feature`;

  constructor(private http: HttpClient) { }

  getFeatured(): Observable<Feature[]> {
    return this.http.get<Feature[]>(this.baseUrl);
  }
}

class ReturnType {
  success: boolean;
  result: {featuredItems: Feature[], categories: Category[], products: Product[], contentPages: ContentPage[]}
}
