import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ProductReview} from "../models/product-review.model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProductReviewService {
  readonly baseUrl = `${environment.url}/product-review`;

  constructor(private http: HttpClient) {}

  getProductReviews(): Observable<ProductReview[]> {
    return this.http.get<ProductReview[]>(this.baseUrl)
  }

  getOneProductReview(reviewId: number): Observable<ProductReview> {
    return this.http.get<ProductReview>(`${this.baseUrl}/${reviewId}`)
  }

  createProductReview(productReview: ProductReview): Observable<ProductReview> {
    return this.http.post<ProductReview>(this.baseUrl, productReview)
  }
}
