
<div class="dialog-body flex flex-wrap gap-5 p-4">

  <div mat-dialog-title>
    <label class="section-title" *ngIf="!address.id">Enter a New Address</label>
    <label class="section-title" *ngIf="address.id">Update an Existing Address</label>
  </div>

  <div class="flex flex-col gap-3 flex-1">
    <label for="name">Name</label>
    <mat-form-field>
      <input matInput id="name" name="name" [(ngModel)]="address.name"
             (keydown)="nameFormControl.markAsTouched()"
             [formControl]="nameFormControl" required/>
      <mat-error *ngIf="nameFormControl.hasError('required')">
        Name is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="nameFormControl.hasError('pattern')">
        Name may not contain special characters.
      </mat-error>
    </mat-form-field>

    Address Line 1
    <mat-form-field>
      <input matInput id="address_line1" name="address_line1"
             [(ngModel)]="address.line1"
             (keydown)="addressLine1FormControl.markAsTouched()"
             [formControl]="addressLine1FormControl"/>
      <mat-error *ngIf="addressLine1FormControl.hasError('required')">
        Address is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="addressLine1FormControl.hasError('pattern')">
        Address may not contain special characters.
      </mat-error>
    </mat-form-field>

    <ng-container *ngIf="!addr2Disabled">
      Address Line 2
      <mat-form-field>
        <input matInput id="address_line2" name="address_line2"
               [(ngModel)]="address.line2"
               (keydown)="addressLine2FormControl.markAsTouched()"
               [formControl]="addressLine2FormControl"/>
        <mat-error *ngIf="addressLine2FormControl.hasError('pattern')">
          Address may not contain special characters.
        </mat-error>
      </mat-form-field>
    </ng-container>

    <div class="flex justify-between">
      <div class="flex flex-col w-[45%]">
        City
        <mat-form-field>
          <input matInput id="city" name="city" [(ngModel)]="address.city"
                 (keydown)="cityFormControl.markAsTouched()"
                 [formControl]="cityFormControl"/>
          <mat-error *ngIf="cityFormControl.hasError('required')">
            City is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="cityFormControl.hasError('pattern')">
            City may not contain special characters.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="flex flex-col w-1/5">
        State
        <mat-form-field>
          <input matInput id="state" name="state"
                 [(ngModel)]="address.state"
                 [formControl]="stateFormControl"
                 (keydown)="stateFormControl.markAsTouched()"
                 (keyup)="address.state = address.state.toUpperCase()"
                 maxlength="2"/>
          <mat-error *ngIf="stateFormControl.hasError('required') || stateFormControl.hasError('pattern')">
            Valid US State code is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div  class="flex flex-col w-1/5">
        ZIP
        <mat-form-field>
          <input matInput id="postal_code" name="postal_code"
                 [(ngModel)]="address.postalCode"
                 (keydown)="postalCodeFormControl.markAsTouched()"
                 (keyup)="address.postalCode = address.postalCode.replace(' ','')"
                 [formControl]="postalCodeFormControl"/>
          <mat-error *ngIf="postalCodeFormControl.hasError('required') || postalCodeFormControl.hasError('pattern')">
            Valid ZIP code is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    Phone Number
    <mat-form-field>
      <input matInput id="address_phone" name="address_phone"
             [(ngModel)]="address.phone"
             (keydown)="phoneNumberFormControl.markAsTouched()"
             (keyup)="address.phone = address.phone.replace(' ','')"
             [formControl]="phoneNumberFormControl"/>
      <mat-error *ngIf="phoneNumberFormControl.hasError('required') || phoneNumberFormControl.hasError('pattern')">
        Valid 10-digit Phone Number is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-checkbox [(ngModel)]="address.default">Make this my default address</mat-checkbox>

    <div  class="flex justify-end gap-2">
      <button mat-flat-button color="primary" (click)="cancel()">Cancel</button>
      <button mat-flat-button color="primary" (click)="submit()" [disabled]="!validateAddressInputs()">Save</button>
    </div>
  </div>
</div>
