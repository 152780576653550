import { OrderItem }         from './order-item.model';
import {PromotionTypeEnum}   from "../enums/promotion-type.enum";
import { OrderTrackingInfo } from './order-tracking-info.model';
export class Order {
    id: number;
    orderItem: OrderItem[];
    orderTrackingInfo: OrderTrackingInfo[];
    stripeSourceId: string;
    stripeCustomerId: string;
    stripeChargeId: string;
    stripeCapturedAmount: number;
    stripeCapturedTs: Date;
    s2kOrderNumber: string;
    orderDate: string;
    productTotal: number;
    orderTotal: number;
    giftFlag: boolean;
    giftReceipt: boolean;
    giftMessage: string;
    shipVia: string;
    shipMethod: string;
    freight: number;
    hasPerishables: boolean;
    hasNonPerishables: boolean;
    promoCode: string;
    shippingDiscountPercent: number;
    shippingDiscountAmount: number;
    shippingPromotionType: PromotionTypeEnum;
    shippingPromotionAmount: number;
    shippingPromotionMethod: string;
    billToZip: string;
    billToEmail: string;
    shipToName: string;
    shipToEmail: string;
    shipToAddress1: string;
    shipToAddress2: string;
    shipToCity: string;
    shipToState: string;
    shipToZip: string;
    shipToCountry: string;
    shipToPhone: string;
    accountId: number;
    number: number;
    orderStatus: string;
    shipToCareOf: string;
    billToCareOf: string;
    shippingStatus: string;
    trackingNumber: string;
    poNumber: string;
    soNumber: string;
    weight: number;
    weightUom: string;
    taxTotal: number;
    taxRateState: number;
    taxRateCnty: number;
    taxRateLocal1: number;
    taxRateLocal2: number;
    cubicVolume: number;
    cubicVolumeUom: string;
    refundTotal: number;
}
