import { Component, Inject, Input, OnInit } from '@angular/core';
import { Category } from '../../models/category.model';
import { CatalogSearchService } from '../../services/catalog-search.service';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { LogoutUser } from '../../actions/state.actions';
import { Store } from '@ngrx/store';
import { AppStore } from '../../models/state.model';
import { Account } from '../../models/account.model';
import { ShoppingCartItem } from '../../models/shopping-cart.model';
import { StorageService } from '../../services/storage.service';
import { StorageEnum } from '../../enums/storage.enum';
import { FormControl, Validators } from '@angular/forms';
import { SearchResult } from '../../models/search-result.model';
import { Banner } from '../../models/banner.model';
import {debounceTime, distinctUntilChanged, switchMap, filter} from "rxjs";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import { Product } from "../../models/product.model";
import { User } from '../../models/user.interface';
import {MajorCategoryEnum} from '../../enums/major-category.enum';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	@Input() smallHeader: boolean;
	@Input() banner: Banner;
	cartItems: ShoppingCartItem[];
	loggedInUser: User | null;
	categories: Category[];
	searchResult: SearchResult;  // result storage
	searchMessage: string | null;       // optional message
	isSearchActive: boolean;     // whether request in progress
	mobileMenuOpened: boolean = false;
	searchBoxFormControl = new FormControl('', Validators.minLength(3));
	searchKeyLengthError: boolean;
	removeHoverFlag: boolean;
  majorCategories: string[] = Object.values(MajorCategoryEnum);

	constructor(
		private catalogSearchService: CatalogSearchService,
		private loginService: LoginService,
		private router: Router,
		private storageService: StorageService,
		private store: Store<AppStore>) {
		store.subscribe(store => {
			const state = store.state;
			this.categories = state.categories;
			this.loggedInUser = state.user;
			this.cartItems = state.cartItems;
		});
	}

	ngOnInit() {
		this.searchResult = new SearchResult();
		this.isSearchActive = false;

		this.searchBoxFormControl.valueChanges          // listen to textbox value change
			//.pipe(filter(text => text.trim().length > 2)) // if length requirements met, (set in service)
			//.pipe(tap((text) => console.log(text)))
			.pipe(filter(text => typeof text === 'string')) // if user input, it's a string. if setValue(), it's an object (for now)
			.pipe(debounceTime(1000))                       // buffer multiple value changes,
			.pipe(distinctUntilChanged())                   // and only if there was a net value change
			.pipe(switchMap((searchBoxValue) => {           // cancelling any currently running requests
				this.searchResult = new SearchResult();
				this.searchMessage = null;
				this.isSearchActive = true;
				return this.catalogSearchService.performSearch(searchBoxValue, false); // perform the search (extended is FALSE since we don't need extra relations)
				// .pipe(retryWhen(this.retryService.safariRetryStrategy()));
			}))
			.subscribe({
				next: (result) => {
					this.searchResult = new SearchResult();
					this.isSearchActive = false;

					if (!result) {
						return false;
					}

					if (result.products.length < 1 && result.categories.length < 1) {
						this.searchMessage = 'NO RESULTS';
					} else {
						this.searchResult = result;
					}
          return true;
				},
				error: (error) => {
					this.searchResult = new SearchResult();
					this.searchMessage = '';
					this.isSearchActive = false;
					console.error(error);
				}
      });
	}

	onSearchBoxEnterKeyUp(event: any) {
		this.searchBoxFormControl.setValue(''); // don't perform autocomplete search if enter is pressed (caught because empty string is too short)
		this.router.navigateByUrl('search/' + event.target.value, {}).then();
	}

	get cartTotal(): number {
		let cartTotal = null;
		if (this.cartItems && this.cartItems.length) {
			cartTotal = this.cartItems.map(ci => ci.quantity)
				.reduce((ci, currentTotal) => ci + currentTotal);
		}
		return cartTotal ? cartTotal : 0;
	}

	get primaryCategories(): Category[] {
    //this function is named primary categories but it's not really returning the categories whose primaryCategory property = 1
    //it's hardcoded to just return the major categories
		return this.categories.filter(cat => cat.primaryCategory)
			// make sure the list is unique
			.filter((cat, index, self) => self.findIndex(s => s.id === cat.id) === index)
			// we only want to show major categories
			.filter((cat) => this.majorCategories.includes(cat.name.toLocaleLowerCase()))
			.sort((a, b) => a.displaySeq - b.displaySeq);
	}

	removeHover(event: any) {
		const meaningFulClick = event.path?.filter((p: Element) => p.tagName).some((p: Element) => {
			if (p.tagName.toLowerCase() === 'a') {
				return true;
			}
      return false;
		});

		if (meaningFulClick) {
			// find the menu
			const menuElement = event.path.filter((p: Element) => p.classList).find((p: Element) => {
				return p.classList.contains('menu');
			});

			if (menuElement) {
				const classList = menuElement.className;
				menuElement.classList += ' remove-hover';
				this.toggleRemoveHover(menuElement, classList);
			}
			// event.target.nativeElement.hover = false;
		}
	}

	toggleRemoveHover(menuElement: Element, classList: string) {
		setTimeout(() => {
			menuElement.className = classList;
		}, 1000)
	}

	searchSelected(event: MatAutocompleteSelectedEvent) {
		const group = event.option.group.label;
		const url = event.option.value.url;
		let urlToNavigateTo = '/';
		switch (group) {
			case 'PRODUCT MATCHES':
				urlToNavigateTo = `/product/${url}`;
				break;
			case 'RECIPE MATCHES':
				urlToNavigateTo = `/recipe/${url}`;
				break;
			case 'CATEGORY MATCHES':
				urlToNavigateTo = `/products/${url}`;
				break;
		}

		this.openInternalLink(urlToNavigateTo);
	}

	autoCompleteRenderFn(selected: any): string {
		if (selected) {
			return selected.displayName || selected.name || '';
		}
    return '';
	}

	openInternalLink(url: string) {
		this.router.navigateByUrl(url);
	}

	logout() {
		this.loginService.signOut()
			// .pipe(retryWhen(this.retryService.safariRetryStrategy()))
			.subscribe(success => {
				this.store.dispatch(new LogoutUser());
				this.storageService.setItem(StorageEnum.IS_USER_LOGGED_IN, 'false');

				this.router.navigate(['/']);
			}, error => {
				console.error(error);
			});
	}

	getCategoryChildren(category: Category): Category[] {
		if (category.categoryRelations) {
			return this.categories.filter(cat => category.categoryRelations.findIndex(cr => cr.categoryId === cat.id) > -1)
				.sort((catA, catB) => {
					const catAIndex = category.categoryRelations.findIndex(cr => cr.categoryId === catA.id);
					const catBIndex = category.categoryRelations.findIndex(cr => cr.categoryId === catB.id);

					return category.categoryRelations[catAIndex].displaySeq - category.categoryRelations[catBIndex].displaySeq;
				});
		} else {
			return [];
		}
	}

	handleLogoClick() {

		const urlToGoTo = '/';
		const currentUrl = this.router.url;

		if (currentUrl === urlToGoTo) {
			// scroll to the top of the page
/*			this.window.scrollTo({
				top: 0,
				behavior: "smooth"
			});*/
		} else {
			this.openInternalLink(urlToGoTo);
		}
	}

	triggerMobileMenu() {
		this.mobileMenuOpened = !this.mobileMenuOpened;
	}

	redirect() {
		this.router.navigate(['/']);
	}

  get showSearchMessage(): boolean {
    let searchTerm;
    if(this.searchBoxFormControl && this.searchBoxFormControl.value) {
      searchTerm = this.searchBoxFormControl.value;
    }
    if(searchTerm && searchTerm.length) {
      return searchTerm.length > 0 && searchTerm.length < 3
    }
    return false;
  }
}
