import {Inject, Injectable} from '@angular/core';
import {HttpClient}   from '@angular/common/http';
import {Account}      from '../models/account.model';
import {Observable}   from 'rxjs';
import {environment}  from '../../../environments/environment';
import { Order }      from '../models/order.model';
import {OrderItem} from "../models/order-item.model";
import {User} from "../models/user.interface";

@Injectable()
export class AccountDetailsService {

    readonly accountBaseUrl = `${environment.url}/users`;

    constructor(private http: HttpClient) {}

    updateAccount(account: Partial<User>): Observable<User> {
        return this.http.patch<User>(`${this.accountBaseUrl}/${account.id}`, account);
    } // updateUser()

    changePassword(oldPassword: string, newPassword: string, confirmNewPassword: string): Observable<boolean> {
        const body = {
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmNewPassword: confirmNewPassword
        };

        return this.http.post<boolean>(`${this.accountBaseUrl}/change-password`, body);
    }

    deleteAccount(account: User): Observable<boolean> {
      return this.http.delete<boolean>(`${this.accountBaseUrl}/${account.id}`);
    } // deleteAccount()
}
