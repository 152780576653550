import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {ContentPageService} from "../services/content-page.service";
import {ObservableInput, of} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable()
export class ContentPageResolver implements Resolve<any> {
	constructor(private contentPageService: ContentPageService, private router: Router) {}

	resolve(route: ActivatedRouteSnapshot) {
		const url = route.params['id'];
		if (!url) {
			return this.notFound();
		}
		return this.contentPageService.getContent(url).pipe(
			// retryWhen(this.retryService.safariRetryStrategy()),
			catchError(err => this.handleError(err)));
	}

	handleError(err: any): ObservableInput<{}> {
		console.error(err);
		this.notFound();
		return of(err)
	}

	async notFound() {
		await this.router.navigateByUrl('/not-found', {skipLocationChange: true});
	}
}
