import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable()
export class VerifyService {

    readonly url = `${environment.url}/users/verify`;

    constructor(private http: HttpClient) {}

    verifyCode(verificationCode: string): Observable<boolean> {
        let params = new HttpParams();
        params = params.set('code', verificationCode);

        return this.http.get<boolean>(this.url, {
            params: params
        });
    }

    verifyChangePasswordCode(newPassword: string, confirmNewPassword: string, verificationCode: string): Observable<boolean> {
        const body = {
            newPassword: newPassword,
            confirmNewPassword: confirmNewPassword,
            verificationCode: verificationCode
        };

        return this.http.post<boolean>(this.url, body);
    }
}
