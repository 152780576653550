import {Category} from './category.model';
import {Account} from './account.model';
//import {StripeCustomer} from './stripe.model';
import {TagGroup} from './tag-group.model';
import {FavoriteProduct} from './favorite-product.model';
import {Tag} from './tag.model';
import {Product} from './product.model';
import {Feature} from './feature.model';
import {ContentPage} from './content-page.model';
import {ShoppingCartItem} from './shopping-cart.model';
import {AccountFilter} from './account-filter.model';
import {Address} from './address.model';
import {CategoryViewEnum} from '../enums/category-view.enum';
import {Banner} from './banner.model';
import {User} from './user.interface';

export class State {
	user: User | null;
	categories: Category[] = [];
	cartItems: ShoppingCartItem[] = [];
	tagGroups: TagGroup[] = [];
	favoriteProducts: FavoriteProduct[] = [];
	tags: Tag[] = [];
	featuredItems: Feature[] = [];
	products: Product[] = [];
	contentPages: ContentPage[] = [];
	accountFilters: AccountFilter[] = [];
	addresses: Address[] = [];
	//    stripeCustomer: StripeCustomer;
	shippingAddress: Address | null;
	banner: Banner;
	newsletterIsDisabled: boolean;
	quantityIsDisabled: boolean;
	showStockIsDisabled: boolean;
	shippingAddressSameAsBilling: boolean | null;
	categoryView: CategoryViewEnum = CategoryViewEnum.GALLERY;
	stripePublishableKey: string;
	addr2IsDisabled: boolean;
}

export class AppStore {
	state: State;
}
