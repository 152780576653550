import { Injectable }             from '@angular/core';
import { environment }            from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of }         from 'rxjs';
import { SearchResult }           from '../models/search-result.model';

@Injectable()
export class CatalogSearchService {

    readonly baseUrl: string = `${environment.url}/catalog-search`;

    constructor(private http: HttpClient) {
    }

    performSearch(searchTerm: string | null, extendedSearch: boolean = true): Observable<SearchResult> {
      let term;
      if(searchTerm) {
        term = searchTerm.trim();
      }

      if (!term || term.length < 3) {
          return of(new SearchResult());
      }

      let params: HttpParams = new HttpParams().set('search', term);

      if (extendedSearch) {
        params = params.append('extended', 'true');
      }


      return this.http.get<SearchResult>(this.baseUrl, {params});
    }
}
