import {ErrorCode} from './error-code.config';

/**
 *
 */
export class Functions {

    /**
     * Find an error message in the given errorSource
     * We do our best here to find whatever error message might be available
     */
    public static findErrorMessage(errorSource: any): string | null {

        if (!errorSource) {
            return null;
        }

        // base message
        let errorMessage = null;

        // what sort of thing did we receive?
        // it might be:
        // -> a string that's the actual error message
        // -> an error object that has a message
        // -> an object that has a nested error object
        if (errorSource instanceof String || typeof errorSource === 'string') {
            // if we get a string here then that's the message - pass it along.
            errorMessage = errorSource;
        } else if (errorSource.message) {
            // if there's a message attached to the object we received
            // then assume that's the error message
            errorMessage = errorSource.message;
        } else {
            // if there's a nested error object then that's what we want
            const errorObject = this.findErrorObject(errorSource);

            if (errorObject && errorObject.message) {
                errorMessage = errorObject.message;
            }
        }

        return errorMessage;
    } // findErrorMessage()


    /**
     * Find an Error object within given Object
     * @param object
     */
    public static findErrorObject(object:  Record<string, any>): any {
        if (!object) {
            return null;
        }

        // the parameter object might be the error object
        // if the object has a message then assume it's an error message
        // and therefore the object is the error object
        if (object.hasOwnProperty('message')) {
            return object;
        }

        let errorObject = null;
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                const value = object[key];
                if (key === 'error' && value.message) {
                    errorObject = value;
                    // console.log('found error object');
                    break;
                } else if (value instanceof Object) {
                    errorObject = this.findErrorObject(value);
                    break;
                }
            }
        }
        return errorObject;
    } // findErrorObject()

    /**
     * Get the error code for the given error object
     * @param {string} error
     * @return {number} error code
     */
    public static getErrorCode(error: any): number {
        // console.log('getErrorCode()');

        let errorCode = ErrorCode.NO_ERROR_CODE_AVAILABLE;

        let errorObject: any = error;
        if (!error) {
            return errorCode;
        } else {
            errorObject = Functions.findErrorObject(error);
        }

        // interpret the errorObject
        if (errorObject && errorObject.hasOwnProperty('code')) {
            errorCode = errorObject.code;
        }

        return errorCode;

    } // getErrorCode()


}
