<div class="flex flex-wrap md:flex-nowrap justify-center items-stretch">
    <div class="checkout-main shipping-info flex flex-col w-full md:w-3/4" appMobileView>

        <div id="validated-address" *ngIf="fedexValidatedAddress" class="body-section flex flex-col gap-3">
            <label class="section-title">Proceed with Validated Address:</label>
                    <div class="tile-container flex flex-col">
                        <div class="tile-body flex flex-col">
                            <mat-checkbox color="primary" class="checkbox" [checked]="isAddressChosen(fedexValidatedAddress)"
                                          (change)="addressSelectionChanged($event.checked, fedexValidatedAddress)">Selected</mat-checkbox>
                            <span>{{fedexValidatedAddress.name}}</span>
                            <span>{{fedexValidatedAddress.line1}}</span>
                            <span>{{fedexValidatedAddress.line2}}</span>
                            <span>{{fedexValidatedAddress.city}}, {{fedexValidatedAddress.state}} {{fedexValidatedAddress.postalCode}}</span>
                            <span>{{fedexValidatedAddress.phone}}</span>
                        </div>
                    </div>
            <div class="or-label">
                <label class="section-title">OR</label>
            </div>
        </div>

        <div id="saved-addresses" *ngIf="loggedInUser && addresses.length > 0" class="body-section flex flex-col gap-3">
            <label class="section-title">Select a saved address:</label>
            <div id="addresses" class="flex flex-wrap gap-4">
                <div *ngFor="let savedAddress of addresses">

                    <div class="tile-container flex flex-col">
                        <div class="tile-body flex flex-col">
                            <mat-checkbox color="primary" class="checkbox" [checked]="isAddressChosen(savedAddress)"
                                          (change)="addressSelectionChanged($event.checked, savedAddress)">Selected</mat-checkbox>
                            <span>{{savedAddress.name}}</span>
                            <span>{{savedAddress.line1}}</span>
                            <span>{{savedAddress.line2}}</span>
                            <span>{{savedAddress.city}}, {{savedAddress.state}} {{savedAddress.postalCode}}</span>
                            <span>{{savedAddress.phone}}</span>
                        </div>
                        <div class="tile-buttons flex justify-evenly gap-2">
                            <button class="tile-button" (click)="editAddress(savedAddress)"><i class="fa fa-pencil"
                                                                                               aria-hidden="true"></i>
                                Edit
                            </button>
                            <button class="tile-button" (click)="removeAddress(savedAddress)"><i class="fa fa-times"
                                                                                                 aria-hidden="true"></i>
                                Remove
                            </button>
                        </div>

                        <div class="tile-footer flex justify-center">
                            <label *ngIf="savedAddress.default">Default Shipping Address</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="or-label">
                <label class="section-title">OR</label>
            </div>
        </div>

        <div id="enter-shipping-information" class="body-section flex flex-col">

            <mat-checkbox color="primary" class="checkbox" *ngIf="addresses && addresses.length > 0" [checked]="isAddressChosen(formAddress)"
                          (change)="addressSelectionChanged($event.checked, formAddress)">Selected</mat-checkbox>
            <label class="section-title mb-2">Enter your shipping information:</label>

            <div id="shipping-information-form flex flex-row flex-wrap md:flex-col md:flex-nowrap">
                <div class="shipping-form-container flex flex-col">
                    <span class="required">Name</span>
                    <mat-form-field>
                        <input matInput id="name" name="name" [(ngModel)]="formAddress.name"
                               (keydown)="addressSelectionChanged(true, formAddress)"
                               [formControl]="nameFormControl" required/>
                        <mat-error *ngIf="nameFormControl.hasError('required')">
                            <span class="hidden md:inline">Name is </span><strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="nameFormControl.hasError('pattern')">
                            Name may not contain special characters.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="shipping-form-container flex flex-col">
                    <span class="required">Address Line 1</span>
                    <mat-form-field>
                        <input matInput id="address_line1" name="address_line1"
                               [(ngModel)]="formAddress.line1"
                               (keydown)="addressSelectionChanged(true, formAddress); addressLine1FormControl.markAsTouched()"
                               [formControl]="addressLine1FormControl"/>
                        <mat-error *ngIf="addressLine1FormControl.hasError('required')">
                            <span class="hidden md:inline">Address is </span><strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="addressLine1FormControl.hasError('pattern')">
                            Address may not contain special characters.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="shipping-form-container flex flex-col" *ngIf="!addr2Disabled">
                    <span>Address Line 2</span>
                    <mat-form-field>
                        <input matInput id="address_line2" name="address_line2"
                               (keydown)="addressSelectionChanged(true, formAddress); addressLine2FormControl.markAsTouched()"
                               [(ngModel)]="formAddress.line2"
                               [formControl]="addressLine2FormControl"/>
                        <mat-error *ngIf="addressLine2FormControl.hasError('pattern')">
                            Address may not contain special characters.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="shipping-form-container flex justify-between">
                    <div class="flex flex-col w-[45%]">
                        <span class="required">City</span>
                        <mat-form-field>
                            <input matInput id="city" name="city" [(ngModel)]="formAddress.city"
                                   (keydown)="addressSelectionChanged(true, formAddress); cityFormControl.markAsTouched()"
                                   [formControl]="cityFormControl"/>
                            <mat-error *ngIf="cityFormControl.hasError('required')">
                                <span class="hidden md:inline">City is </span><strong>required</strong>
                            </mat-error>
                            <mat-error *ngIf="cityFormControl.hasError('pattern')">
                                City may not contain special characters.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="w-1/5 flex flex-col">
                        <span class="required">State</span>
                        <mat-form-field>
                            <input matInput id="state" name="state" [(ngModel)]="formAddress.state"
                                   (keydown)="addressSelectionChanged(true, formAddress); stateFormControl.markAsTouched()"
                                   (keyup)="formAddress.state = formAddress.state.toUpperCase()"
                                   [formControl]="stateFormControl"
                                   maxlength="2"/>
                            <mat-error
                                    *ngIf="stateFormControl.hasError('required') || stateFormControl.hasError('pattern')">
                                <span class="hidden md:inline">Valid US State code is </span><strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="flex flex-col w-1/5">
                        <span class="required">ZIP</span>
                        <mat-form-field>
                            <input matInput id="postal_code" name="postal_code"
                                   [(ngModel)]="formAddress.postalCode"
                                   (keydown)="addressSelectionChanged(true, formAddress); postalCodeFormControl.markAsTouched()"
                                   (keyup)="formAddress.postalCode = formAddress.postalCode.replace(' ','')"
                                   [formControl]="postalCodeFormControl"/>
                            <mat-error
                                    *ngIf="postalCodeFormControl.hasError('required') || postalCodeFormControl.hasError('pattern')">
                                <span class="hidden md:inline">Valid ZIP code is </span><strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                </div>

                <div class="shipping-form-container flex flex-col">
                    <span class="required">Phone Number</span>
                    <mat-form-field>
                        <input matInput id="address_phone" name="address_phone"
                               [(ngModel)]="formAddress.phone"
                               (keydown)="addressSelectionChanged(true, formAddress); phoneNumberFormControl.markAsTouched()"
                               (keyup)="formAddress.phone = formAddress.phone.replace(' ','')"
                               [formControl]="phoneNumberFormControl"/>
                        <mat-error *ngIf="phoneNumberFormControl.hasError('required') || phoneNumberFormControl.hasError('pattern')">
                            <span class="hidden md:inline">Valid 10-digit Phone Number is </span><strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="shipping-form-container flex flex-col gap-3">
                    <mat-checkbox color="primary" class="checkbox" *ngIf="loggedInUser" [(ngModel)]="shouldSaveAddress">Save this address.
                    </mat-checkbox>
                    <mat-checkbox color="primary" class="checkbox" *ngIf="loggedInUser" [(ngModel)]="formAddress.default">Make this my default address.
                    </mat-checkbox>
                </div>

                    <label>
                        Grow Generation products are subject to US Export rules known as Export Administration Regulations (EAR) and enforced by U.S. Department of Commerce <a href="https://www.bis.doc.gov/index.php/regulations/export-administration-regulations-ear" target="_new" >Bureau of Industry and Security</a> (BIS).

                        </label>
            </div>

        </div>

    </div>
    <div class="flex flex-col w-full md:w-1/4">
        <div class="checkout-grey-box" appMobileView>
            <app-checkout-summary [orderArray]="orderArray"></app-checkout-summary>
            <div class="checkout-sidebar-section action flex flex-col justify-center">
                <mat-checkbox color="primary" class="checkbox terms" [(ngModel)]="orderAgreementAccepted">I understand that items on this site are sold and shipped only to customers located within the United States.
                </mat-checkbox>
                <div matTooltip="Please accept the terms and conditions to continue." [matTooltipDisabled]="orderAgreementAccepted">
                    <button mat-button class="checkout-button" (click)="next()" [disabled]="!validateAddressInputs() || !orderAgreementAccepted">Continue
                    </button>
                </div>

            </div>
        </div>

        <div class="checkout-grey-box" appMobileView>
            <div class="promo-container flex justify-center gap-4">
                <div class="flex flex-col justify-center">
                    <label class="promo-active" *ngIf="orderArray&& orderArray[0] && orderArray[0].promoCode">Promo Code {{orderArray[0].promoCode}} is currently active. Want to use a different one?</label>
                    <label class="promo-active" *ngIf="!orderArray|| !orderArray[0] || !orderArray[0].promoCode">Have a promo code? </label>
                    <label class="promo-active mb-2">Please go back to Shopping Cart to apply it.</label>
                    <button mat-flat-button color="accent" (click)="return()">Return To Cart</button>
                </div>

            </div>
        </div>

        <div class="checkout-grey-box checkout-help-box flex flex-col" appMobileView >
            <label class="checkout-summary-title">Need Assistance?</label>
            <div class="flex flex-col gap-2">
              <label>Email:<br>
                <a class="checkout-email checkout-assistance" href="mailto:Humveeparts@amgeneral.com">Humveeparts@amgeneral.com</a></label>
                <label>Phone:<br>
                    <a class="checkout-email checkout-assistance" href="tel:800-348-8833">(800) 348-6833</a></label>
            </div>
        </div>
    </div>
</div>
