import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, Params} from "@angular/router";
import {Store} from "@ngrx/store";
import {SeoService} from "../core/services/seo.service";
import {AppStore} from "../core/models/state.model";
import {ContentPage} from "../core/models/content-page.model";
import {UpdateContentPages} from "../core/actions/state.actions";

@Component({
	selector: 'app-content-page',
	templateUrl: './content-page.component.html',
	styleUrls: ['./content-page.component.scss']
})
export class ContentPageComponent implements OnInit {

	// contentPage: ContentPage = new ContentPage();
	contentPages: ContentPage[];
	contentPageUrl: string;

	constructor(private route: ActivatedRoute,
				private router: Router,
				private store: Store<AppStore>,
				private seoService: SeoService) {
		store.subscribe(store => {
			const state = store.state;
			this.contentPages = state.contentPages;
		});
	}

	ngOnInit() {
		this.route.params.subscribe((params: Params) => {
			this.contentPageUrl = params['id'];
			if (!this.contentPageUrl) {
				this.notFound();
			} else {
				this.getContentPageById();
			}
		});
	}

	notFound() {
		this.router.navigateByUrl('/not-found', {skipLocationChange: true});
	}

	get contentPage(): ContentPage {
		let contentPage = null;

		if (this.contentPages) {
			contentPage = this.contentPages.filter(content => content?.url)
				.find(content => content?.url === this.contentPageUrl);
		}

		return contentPage ? contentPage : new ContentPage();
	}


	getContentPageById() {
		this.route.data.subscribe(resolve => {
			const response = resolve['resolve'];
			if (!response) {
				this.router.navigate(['/not-found'], {skipLocationChange: true});
			} else {
				const title = `${response.title}`
				this.seoService.setTitle(title);
				this.seoService.setMetaTitle(title);
				this.seoService.setMetaDescription(response.content.replace( /(<([^>]+)>)/ig, ''));
				this.seoService.setMetaKeywords(`Content ${title}`);
				this.store.dispatch(new UpdateContentPages([response]));
			}

		}, error => {
			console.error(error);
		});
	}

}
