import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-check-email',
    templateUrl: './check-email.component.html',
    styleUrls: ['./check-email.component.scss']
})
export class CheckEmailComponent implements OnInit {
    title: string;
    subText: string;

    textOptions: any[] = [
        {
            title: 'Thank you for signing up to our website!',
            subText: `An email will be sent to your account shortly.
                    Please follow the instructions in the email to complete your account registration.
                    If you have not received an email, make sure to check your spam folder.`
        },
        {
            title: 'Reset Password',
            subText: `An email will be sent to your account shortly.
                    Please follow the instructions in the email to reset your password.
                    If you have not received an email, make sure to check your spam folder.`
        }
    ];

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            let opt = params['option'];
            this.title = this.textOptions[opt].title;
            this.subText = this.textOptions[opt].subText;
        });
    }
}
