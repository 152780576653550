<div class="flex flex-col md:mobile-view" id="category">
  <div *ngIf="category?.primaryCategory" class="category-header primary" [ngClass]="longDescription ? 'extratext' : ''" appMobileView>
    <div class="header-description">
      <h2 class="category-title">{{category.name | titlecase}}</h2>
      <label class="header-label" [innerHtml]="category.description"></label>
    </div>
    <img class="header-image flex-1" *ngIf="category?.image?.url && !imageNotFound" [src]="category.image.url" (error)="imageError()" appMobileView>
  </div>

  <div class="bread-crumb-options " *ngIf="!category?.primaryCategory" appMobileView>
    <div class="hidden md:flex items-center justify-between p-2 border-bottom">
      <div class="flex gap-2">
        <mat-icon svgIcon="grid-grey" class="not-active" aria-hidden="true" (click)="changeView(CategoryViewEnum.GALLERY)" *ngIf="currentView !== CategoryViewEnum.GALLERY"></mat-icon>
        <mat-icon svgIcon="grid" class="active" *ngIf="currentView === CategoryViewEnum.GALLERY" aria-hidden="true"></mat-icon>
        <mat-icon svgIcon="list-grey" class="not-active" aria-hidden="true" (click)="changeView(CategoryViewEnum.LIST)" *ngIf="currentView !== CategoryViewEnum.LIST"></mat-icon>
        <mat-icon svgIcon="list" class="active" *ngIf="currentView === CategoryViewEnum.LIST" aria-hidden="true"></mat-icon>
<!--        <div class="category-header-divider"></div>-->
<!--        <app-breadcrumbs fxShow="true" fxShow.lt-md="false" [hideBase]="!true" [type]="breadCrumbType" [relatedId]="category?.id" [description]="breadcrumbTitle" [breadcrumbs]="category?.breadcrumbs"></app-breadcrumbs>-->
<!--        <app-breadcrumbs fxShow="false" fxShow.lt-md="true" [hideBase]="true" [type]="breadCrumbType" [relatedId]="category?.id" [description]="breadcrumbTitle" [breadcrumbs]="category?.breadcrumbs"></app-breadcrumbs>-->
      </div>
      <img class="header-image" *ngIf=" category.breadcrumbs?.length && headerImageUrl && !imageNotFound" [src]="headerImageUrl" (error)="imageError()">
    </div>
    <div class="mobile-options flex items-center justify-around mobile-view md:hidden w-full " >
      <mat-icon svgIcon="grid-grey" class="not-active" aria-hidden="true" (click)="changeView(CategoryViewEnum.GALLERY)" *ngIf="currentView !== CategoryViewEnum.GALLERY"></mat-icon>
      <mat-icon svgIcon="grid" class="active" *ngIf="currentView === CategoryViewEnum.GALLERY" aria-hidden="true"></mat-icon>
      <mat-icon svgIcon="list-grey" class="not-active" aria-hidden="true" (click)="changeView(CategoryViewEnum.LIST)" *ngIf="currentView !== CategoryViewEnum.LIST"></mat-icon>
      <mat-icon svgIcon="list" class="active" *ngIf="currentView === CategoryViewEnum.LIST" aria-hidden="true"></mat-icon>
      <mat-form-field class="sort-by w-1/4" subscriptSizing="dynamic">
        <mat-select [(ngModel)]="sortOrder" [panelClass]="{'select-panel': !sortOrder}" placeholder="SORT">
          <mat-option *ngIf="sortOrder" [value]="null">Reset Sorting</mat-option>
          <mat-option *ngFor="let option of sortOptions" [value]="option">{{option}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button class="white-button mobile-filters mat-form-field-flex" *ngIf="filterGroups.length > 0" (click)="openFilterDialog()">
        <div class="mat-form-field-infix">
          FILTERS
          <mat-icon >arrow_drop_down</mat-icon>
        </div>
      </button>
    </div>
  </div>

  <div *ngIf="category?.primaryCategory; then showCategories else showProducts"></div>

  <ng-template #showCategories>
    <div class="categories columns-1 sm:columns-2 md:columns-3 p-2">
       <app-sub-category *ngFor="let subCategory of subCategories" [subCategory]="subCategory" [ngClass]="{'no-image':!subCategory.image}"></app-sub-category>
    </div>
  </ng-template>

  <ng-template #showProducts>
    <div class="flex">
      <div class="category-left flex flex-col w-1/2 sm:w-auto hidden md:block " *ngIf="filterGroups.length > 0">
        <div class="sort-section flex gap-4">
          <label class="sort-header" fxFlexAlign="center">SORT</label>
          <mat-form-field class="sort-by" subscriptSizing="dynamic">
            <mat-select [(ngModel)]="sortOrder" [panelClass]="{'select-panel': !sortOrder}" placeholder="Default">
              <mat-option *ngIf="sortOrder" [value]="null">Reset Sorting</mat-option>
              <mat-option *ngFor="let option of sortOptions" [value]="option">{{option}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex flex-col sideview-container" *ngIf="category?.primaryCategory && subCategories.length > 0">
          <div class="filter-header">
            <label>SUBCATEGORIES</label>
          </div>
          <div class="subcategories uppercase flex flex-col gap-2">
            <a *ngFor="let subCategory of subCategories" class="subcategory" [routerLink]="'/products/' + subCategory.url">{{subCategory.name}}</a>
          </div>
        </div>
        <div class="flex flex-col sideview-container" *ngFor="let filterGroup of filterGroups">
          <div class="filters flex flex-col">
            <div class="flex flex-col" >
              <div class="filter-header">
                <label>Filter by {{filterGroup.title}}</label>
              </div>
              <div class="checkbox-container flex flex-col" *ngFor="let filter of filterGroup.tags">
                <!-- <mat-checkbox class="checkbox" [checked]="isFilterSelected(filter)" (change)="filterIsSelected(filter)" [appTooltip]="filter.description"> -->
                <mat-checkbox class="checkbox" [checked]="isFilterSelected(filter)" (change)="filterIsSelected(filter)" >
                  {{filter.name}}
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="currentView === CategoryViewEnum.GALLERY; then showGallery else showList"></div>

      <ng-template #showGallery>
        <div class="gallery-view flex flex-wrap" appMobileView>
           <app-product-card-gallery class="flex-grow-0 flex-shrink-0 w-1/2 md:w-1/3" *ngFor="let product of filteredAndSortedProducts | slice:0:endDisplay; let index = index" [ngClass]="{'bottom': index > bottomRow(3)}" [product]="product"></app-product-card-gallery>
        </div>
      </ng-template>

      <ng-template #showList>
        <div class="list-view flex flex-1 flex-col" appMobileView>
           <app-product-card-list *ngFor="let product of filteredAndSortedProducts | slice:0:endDisplay" [product]="product"></app-product-card-list>
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
