<div
	class="image-container flex flex-wrap w-full"
	(swipeleft)="startAnimation('left'); clearTimeOut()"
	(swiperight)="startAnimation('right'); clearTimeOut()"
	appMobileView
>
	<div
		[@swipeChange]="animationState"
		(@swipeChange.done)="resetAnimationState()"
		[@completedAnimationState]="completedAnimationState"
		(@completedAnimationState.done)="resetCompletedAnimationState()"
		(click)="goToRelated()"
		[ngClass]="{clickable: link}"
    class="flex flex-wrap w-full"
	>
		<img
			class="carousel-image"
			id="carouselImage"
			appMobileView
			[src]="chosenItem.imageFilename"
			*ngIf="chosenItem && !imageNotFound[chosenItem.id]"
			(error)="imageError(chosenItem)"
			[ngStyle]="{height: 'auto'}"
		/>
		<img
			class="carousel-image"
			appMobileView
			alt="not found image"
			src="../../../assets/image-not-found.500.jpg"
			*ngIf="!chosenItem || imageNotFound[chosenItem.id]"
			(error)="imageError(chosenItem)"
		/>
<!--		<div class="carousel-content" appMobileView >-->
<!--      <p class="carousel-title">{{chosenItem?.title ?? ''}}</p>-->
<!--      <span class="carousel-detail" [innerHtml]="unescapeHtml(chosenItem?.description ?? '') | safeHtml"></span>-->
<!--      <button>{{'Shop ' + chosenItem?.title}}</button>-->
<!--    </div>-->
	</div>
	<div class="image-button-containers hidden md:flex justify-center absolute top-[-130px] left-[20px] md:left-1/2 md:right-1/2 md:bottom-[20px] md:top-[95%]">
		<div class="flex gap-2">
			<div
				*ngFor="let item of carouselItems; let index = index"
				class="item-box"
				[ngClass]="{active: item.id === chosenItem?.id}"
				(click)="chooseItem(index)"
			></div>
		</div>
	</div>
	<!--Preload Carousel Images-->
	<div id="imageLoad" style="display: none">
		<div *ngFor="let item of carouselItems; let index = index" class="carousel-image">
			<img class="carousel-image" appMobileView [src]="item.imageFilename" />
		</div>
	</div>
</div>
