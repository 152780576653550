import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import {OrderService} from '../core/services/order.service';
import {ErrorService} from '../core/services/error.service';
import {OrderHistory, OrderHistoryItem} from '../core/models/order-history.model';
import {AddToCartEnum} from '../core/enums/add-to-cart.enum';
import {Product} from '../core/models/product.model';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ProductReviewWriteComponent} from "./product-review-write/product-review-write.component";
import {SnackbarActionEnum} from "../core/enums/snackbar-action.enum";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
	selector: 'app-order-history',
	templateUrl: './order-history.component.html',
	styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {

	constructor(
    private orderService: OrderService,
		private route: ActivatedRoute,
		private errorService: ErrorService,
		private router: Router,
    private dialog: MatDialog,
    private snackbar: MatSnackBar) {
	}

	orders: OrderHistory[] = [];
	//orderItems: OrderItem[] = [];
	loading: boolean = true;
	addToCartButton: AddToCartEnum = AddToCartEnum.BUY_IT_AGAIN_BUTTON;
	dateFilter: { title: string, startDate: string, endDate: string };
	dateFilters: { title: string, startDate: string, endDate: string }[] = [];

	error = 'Sorry, we could not locate your order history. Please try again later.';

	ngOnInit() {
		this.getOrderHistory();
	}

  openWriteReviewModal(event: Event, orderedProduct: OrderHistoryItem){
    event.preventDefault();
    event.stopPropagation();
   const dialogRef = this.dialog.open(ProductReviewWriteComponent)
    dialogRef.componentInstance.orderedProduct = orderedProduct;
    dialogRef.componentInstance.userId = this.orders[0].userId;

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.snackbar.open('Review submitted. Thanks for your feedback!', SnackbarActionEnum.SUCCESS)
      }
    })
  }

	getOrderHistory() {
		this.route.data.subscribe(resolve => {
			const response = resolve['resolve'];
			this.loading = false;
			if (!response) {
				this.errorService.showErrorMessage(this.error);
				return;
			}
			this.orders = response as OrderHistory[];
			//this.orderItems = next.result.orderItems;
			this.buildDateFilters();
		},
			(error: any) => {
				this.loading = false;
				this.errorService.showErrorMessage(this.error);
			}
		);
	}

	buildFormatDate(date: Date): string {
		return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
	}

	buildDateFilters() {
		this.dateFilters = [];

		// the first one I always want to the be with in the last 30 days
    const { add, sub } = require("date-fns");
    const today = add(new Date(), {days: 1});

		const thirtyDaysAgo = sub(today, {days: 31});
		this.dateFilter = {
			title: 'last 30 days',
			startDate: this.buildFormatDate(thirtyDaysAgo),
			endDate: this.buildFormatDate(today)
		};
		this.dateFilters.push(this.dateFilter);

		// build a list of dates
		// convert everything into the year
		const orderDates = this.orders.map(order => new Date(order.orderDate).getFullYear())
			// filter out duplicates
			.filter((date, index, self) => self.indexOf(date) === index)
			// sort the years
			.sort((a, b) => a - b);

		// loop over each and add them to the date filters
		orderDates.forEach(date => {
			this.dateFilters.push({
				title: date.toString(),
				startDate: `01/01/${date}`,
				endDate: `12/31/${date}`
			});
		});


	}

	get filteredOrders(): OrderHistory[] {
		return this.orders.filter(order => {
			var from = Date.parse(this.dateFilter.startDate);
			var to = Date.parse(this.dateFilter.endDate);
			var check = Date.parse(order.orderDate);

			return check <= to && check >= from;
		});
	}

  getItemAsProduct(item: OrderHistoryItem): Product {
    return item as unknown as Product;
  }

  getAddToCartDisabled(item: OrderHistoryItem): boolean {
    return item?.quantityAvailable <= 0 || item?.price <= 0;
  }

	getOrderStatusName(orderStatusCode: string): string {
		switch (orderStatusCode) {
			case 'SHIPPING_METHOD_PENDING':
				return 'Shipping Method Pending';
			case 'FTP_ORDER_ACCEPTED':
				return 'Order Accepted';
			case 'FTP_FILE_SENT':
				return 'Order Sent';
			case 'FTP_ORD_ACK_RECEIVED':
				return 'Order Received';
			case 'FTP_SHIP_RECEIVED':
				return 'Shipping Info Received';
			case 'FTP_INV_ACK_RECEIVED':
				return 'Inv Acknowledgement Received';

			default:
				return orderStatusCode;
		}
	}

	// getOrderItems(order: Order): OrderItem[] {
	// 	return this.orderItems.filter(item => item.orderId === order.id);
	// }

	showTrackingHistory(url: string) {
		// this.window.open(url, '_blank');
	}

	// goToLink(event, item) {
	// 	this.router.navigateByUrl(`/product/${item.url}`);
	// }
  protected readonly Product = Product;
}
