import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageComponent } from './landing-page.component';
import {SharedModule} from "../shared/shared.module";
import {CarouselComponent} from "./carousel/carousel.component";
import {RouterModule} from "@angular/router";
import {LandingPageService} from "../core/services/landing-page.service";
import {NewsLetterService} from "../core/services/news-letter.service";
import {FeaturedItemComponent} from "./featured-item/featured-item.component";

@NgModule({
  declarations: [
    LandingPageComponent,
    CarouselComponent,
    FeaturedItemComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  providers: [
    LandingPageService,
    NewsLetterService
  ]
})
export class LandingPageModule { }
