import {Image} from './image.model';
import {CategoryRelation} from './category-relation.model';
import {ProductCategory} from './product-category.model';
import {Product} from "./product.model";

export class Category {
    id: number;
    url: string;
    name: string;
    metaTitle: string;
    metaDescription: string;
    metaKeyword: string;
    displaySeq: number;
    primaryCategory: boolean;
    categoryRelations: CategoryRelation[];
    productCategories: ProductCategory[];
    image: Image;
    active: boolean;
    deleted: boolean;
    searchImages: Image[];
    breadcrumbs: Category[];
    description?: string;
    products?: Product[];
}
