import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-category-sub-categories',
  templateUrl: './mobile-category-sub-categories.component.html',
  styleUrls: ['./mobile-category-sub-categories.component.scss']
})
export class MobileCategorySubCategoriesComponent {

}
