export class Address {
  constructor() {
    this.default = false;
  }
    id: number;
    userId: number;
    name: string;
    careOf: string;
    line1: string;
    line2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    phone: string;
    email: string;
    default: boolean;
    isValidatedAddress: boolean;
    isFormAddress: boolean;
}

