import { Injectable }  from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient }  from '@angular/common/http';
import { Observable }  from 'rxjs';
import { Product }     from '../models/product.model';

@Injectable()
export class ProductsService {

  readonly baseUrl = `${environment.url}/product`;

  constructor(private http: HttpClient) {
  }

  getProduct(url: string): Observable<Product> {
    return this.http.get<Product>(`${this.baseUrl}/get-by-url?url=${url}`);
  }

}
