import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ShoppingCartItem} from "../models/shopping-cart.model";

@Injectable()
export class PromotionsService {
  readonly baseUrl = `${environment.url}/api/promotion`;

  constructor(private http: HttpClient) {
  }

  getPromotion(promotionCode: string): Observable<{ success: boolean, message: string[], result: ShoppingCartItem[] }> {
    return this.http.get<{ success: boolean, message: string[], result: ShoppingCartItem[] }>(`${this.baseUrl}/${promotionCode}`);
  }
}
