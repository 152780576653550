import {Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID, signal, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {
  PaymentIntent, PaymentIntentResult,
  StripeElementsOptionsClientSecret,
  StripePaymentElementChangeEvent
} from '@stripe/stripe-js';
import {injectStripe, StripePaymentElementComponent } from 'ngx-stripe';
import {
	StripeAddress,
	StripeCustomer,
	StripeCustomerInput,
	StripeCustomerSourceInput,
	StripeSource,
	StripeSourceData
} from '../../core/models/stripe.model';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {AccountDetailsService} from '../../core/services/account-details.service';
import {Router} from '@angular/router';
import {AppStore} from '../../core/models/state.model';
import {Store} from '@ngrx/store';
import {Account} from '../../core/models/account.model';
// import { UpdateSourceDialogComponent } from '../../account-details/dialogs/update-source.dialog.component';
// import { UpdateStripeCustomer } from '../../core/actions/state.actions';
import { ConfirmationDialogComponent } from "../../shared/components/confirmation-dialog/confirmation-dialog.component";
import { MessageDialogComponent } from '../../shared/components/message-dialog/message-dialog.component';
import { Order } from '../../core/models/order.model';
import { OrderService } from '../../core/services/order.service';
import { ShoppingCartItem } from '../../core/models/shopping-cart.model';
import { CartService } from '../../core/services/cart.service';
import { Functions } from '../../shared/config/functions.config';
import { PaymentService } from '../../core/services/payment.service';
import { Constants } from '../../shared/config/constants.config';
import { ErrorCode } from '../../shared/config/error-code.config';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { PaymentProcessingDialogComponent } from './dialogs/payment-processing.dialog.component';
import {User} from "../../core/models/user.interface";
import {PromotionTypeEnum} from '../../core/enums/promotion-type.enum';
import {Observable, take, tap} from "rxjs";
@Component({
	selector: 'app-payment-options',
	templateUrl: './payment-options.component.html',
	styleUrls: ['./payment-options.component.scss']
})

/**
 * PaymentOptionsComponent
 */
export class PaymentOptionsComponent implements OnInit {
  @ViewChild(StripePaymentElementComponent)
  paymentElement!: StripePaymentElementComponent;
	@Output() completed = new EventEmitter();
	@Output() returning = new EventEmitter();

	orderArray: Order[];

    loggedInUser: User | null;
    stripeCustomer: StripeCustomer | null = null;
    saveNewPaymentMethod: boolean;
    stripeSourceData: StripeSourceData = new StripeSourceData();
    paymentSource: StripeSource | null;
    chosenPaymentMethod: any;

	newCreditCard = 'new-credit-card';
	savedCreditCard = 'saved-credit-card';
	googlePay = 'google-pay';
	applePay = 'apple-pay';
	paymentMethodType: string = this.newCreditCard;
	paymentMethodTypeNewCreditCard: boolean = true;
	paymentMethodTypeSavedCreditCard: boolean = false;
	paymentMethodTypeGooglePay: boolean = false;
	paymentMethodTypeApplePay: boolean = false;
	orderPartiallyProcessed: boolean = false;

	stripe = injectStripe();
	elementsOptions: StripeElementsOptionsClientSecret = {
    locale: 'en'
	};

	googlePayAvailable: boolean = false;
	applePayAvailable: boolean = false;
	error: string;
	submitButton: HTMLButtonElement;
	paymentMethodErrorMessage =
		'There is a problem with the Payment Method you entered.' +
		' Please contact the Payment Method vendor for more information.' +
		' You can enter a different Payment Method to proceed now.';

	defaultErrorMessage = 'There was an error processing your order. Please correct any errrors and try again';
	emailFormControl = new FormControl('', [Validators.required, Validators.pattern(Constants.emailRegex)]);
	processingDialogRef: MatDialogRef<PaymentProcessingDialogComponent>;
  paying = signal(false);
  validStripe = signal(false);
  stripePaymentIntentSuccess = signal(false);

	constructor(
		// private stripeService: StripeService,
		private fb: FormBuilder,
		private paymentService: PaymentService,
		private accountDetailsSerice: AccountDetailsService,
		private snackBar: MatSnackBar,
		private router: Router,
		private store: Store<AppStore>,
		private dialog: MatDialog,
		private orderService: OrderService,
		private cartService: CartService,
		@Inject(PLATFORM_ID) private platformId: any,
		@Inject(DOCUMENT) private document: any
	) {
		store.subscribe((appStore) => {
			const state = appStore.state;
			this.loggedInUser = state.user;
			if (state.shippingAddressSameAsBilling && this.stripeCustomer === null) {
				// use first order in the array as representative
				const order = this.orderArray[0];
			}
		});
	}

	ngOnInit() {
		// capture reference to submit button
		this.submitButton = <HTMLButtonElement>this.document.getElementById('submitButton');

		// orderArray needs to be reset here to avoid confusion for the summary display
		this.orderArray = [];

		this.orderService.get()
		    .subscribe(response => {
		        this.orderArray = response;
            //create payment intent in Stripe
            this.createPaymentIntent();
		    }, error => {
		        console.error(error);
		    });

    //FIXME when stripe customer stuff is figured out
		// initialize the email address
		if (this.loggedInUser && this.loggedInUser.email) {
			// this.stripeSourceData.owner.email = this.loggedInUser.email;
		}
		// fetch the Stripe Customer
		// this.getStripeCustomer();
	}

  createPaymentIntent(): void {
    this.paymentService
      .createPaymentIntent(this.elementsOptions, parseFloat(this.orderArray[0].orderTotal.toFixed(2)))
      .pipe(
        tap((response: PaymentIntent) => {
          if (response.client_secret) {
            this.elementsOptions.clientSecret = response.client_secret;
            this.stripePaymentIntentSuccess.set(true);
          }
        }),
        take(1)
      )
      .subscribe();
  }


  validateStripe(event: StripePaymentElementChangeEvent) {
    this.validStripe.set(event.complete)
  }

  validateInputs() {
    return this.validStripe() && this.emailFormControl.status === 'VALID';
  }

  pay() {
    //piece from onSubmit()
    this.submitButton.disabled = true;
    this.submitButton.textContent = 'Processing Payment…';
    this.processingDialogRef = this.dialog.open(PaymentProcessingDialogComponent, {
      width: '40%',
      disableClose: true
    });

    this.paying.set(true);
    this.stripe
      .confirmPayment({
        elements: this.paymentElement.elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: this.orderArray[0].shipToName,
              email: this.orderArray[0].shipToEmail,
              address: {
                line1: this.orderArray[0].shipToAddress1,
                postal_code: this.orderArray[0].shipToZip,
                city: this.orderArray[0].shipToCity,
              },
            },
          },
        },
        redirect: 'if_required',
      })
      .pipe(take(1))
      .subscribe({
        next: (stripePaymentResponse: any) => {
          //the argument here is of type PaymentIntentResponse, but throws errors

          if (stripePaymentResponse.error) {
          console.error(stripePaymentResponse.error)
          } else if (stripePaymentResponse.paymentIntent.status === 'succeeded') {
            //update stripe source info on order object (convert amount and date)
            this.orderArray[0] = {
              ...this.orderArray[0],
              stripeSourceId: stripePaymentResponse.paymentIntent.payment_method,
              stripeChargeId: stripePaymentResponse.paymentIntent.id,
              stripeCapturedAmount: stripePaymentResponse.paymentIntent.amount / 100,
              stripeCapturedTs: new Date(stripePaymentResponse.paymentIntent.created * 1000),
              billToEmail: this.emailFormControl.value ?? ''
            }
            this.queueOrder(this.orderArray[0], '');
          }
        },
        error: (err) => {
          this.paying.set(false);
          console.log(err)
        },
      });
  }


	/**
	 * Initialize the Payment Request(s)
	 * @requires this.orderArray be initialized
	 * @requires this.elements be initialized
	 * @param {Order} order
	 */
	initializePaymentRequest(order: Order) {
    //new version of this is createPaymentIntent()

		// amount is in the currency subunits (cents)
		// round to get rid of tiny fractions
		const amount = Math.round(order.orderTotal * 100);

		const paymentRequestOptions = {
			country: 'US',
			currency: 'usd',
			total: {
				label: 'Demo total',
				amount: amount
			},
			requestPayerEmail: true
		};

		// this.stripeService.paymentRequest(paymentRequestOptions)
		//     .subscribe(paymentRequest => {
		//
		//         // Check the availability of the Payment Request API first.
		//         paymentRequest.canMakePayment().then(result => this.handleCanMakePaymentResult(result, order, paymentRequest));
		//
		//         // process the order with the paymentRequest token
		//         paymentRequest.on('token', paymentResponse => this.handlePaymentResponse(paymentResponse, order));
		//
		//     });
	} // initializePaymentRequest()


	// handleCanMakePaymentResult(result: any, order: Order, paymentRequest: any) {
	// 	const paymentRequestButtonId = this.getPaymentRequestButtonId(order);
	// 	const button: HTMLButtonElement = <HTMLButtonElement>this.document.getElementById(paymentRequestButtonId);
  //
	// 	if (result) {
	// 		console.log('paymentRequest.canMakePayment() = true');
	// 		if (result.applePay) {
	// 			console.log('applePayAvailable = true');
	// 			this.applePayAvailable = true;
	// 		} else {
	// 			console.log('googlePayAvailable = true');
	// 			this.googlePayAvailable = true;
	// 		}
	// 		button.disabled = false;
	// 		button.onclick = function () {
	// 			paymentRequest.show();
	// 		};
	// 	} else {
	// 		console.log('paymentRequest.canMakePayment() = false');
	// 		button.disabled = true;
	// 		// this.document.getElementById(paymentRequestButtonId).style.display = 'none';
	// 	}
  //
	// 	// this.logObject('canMakePaymentResult', result);
	// } // handleCanMakePaymentResult()

	/**
	 *
	 * @param {PaymentResponse} paymentResponse
	 * @param {Order} order
	 */
	handlePaymentResponse(paymentResponse: any, order: Order) {
		// console.log('handlePaymentResponse() for orderId: ' + order.id);

		// queue the order for processing
		// this.queueOrder(order, paymentResponse.token.id, null);

		// set flag that order is partially processed - controls presentation of options
		this.orderPartiallyProcessed = true;

		// clear the payment request window
		paymentResponse.complete('success');

		// disable the button that was attached to this request
		const paymentRequestButtonId = this.getPaymentRequestButtonId(order);
		const button: HTMLButtonElement = <HTMLButtonElement>this.document.getElementById(paymentRequestButtonId);
		button.disabled = true;

		// check to see if there are any more orders
		// for which to create a payment request
		let alreadyFoundUnqueuedOrder: boolean = false; // this functions as a break statement
		this.orderArray.forEach((orderBeingChecked) => {
			// if this order does not have a stripe source
			// then it has not been queued
			console.log('handlePaymentResponse(): checking queued status orderId: ' + orderBeingChecked.id);
			const thisOrderNotQueued: boolean = !orderBeingChecked.stripeSourceId || orderBeingChecked.stripeSourceId === '';
			console.log(
				'handlePaymentResponse(): checking queued status orderId: ' + orderBeingChecked.id + ': thisOrderNotQueued = ' + thisOrderNotQueued
			);
			if (thisOrderNotQueued && !alreadyFoundUnqueuedOrder) {
				console.log('handlePaymentResponse(): creating new PaymentRequest for orderId: ' + orderBeingChecked.id);

				// make a paymentRequest for the first order we find that has not been queued
				this.initializePaymentRequest(orderBeingChecked);

				// tell the user to complete the new payment request
				// this.displayMessage('Please complete the payment for your next order. ' +
				//     ' Your orders will not be submitted until all payments are completed.');

				// break here - will come back for other un-queued orders
				alreadyFoundUnqueuedOrder = true;
			}
		});

		console.log('returning from handlePaymentResponse()');
	} // handlePaymentResponse()

	/**
	 * Open a dialog to Update the given Stripe Source
	 * @param {Source} stripeSource
	 */
	updateSource(stripeSource: any) {
		// const dialogRef = this.dialog.open(UpdateSourceDialogComponent, {
		//     data: { ...stripeSource },
		//     width: '50%'
		// });
		// dialogRef.afterClosed().subscribe(updatedStripeSource => {
		//     if (!updatedStripeSource) {
		//         return;
		//     }
		// });
	} // updateSource()

	/**
	 * Detach the given Source from the Stripe Customer
	 * This effectively "deletes" the source
	 * @param {Source} source
	 */
	detachSource(source: any) {
		// this.showDeleteConfirmation().subscribe(confirmationResult => {
		//     if (confirmationResult) {
		//         const stripeCustomerSourceInput: StripeCustomerSourceInput = {
		//             sourceId: source.id,
		//             customerId: this.loggedInUser.stripeId
		//         };
		//
		//         this.paymentService.detachSourceFromCustomer(stripeCustomerSourceInput)
		//             // .pipe(retryWhen(this.retryService.safariRetryStrategy()))
		//             .subscribe(result => {
		//                 if (result.success) {
		//                     // update the local model
		//                     const index = this.stripeCustomer.sources.data.findIndex(thisSource => thisSource.id === source.id);
		//                     this.stripeCustomer.sources.data.splice(index, 1);
		//
		//                     const stripeCustomer: StripeCustomer = result.result;
		//                     // this.store.dispatch(new UpdateStripeCustomer(stripeCustomer));
		//                 } else {
		//                     this.displayError(result);
		//                     return false;
		//                 }
		//
		//             }, error => {
		//                 this.displayError(error);
		//                 return false;
		//             });
		//     }
		// });
	} // detachSource()

	/**
	 * The Selected StipeSource has changed
	 * @param {boolean} checked
	 * @param {StripeSource} stripeSource
	 */
	sourceSelectionChanged(checked: boolean, stripeSource: StripeSource | StripeSourceData) {
		console.log('sourceSelectionChanged(): checked = ' + checked + '; stripeSource.id = ' + (<StripeSource>stripeSource).id);
		if (checked) {
			this.chosenPaymentMethod = stripeSource;
		} else {
			// this.chosenPaymentMethod = null;
		}
	} // sourceSelectionChanged()

	/**
	 *
	 * @param {StripeSource | StripeSourceData} source
	 * @returns {boolean}
	 */
	isSourceSelected(source: StripeSource | StripeSourceData) {
		return source === this.chosenPaymentMethod;
	} // isSourceSelected()

	/**
	 * Validate inputs to control enabled state of submit button
	 */
	// validateInputs() {
	// 	let validated = false;

		// if they have selected a saved payment method then proceed
		// if (this.stripeSourceData.owner.email
		//     && this.chosenPaymentMethod && this.chosenPaymentMethod.hasOwnProperty('id')
		//     && (<StripeSource>this.chosenPaymentMethod).id
		//     && (<StripeSource>this.chosenPaymentMethod).id !== '') {
		//     validated = true;
		// } else {
		//     if (this.stripeSourceData.owner.email
		//         && this.document.getElementById('card-element').getAttribute('cardInputIsValid') === 'true'
		//     ) {
		//         validated = true;
		//     }
		// }

		// return validated;
	// } // validateInputs()

	/**
	 * onSubmit
	 */
	onSubmit() {
		// this.submitButton.disabled = true;
		// this.submitButton.textContent = 'Processing Payment…';
		// this.processingDialogRef = this.dialog.open(PaymentProcessingDialogComponent, {
		// 	width: '40%',
		// 	disableClose: true
		// });

		// several payment paths
		// is there a saved source selected?
		if (this.chosenPaymentMethod && this.chosenPaymentMethod['id']) {
			// proceed with selected source
			this.placeOrderWithCustomerSource(this.chosenPaymentMethod as StripeSource);
		} else if (this.loggedInUser != null && this.saveNewPaymentMethod) {
			this.placeOrderWithNewCustomerSource();
		} else {
			this.placeOrderWithSingleUseSources();
		}
	} // onSubmit()

	// get subtotal(): number {
	// 	// sum the values from the orders
	// 	let orderSubtotal = null;
	// 	if (this.orderArray) {
	// 		orderSubtotal = 0.0;
	// 		this.orderArray.forEach(order => {
	// 			orderSubtotal += order.productTotal;
	// 		});
	// 	}
	//
	// 	return orderSubtotal;
	//
	// } // subtotal()
	//
	// get tax(): number {
	// 	// sum the values from the orders
	// 	let tax = null;
	// 	if (this.orderArray) {
	// 		tax = 0.0;
	// 		this.orderArray.forEach(order => {
	// 			tax += order.taxTotal;
	// 		});
	// 	}
	//
	// 	return tax;
	//
	// } // taxTotal()
	//
	// get freight(): number {
	// 	// sum the values from the orders
	// 	let freight = null;
	// 	if (this.orderArray) {
	// 		freight = 0.0;
	// 		this.orderArray.forEach(order => {
	// 			freight += order.freight;
	// 		});
	// 	}
	//
	// 	return freight;
	//
	// } // freight()
	//
	//
	// /**
	//  *
	//  * @returns {number}
	//  */
	// get total(): number {
	// 	let total = null;
	// 	// sum the values from the orders
	// 	let productTotal = 0.0;
	// 	let tax = 0.0;
	// 	let freight = 0.0;
	// 	if (this.orderArray) {
	// 		this.orderArray.forEach(order => {
	// 			productTotal += order.productTotal;
	// 			tax += order.taxTotal;
	// 			freight += order.freight;
	// 		});
	// 		total = productTotal + tax + freight;
	// 	}
	//
	// 	return total;
	// } // total()

	/**
	 * Queue the order for further processing
	 * @param {Order} order
	 * @param {string} sourceId
	 * @param {string} customerId
	 */
	queueOrder(order: Order, customerId: string) {
		console.log('queueOrder(): orderId = ' + order.id);

		// save stuff to the order before sending off for processing
		// setting stripeSourceId on the order is equivalent to placing it in queue

    //temp removed stripeSourceId from args
		// order.stripeSourceId = sourceId;
		order.stripeCustomerId = customerId;

		// capture the billing address
		this.captureBillingAddressInOrder(order);

		// check to see if all orders are in the queue
		let allOrdersQueued: boolean = true;
		this.orderArray.forEach((orderBeingChecked) => {
			// if this order does not have a stripe source
			// then NOT all orders are queued
			console.log('checking orderId: ' + orderBeingChecked.id);
			if (!this.orderIsQueued(orderBeingChecked)) {
				console.log('setting allOrdersQueued to false');
				allOrdersQueued = false;
			}
		});

		// if all orders are queued then process the queue
		if (allOrdersQueued) {
			this.processOrders();
		}
	} // queueOrder()

	/**
	 *
	 * @param order
	 * @returns {boolean}
	 */
	orderIsQueued(order: Order) {
		return order.stripeSourceId && order.stripeSourceId.length > 0;
	} // orderIsQueued()

	/**
	 * Process the orders
	 */
	processOrders() {
		console.log('processOrders()');

		if (this.processingDialogRef === undefined) {
			this.processingDialogRef = this.dialog.open(PaymentProcessingDialogComponent, {
				width: '40%',
				disableClose: true
			});
		}

		this.orderService.processOrders(this.orderArray).subscribe(
			(processOrderResult: Order[]) => {
				// if (!processOrderResult) {
				// 	// interpret failure
				// 	// if the s2k submit failed then we will retry; proceed here
        //
				// 	// get the error code
				// 	const errorCode: number = Functions.getErrorCode(processOrderResult);
				// 	console.log('errorCode = ' + errorCode);
        //
				// 	// if it's an S2K error then the order will go into the retry queue
				// 	// we will ignore it in the UI
				// 	if (errorCode === ErrorCode.S2K_SUBMIT_FAILED || errorCode === ErrorCode.ORDER_ALREADY_IN_S2K) {
				// 		console.log('Ignoring s2k error.');
				// 	} else {
				// 		this.paymentFailed(processOrderResult);
				// 		return;
				// 	}
				// }

				// the cart items are removed in processOrder(); we need to update the UI to reflect the change.
				this.cartService.getShoppingCartItems().subscribe(
					(getShoppingCartItemsResponse: ShoppingCartItem[]) => {
						this.cartService.dispatchCartUpdate(getShoppingCartItemsResponse);
					},
					(error: any) => console.error(error),
					() => {
						// not a show-stopper if cart is not cleared
					}
				);

				// if (processOrderResult.hasOwnProperty('result')) {
				// 	const processedOrders = processOrderResult;
        //
				// 	// set s2kOrderNumbers on local Order objects
				// 	this.orderArray.forEach((localOrder) => {
				// 		const index = processedOrders.findIndex((processedOrder) => processedOrder.id === localOrder.id);
				// 		localOrder.s2kOrderNumber = processedOrders[index].s2kOrderNumber;
				// 	});
				// }

				// clear dialog and proceed to OrderCompleted
				this.processingDialogRef.close();
        this.paying.set(false);
				this.next();
			},
			(error) => {
				this.handlePaymentError(error);
				return;
			}
		);
	} // processOrders()

	/**
	 * Proceed to next step
	 */
	next() {
		console.log('next()');

		this.completed.emit();
	} // next()

	/**
	 * Place the order with single use sources
	 */
	placeOrderWithSingleUseSources() {
		console.log('placeOrderWithSingleUseSources()');

		// todo for testing
		const testingForSecondSourceFailed = false;
		let index = 0;

		// for each order, create a new source and charge it immediately
		this.orderArray.forEach((order) => {
			// create new source
			// this.stripeService
			//     .createSource(this.cardElement, this.stripeSourceData)
			//     .subscribe(createSourceResponse => {
			//         if (createSourceResponse.error || !createSourceResponse.source) {
			//             this.handlePaymentError(createSourceResponse);
			//             return;
			//         }
			//
			//         const ngxSource = createSourceResponse.source;
			//
			//         // todo for testing
			//         if (testingForSecondSourceFailed && index > 0) {
			//             ngxSource.status = 'failed';
			//             this.paymentMethodErrorMessage = 'Testing for second source failed.';
			//         }
			//         index++;
			//
			//         switch (ngxSource.status) {
			//             case 'chargeable':
			//                 // hooray, we have a chargeable source, we can proceed
			//                 // save the paymentSource for later use (capture billing address)
			//                 // if there is more than one order this will get overwritten, but that's fine
			//                 try {
			//                     // we want to see if there is an error with creating the source
			//                     // chances are if it fails here then it is a foreign credit card
			//                     this.paymentSource = StripeSource.createFromNgxSource(ngxSource);
			//                     // charge the Source for this order
			//                     this.queueOrder(order, ngxSource.id, null);
			//                 } catch (e) {
			//                     this.handlePaymentError(e);
			//                 }
			//
			//
			//
			//
			//                 break;
			//             case 'failed':
			//             case 'canceled':
			//             default:
			//                 this.handlePaymentError(null);
			//                 return;
			//         } // end switch
			//     }, error => {
			//         this.handlePaymentError(error);
			//         return;
			//     });
		}); // end foreach order
	} // placeOrderWithSingleUseSources()

	/**
	 * Place the order with a new customer source
	 */
	placeOrderWithNewCustomerSource() {
		console.log('placeOrderWithNewCustomerSource()');

		// fix stripeSourceData
		// this.stripeSourceData.owner.name = null;
		// this.stripeSourceData.owner.phone = null;
		// this.stripeSourceData.owner.address.line1 = null;
		//
		// // create new source
		// this.stripeService
		//     .createSource(this.cardElement, this.stripeSourceData)
		//     .subscribe(createSourceResponse => {
		//         if (createSourceResponse.error || !createSourceResponse.source) {
		//             this.handlePaymentError(createSourceResponse);
		//             return;
		//         }
		//
		//         const ngxSource = createSourceResponse.source;
		//
		//         switch (ngxSource.status) {
		//             case 'chargeable':
		//                 // hooray, we have a chargeable source, we can proceed
		//
		//                 // save the paymentSource for later use (capture billing address)
		//                 try {
		//                     // we want to see if there is an error with creating the source
		//                     // chances are if it fails here then it is a foreign credit card
		//                     this.paymentSource = StripeSource.createFromNgxSource(ngxSource);
		//                     // Save the source to an existing or new Stripe customer.
		//                     // NOTE that the Source must be attached to the Customer BEFORE it is charged.
		//                     this.saveNewCustomerSource(ngxSource);
		//                 } catch (e) {
		//                     this.handlePaymentError(e);
		//                 }
		//
		//
		//
		//                 break;
		//             case 'failed':
		//             case 'canceled':
		//             default:
		//                 this.handlePaymentError(null);
		//                 return;
		//         } // end switch
		//     }, error => {
		//         this.handlePaymentError(error);
		//         return null;
		//     });
	} // placeOrderWithNewCustomerSource()

	/**
	 * Place the order with the Customer Source
	 * @param {StripeSource} paymentSource
	 */
	placeOrderWithCustomerSource(paymentSource: StripeSource) {
		console.log('placeOrderWithCustomerSource()');

		this.orderArray.forEach((order) => {
			this.queueOrder(order,  paymentSource.customer);
		});
	} // placeOrderWithCustomerSource()

	/**
	 * Create Customer
	 * NOTE that this method also calls placeOrderWithCustomerSource()
	 * @param {Source} ngxSource
	 */
	createCustomer(ngxSource: any) {
		console.log('createCustomer()');

		const stripeCustomerInput: StripeCustomerInput = {
			email: ngxSource.owner.email,
			sourceId: ngxSource.id
		};

		// this.paymentService.createStripeCustomer(stripeCustomerInput)
		//     .subscribe(
		//         createStripeCustomerResponse => {
		//             if (createStripeCustomerResponse.success) {
		//                 let stripeCustomer: StripeCustomer = null;
		//                 if (createStripeCustomerResponse.result.id) {
		//                     stripeCustomer = createStripeCustomerResponse.result;
		//
		//                     try {
		//                         // we want to see if there is an error with creating the source
		//                         // chances are if it fails here then it is a foreign credit card
		//                         // now place the order with the newly created and saved source
		//                         const stripeSource = StripeSource.createFromNgxSource(ngxSource);
		//                         // set the new customer id on the source
		//                         stripeSource.customer = stripeCustomer.id;
		//                         this.placeOrderWithCustomerSource(stripeSource);
		//                     } catch (e) {
		//                         this.handlePaymentError(e);
		//                     }
		//
		//
		//                 } else {
		//                     this.handlePaymentError(createStripeCustomerResponse);
		//                     return false;
		//                 }
		//
		//                 // update the logged in user account with the stripe stripeCustomer id
		//                 // note that this returns separately from above subscribe
		//                 // if (stripeCustomer) {
		//                 //     this.loggedInUser.stripeId = stripeCustomer.id;
		//                 //     this.accountDetailsSerice.updateAccount(this.loggedInUser)
		//                 //         // .pipe(retryWhen(this.retryService.safariRetryStrategy()))
		//                 //         .subscribe(
		//                 //             updateAccountResponse => {
		//                 //                 if (updateAccountResponse.success && updateAccountResponse.result) {
		//                 //                     this.loggedInUser.stripeCustomer = stripeCustomer;
		//                 //                 } else {
		//                 //                     this.displayError(updateAccountResponse);
		//                 //                     return false;
		//                 //                 }
		//                 //             });
		//                 // }
		//             } else {
		//                 this.handlePaymentError(createStripeCustomerResponse);
		//                 return false;
		//             }
		//         }, error => {
		//             this.handlePaymentError(error);
		//             return false;
		//         });
	} // createCustomer()

  /**
   * Save the new Stripe Source to the user's Stripe Customer
   * If there is no Stripe Customer associated with the user then create the Stripe Customer here
   * NOTE that this method calls placeOrderWithCustomerSource() (one of 2 paths)
   * @param ngxSource
   * @return {StripeSource}
   */
  saveNewCustomerSource(ngxSource: any) {
    console.log('saveNewCustomerSource()');

    // if (this.loggedInUser.stripeId == null || this.loggedInUser.stripeId === '') {
    //     // create stripe customer with the source
    //     this.createCustomer(ngxSource);
    //
    // } else {
    //     // attach the Source to the existing stripe customer
    //     // console.log('existing customer');
    //     const stripeCustomerSourceInput: StripeCustomerSourceInput = {
    //         sourceId: ngxSource.id,
    //         customerId: this.loggedInUser.stripeId
    //     };
    //
    //     this.paymentService.addSourceToCustomer(stripeCustomerSourceInput)
    //         // .pipe(retryWhen(this.retryService.safariRetryStrategy()))
    //         .subscribe(addSourceResponse => {
    //             if (addSourceResponse.success) {
    //
    //                 const stripeCustomer: StripeCustomer = addSourceResponse.result;
    //
    //                 // get the source back from the StripeCustomer object
    //                 // this ensures that it was properly attached to the customer
    //                 const index = stripeCustomer.sources.data.findIndex(src => src.id === ngxSource.id);
    //                 // this is the return value
    //                 const stripeSource = stripeCustomer.sources.data[index];
    //
    //                 // update the local model
    //                 this.stripeCustomer.sources.data.push(stripeSource);
    //
    //                 // now place the order with the newly created and saved source
    //                 if (stripeSource) {
    //                     this.placeOrderWithCustomerSource(stripeSource);
    //                 }
    //
    //             } else {
    //                 this.handlePaymentError(addSourceResponse);
    //                 return null;
    //             }
    //
    //         }, error => {
    //             this.handlePaymentError(error);
    //             return null;
    //         });
    // }
  } // saveNewCustomerSource()

	/**
	 * Capture the billing address in the order
	 */
	captureBillingAddressInOrder(order: Order) {
		// capture the billing information on the order
		if (this.paymentSource != null) {
			// email is tricky - may or may not be in the paymentSource or the form or the user account
			if (this.paymentSource && this.paymentSource.owner && this.paymentSource.owner.email) {
				order.billToEmail = this.paymentSource.owner.email;
			} else if (this.stripeSourceData && this.stripeSourceData.owner && this.stripeSourceData.owner.email) {
				order.billToEmail = this.stripeSourceData.owner.email;
			} else if (this.loggedInUser && this.loggedInUser.email) {
				order.billToEmail = this.loggedInUser.email;
			}
			// order.billToZip = this.paymentSource.owner.address.postal_code;
			order.billToZip = '12345'
		} else {
			// this is a fail-safe: the paymentSource should have been set previously
			// order.billToEmail = this.stripeSourceData.owner.email;
			// order.billToZip = this.stripeSourceData.owner.address.postal_code;
		}
	} // captureBillingAddressInOrder()

	/**
	 * Handle Payment Error
	 * we might get here with different errors
	 * interpret and handle them correctly
	 * @param {string} error
	 * @return {number} error code
	 */
	handlePaymentError(error: any): number {
		console.log('handlePaymentError()');

		let errorObject: any = error;
		if (!error) {
			errorObject = this.paymentMethodErrorMessage;
		} else {
			errorObject = Functions.findErrorObject(error);
		}

		// interpret the errorObject
		let errorCode = ErrorCode.NO_ERROR_CODE_AVAILABLE;
		if (errorObject.hasOwnProperty('code')) {
			errorCode = errorObject.code;
			console.log('errorCode = ' + errorObject.code);
		}

		// if it's an S2K error then the order will go into the retry queue
		// we will ignore it in the UI
		if (errorCode === ErrorCode.S2K_SUBMIT_FAILED || errorCode === ErrorCode.ORDER_ALREADY_IN_S2K) {
			console.log('ignoring s2k error.');
			return errorCode;
		}

		// other errors necessitate a failure response
		this.paymentFailed(errorObject);

		return errorCode;
	} // paymentFailed()

	/**
	 * Payment Failed
	 * This method clears sourceIds from ALL queued orders
	 * This is super important!!!
	 * If one souce / charge fails then entire transaction must fail
	 * @param {string} error
	 */
	paymentFailed(error: any) {
		console.log('paymentFailed()');

		this.paymentSource = null;

		if (this.processingDialogRef !== undefined) {
			this.processingDialogRef.close();
		}

		// clear out any existing stripeSourceIds - must start from scratch
		this.orderArray.forEach((order: Order) => {
			order.stripeSourceId = '';
			order.stripeCustomerId = '';
		});

		let errorObject: any = null;
		if (!error) {
			errorObject = this.paymentMethodErrorMessage;
		} else {
			errorObject = Functions.findErrorObject(error);
		}

		// interpret the errorObject
		if (errorObject.hasOwnProperty('code')) {
			console.log('errorCode = ' + errorObject.code);
		}

		this.displayError(errorObject);
	} // paymentFailed()

	/**
	 * Display Successful Result
	 */
	displaySuccess() {
		this.displayMessage('Your payment was successful!');

		return;
	} // displayChargeStatus()

	/**
	 * Display Error Information
	 * We do our best here to find whatever error information might be available
	 */

	displayError(errorSource?: any) {
		this.submitButton.disabled = false;
		this.submitButton.textContent = 'Please fix error and try again.';

		this.snackBar.dismiss();

		// retrieve the error message from the errorSource if possible
		let errorMessage = Functions.findErrorMessage(errorSource);
		if (errorMessage) {
			errorMessage = this.defaultErrorMessage + ': ' + errorMessage;
		}

		// display the message
		// this.displayMessage(errorMessage);

		return;
	} // displayError()

	/**
	 * Open a dialog to display the given message
	 * @param {string} message
	 */
	displayMessage(message: string) {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			data: {
        title: 'Alert',
        message: message
      },
			width: '50%'
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (!result) {
				return;
			}
		});
	} // displayMessage()

	/**
	 * Show the Delete Confirmation Dialog
	 * @returns {Observable<any>}
	 */
	protected showDeleteConfirmation() {
		const newDialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '30%',
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this?'
      }
    });
		return newDialog.afterClosed();
	} // showDeleteConfirmation()

	// return to shopping cart
	return() {
		this.returning.emit();
	} // return()

	/**
	 *
	 */
	getStripeCustomer() {
		console.log('getStripeCustomer()');

		if (
			this.stripeCustomer != null ||
			this.loggedInUser == null ||
			this.loggedInUser.stripeId == null ||
			this.loggedInUser.stripeId === ''
		) {
			return;
		}
		// this.paymentService.getStripeCustomer(this.loggedInUser)
		//     // .pipe(retryWhen(this.retryService.safariRetryStrategy()))
		//     .subscribe(stripeCustomerResponse => {
		//         if (!stripeCustomerResponse.success) {
		//             return;
		//         }
		//
		//         this.stripeCustomer = stripeCustomerResponse.result;
		//
		//         // this.store.dispatch(new UpdateStripeCustomer(stripeCustomerResponse.result));
		//
		//         if (this.stripeCustomer.sources.data.length > 0) {
		//             this.setPaymentMethodType(this.savedCreditCard);
		//         }
		//
		//     }, error => {
		//         console.error(error.error);
		//     });
	} // getStripeCustomer()

	/**
	 *
	 * @param {string} method
	 */
	setPaymentMethodType(method: string) {
		this.paymentMethodType = method;

		this.paymentMethodTypeNewCreditCard = this.paymentMethodType === this.newCreditCard;
		this.paymentMethodTypeSavedCreditCard = this.paymentMethodType === this.savedCreditCard;
		this.paymentMethodTypeGooglePay = this.paymentMethodType === this.googlePay;
		this.paymentMethodTypeApplePay = this.paymentMethodType === this.applePay;

		this.chosenPaymentMethod =
			this.paymentMethodTypeNewCreditCard || this.paymentMethodTypeGooglePay || this.paymentMethodTypeApplePay
				? null
				: this.chosenPaymentMethod;
	} // setPaymentMethodType()

	getPaymentMethodType() {
		return this.paymentMethodType;
	}

	/**
	 *
	 * @param {Order} order
	 * @returns {string}
	 */
	getPaymentRequestButtonId(order: Order) {
		return 'payment-request-button-' + order.id;
	} // getPaymentRequestButtonId()

	/**
	 *
	 * @returns {boolean}
	 */
	showPaymentOptions() {
		let showPaymentOptions: boolean = true;
		// let showPaymentOptions: boolean = false;
    //
		// if (
		// 	(this.loggedInUser && this.stripeCustomer && this.stripeCustomer.sources.data && this.stripeCustomer.sources.data.length > 0) ||
		// 	this.googlePayAvailable ||
		// 	this.applePayAvailable
		// ) {
		// 	if (!this.orderPartiallyProcessed) {
		// 		showPaymentOptions = true;
		// 	}
		// }

		return showPaymentOptions;
	} // showPaymentOptions()
}
