<section class="px-[20px] md:px-[50px] pb-[20px]">
	<h3>Reviews</h3>
	<div class="px-2 border border-gray-400 w-full rounded bg-white">
		<div *ngFor="let review of reviews; index as i" class="pt-2">
      <div class="flex">
        <div class="px-2">
          <div class="h-8 w-8 text-white text-lg items-center justify-center flex rounded-[50%] bg-purple mt-1">
            {{review.user?.firstName?.substring(0,1) | uppercase}}
          </div>
        </div>
        <div class="w-full">
          <div class="flex justify-between">
            <b class="text-secondary">{{review.user?.firstName | titlecase}}&nbsp;{{review.user?.lastName?.substring(0,1) | titlecase}}</b>
            <b class="">{{review.createdTs | date: 'shortDate'}}</b>
          </div>
          <app-star-rating [displayMode]="true" [rating]="review.rating"></app-star-rating>
        </div>
      </div>
			<div class="mb-2">
				<b class="mb-2">{{ review.name }}</b>
				<p>{{ review.review }}</p>
			</div>
			<mat-divider *ngIf="i != reviews.length - 1"></mat-divider>
		</div>
    <p *ngIf="!reviews.length" class="py-2">No reviews yet...</p>
	</div>
</section>
