import {Component, Input} from '@angular/core';
import {CartService} from '../../../core/services/cart.service';
import {Product} from '../../../core/models/product.model';
import {AddToCartEnum} from '../../../core/enums/add-to-cart.enum';
import {ShoppingCartItem} from '../../../core/models/shopping-cart.model';

@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss']
})

export class AddToCartComponent {
  @Input() style: number;
  @Input() product: Product;
  @Input() qty: number;
  @Input() isDisabled: boolean;

  waiting: boolean = false;
  success: boolean | null = null;
  enum = AddToCartEnum;

  constructor(private cartService: CartService) {
  }

  wait() {
    this.waiting = true;
  }

  finish() {
    this.waiting = false;
  }

  setSuccess(success: boolean) {
    this.success = success;
  }

  unsetSuccess() {
    this.success = null;
  }

  add(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.wait();
    this.cartService.addToCart(this.product, this.qty)
      .subscribe(
        (next: ShoppingCartItem[]) => this.onSuccess(next),
        (error: any) => {
          // if the status is 0 and we are looking at safari then call the make shift retry logic
          // if (error.status === 0 && this.retryService.isSafari) {
          // make shift retry logic
          // what is happning so apple products are failing on some type of call
          // but the next call almost always works
          // how ever in the add to cart it was adding two because the server was getting the add but not returing a response
          // instead of sending the add again lets just call what the response will be.
          // this.cartService.getShoppingCartItems()
          // .subscribe((next: { success: boolean, result: ShoppingCartItem[] }) => this.onSuccess(next))
          // } else {
          console.error('add to cart error', error);
          // }
        }
      );
  }

  onSuccess(next:  ShoppingCartItem[]) {
    this.finish();
    this.setSuccess(!!next);
    this.cartService.dispatchCartUpdate(next);
    setTimeout(() => {
      this.unsetSuccess();
    }, 2000);
  }

}
