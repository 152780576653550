import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {FavoriteProduct} from "../models/favorite-product.model";
import {Store} from "@ngrx/store";
import {AppStore} from "../models/state.model";
import {UpdateFavoriteProducts } from "../actions/state.actions";
import {Product} from "../models/product.model";
import {FavoriteRecipe} from "../models/favorite-recipe.model";
import {MatDialog} from "@angular/material/dialog";
import {Account} from "../models/account.model";
import {Router} from "@angular/router";
// import {ConfirmationDialogComponent} from "../components/common-dialogs/confirmation.dialog.component";

@Injectable()
export class SitemapService {

  readonly baseUrl = `${environment.url}/console/generate-sitemap`;

  constructor(private http: HttpClient) {
  }

  getSitemap(): Observable<{success: boolean, result: string}> {
    return this.http.get<{success: boolean, result: string}>(this.baseUrl);
  }
}
