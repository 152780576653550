import * as StateActions from '../actions/state.actions';
import {CategoryViewEnum} from "../enums/category-view.enum";
import {State} from "../models/state.model";
import {ContentPage} from "../models/content-page.model";
import {Product} from "../models/product.model";
import {ShoppingCartItem} from "../models/shopping-cart.model";
import {Feature} from "../models/feature.model";

// create a new state
const blankState: State = new State();

// define the state reducer
export function StateReducer(state: State = blankState, action: StateActions.Actions): State {
  // set up logic to handle state changes
  switch (action.type) {
    case StateActions.LOAD_INITIAL_STATE:
      const featuredItems = action.payload?.featuredItems?.slice();
      const categories = action.payload?.categories?.slice();
      const products = action.payload?.products?.slice();
      const contentPages = action.payload?.contentPages?.slice();

      // take all the current states from the state this is to prevent caching issues
      const stateProducts = state.products?.slice();
      const stateContentPages = state.contentPages?.slice();
      const stateCategories = state.categories?.slice();

      state = JSON.parse(JSON.stringify(action.payload));

      // index the categories
      featuredItems?.filter(item => item.active && !item.deleted)
        // .forEach((feat) => {
        //   state.featuredItems[feat.id] = feat;
        // });
      categories?.filter(cat => cat.active && !cat.deleted)
        .forEach((cat) => {
          if (stateCategories[cat.id]) {
            stateCategories[cat.id] = Object.assign(stateCategories[cat.id], cat);
          } else {
            stateCategories[cat.id] = cat;
          }
        });
      products?.filter(prod => prod.active && !prod.deleted)
        .forEach((prod) => {
          stateProducts.push(prod);
        });
      contentPages?.forEach((cat: ContentPage) => {
        stateContentPages[cat.id] = cat;
      });

      state.products = stateProducts;
      state.contentPages = stateContentPages;
      state.categories = stateCategories;

      // we always want to start off on gallery
      state.categoryView = CategoryViewEnum.GALLERY;

      state.addr2IsDisabled = action.payload.addr2IsDisabled;


      return {...state};

    case StateActions.USER_LOGIN:
      // state.favoriteProducts = action.payload.favoriteProducts;
      // state.accountFilters = action.payload.accountFilters;
      return {
        ...state,
        user: action.payload.user,
        cartItems: action.payload.cartItems,
        addresses: action.payload.addresses
      };

    case StateActions.USER_LOGOUT:
      //  state.stripeCustomer = null;
      //**stripeCustomer missing below**
      return {...state,
        user: null,
        favoriteProducts: [],
        accountFilters: [],
        addresses: [],
        cartItems: [],
        shippingAddress: null,
        shippingAddressSameAsBilling: null
      };

    /*
            case StateActions.STRIPE_CUSTOMER_UPDATE:
                state.stripeCustomer = action.payload;
                return {...state};
    */

    case StateActions.CATEGORIES_UPDATE:
      if (!state.categories) {
        state.categories = [];
      }
      action.payload?.filter(cat => cat.active && !cat.deleted)
        .forEach(payload => {
          if (state.categories[payload.id]) {
            state.categories[payload.id] = Object.assign(state.categories[payload.id], payload);
          } else {
            state.categories[payload.id] = payload;
          }
        });
      return {...state};

      case StateActions.CATEGORY_UPDATE:
      return {
        ...state,
        categories: state.categories.map(category => {
          if (category.id === action.payload.id) {
            return { ...category, ...action.payload };
          }
          return category;
        })
      }

    case StateActions.CART_ITEMS_UPDATE:
      return {
        ...state,
        cartItems: action.payload
      };

    case StateActions.CART_ITEM_UPDATE:
      return {
        ...state,
        cartItems: state.cartItems.map(item =>
          item.itemNumber === action.payload.itemNumber ? action.payload : item
        )
      };


    case StateActions.FAVORITE_PRODUCTS_UPDATE:
      state.favoriteProducts = action.payload;
      return {...state};

    case StateActions.TAG_GROUPS_UPDATE:
      state.tagGroups = action.payload;
      return {...state};

    case StateActions.PRODUCTS_UPDATE:
      return {
        ...state,
        products: state.products.concat((action.payload ?? []).filter((obj2: Product) => !state.products.some((obj1: Product) => obj1.itemNumber === obj2.itemNumber)))
      }

    case StateActions.PRODUCT_UPDATE:
      return {
        ...state,
        products: state.products.length
          ? state.products.map(prod =>
            prod.itemNumber === action.payload.itemNumber
              ? { ...prod, ...action.payload }
              : prod
          )
          : [action.payload, ...state.products]
      };


    case StateActions.FEATURED_ITEMS_UPDATE:
      return {
        ...state,
        featuredItems: action.payload
      }

    case StateActions.CONTENT_PAGES_UPDATE:
      const content: ContentPage[] = state.contentPages ? JSON.parse(JSON.stringify(state.contentPages)): [];

      action.payload?.forEach((payload: any) => {
        if (content[payload.id]) {
          content[payload.id] = Object.assign(content[payload.id], payload);
        } else {
          content[payload.id] = payload;
        }
      });
      return {...state, contentPages: content};

    case StateActions.TAGS_UPDATE:
      state.tags = action.payload;
      return {...state};

    case StateActions.ACCOUNT_FILTERS_UPDATE:
      state.accountFilters = action.payload;
      return {...state};

    case StateActions.ADDRESSES_UPDATE:
      return {...state, addresses: action.payload};

    case StateActions.CATEGORY_VIEW_UPDATE:
      return {...state, categoryView: action.payload};

    case StateActions.SHIPPING_ADDRESS_UPDATE:
      return {...state, shippingAddress: action.payload };

    case StateActions.SHIPPING_ADDRESS_SAME_AS_BILLING_UPDATE:
      return {...state, shippingAddressSameAsBilling: action.payload };

    case StateActions.ACCOUNT_DETAIL_UPDATE:
      return {...state, user: action.payload};

    default:
      return state;
  }
}
