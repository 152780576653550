import {Role} from "./role.interface";

//FIXME needs password column
export class User {
  constructor() {
    //default role for now
    this.roleId = 1
  }
  id: number;
  email: string;
  password: string;
  name: string;
  firstName: string;
  lastName: string;
  description: string;
  userType: string;
  phone: string;
  phoneExt: string;
  roleId: number;
  cognitoId: string;
  stripeId: string;
  dataOptIn: boolean;
  taxExempt: boolean;
  verified: boolean;
  verificationCode: string;
  verificationExpire: Date;
  displaySeq: number;
  active: number;
  checked?: boolean;
  expanded?: boolean;
  role?: Role;
}

//account has these:
// isVerified: string;
// verificationCode: string;
// verificationExpire: string;
// gettingNewsLetters: boolean;
