import { Product }         from './product.model';
import { Category }        from './category.model';
import { ProductCategory } from './product-category.model';

export class SearchResult {
    term: string | null                        = null;
    products: Product[]                  = [];
    categories: Category[]               = [];
    productCategories: ProductCategory[] = [];
}
