import {Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID} from '@angular/core';
import {Order} from '../../core/models/order.model';
import {ShippingRateDetail, ShippingRates} from '../../core/models/shipping-rates.model';
import {FedexService} from '../../core/services/fedex.service';
import {Address} from '../../core/models/address.model';
import {OrderService} from '../../core/services/order.service';
import {ErrorService} from '../../core/services/error.service';
import {OrderItem} from '../../core/models/order-item.model';
import {PromotionTypeEnum} from '../../core/enums/promotion-type.enum';


@Component({
	selector: 'app-shipping-options',
	templateUrl: './shipping-options.component.html',
	styleUrls: ['./shipping-options.component.scss']
})
export class ShippingOptionsComponent implements OnInit {
	@Output() completed = new EventEmitter();
	@Output() returning = new EventEmitter();

	orderArray: Order[];

	// if given the option to split, did they split?
	splitOrder: boolean = false;

	// should we give them the option to split?
	splitShippingOption: boolean = false;

	perishableItems: OrderItem[] | null = null;
	nonperishableItems: OrderItem[] | null = null;

	perishableShippingRates: ShippingRates | null = null;
	nonperishableShippingRates: ShippingRates | null = null;
	combinedShippingRates: ShippingRates | null = null;

	chosenPerishableShippingMethod: ShippingRateDetail | null = null;
	chosenNonperishableShippingMethod: ShippingRateDetail | null = null;
	chosenCombinedShippingMethod: ShippingRateDetail | null = null;

	orderApiErrorMsg: string = 'We could not verify your order. Please try again.';

	fedexApiErrorMsg: string = 'Sorry, we could not contact UPS at this time prepare your order. Please try again.';

	fedexUnavailableRatesErrorMsg: string = 'There was a problem contacting UPS for your shipping rates. Please try again.';

	addShippingMethodToOrderErrorMsg: string = 'We could not add your shipping method to your order. Please try again.';

	ratesLoading: boolean = true;

	// gift stuff
	giftIsDisabled: boolean = true;
	giftFlag: boolean = false;
	giftReceipt: boolean = true;
	giftMessage: string;
	giftMessageMax: number = 250;

	constructor(
		private fedexService: FedexService,
		private errorService: ErrorService,
		@Inject(PLATFORM_ID) private platformId: any,
		private orderService: OrderService
	) {}

	ngOnInit() {
		// orderArray needs to be reset here to avoid confusion for the summary display
		this.splitOrder = false;
		this.orderArray = [];

		// this.orderService.getGiftDisabledStatus().subscribe((result) => {
		// 	if (result) {
		// 		this.giftIsDisabled = result;
		// 	}
		// });

		this.orderService.getOrdersReadyForShippingDecisions().subscribe(
			(next: Order[]) => {
				if (!next) {
					this.errorService.showErrorMessage(this.orderApiErrorMsg);
					return;
				}
				this.orderArray = next;
				const order = this.orderArray[0];

				this.getRates();

				this.splitOrder = false;
				this.splitShippingOption = false;
			},
			(error: any) => this.errorService.showErrorMessage(this.orderApiErrorMsg)
		);
	}

	getRates() {
		if (this.orderArray[0]) {
      this.perishableShippingRates = null;
      this.nonperishableShippingRates = null;
      this.combinedShippingRates = null;

			const address = new Address();
			const firstOrder = this.orderArray[0];
			address.line1 = firstOrder.shipToAddress1;
			address.line2 = firstOrder.shipToAddress2;
			address.city = firstOrder.shipToCity;
			address.state = firstOrder.shipToState;
			address.postalCode = firstOrder.shipToZip;
			address.country = firstOrder.shipToCountry;
			this.fedexService.getRates().subscribe(
				(getRatesResult: {perishable: ShippingRates; nonPerishable: ShippingRates; combined: ShippingRates}) => {
					if (!getRatesResult) {
						this.errorService.showErrorMessage(this.fedexApiErrorMsg);
						return;
					}
			this.perishableShippingRates = getRatesResult.perishable;
			this.nonperishableShippingRates = getRatesResult.nonPerishable;
			this.combinedShippingRates = getRatesResult.combined;

			// set first value by default (change if you have a better idea)
			if (this.perishableShippingRates) {
				const chosenPerishable = this.perishableShippingRates.rateDetail.find((rate) => {
					const perishableOrder = this.orderArray.find((order) => order.hasPerishables);

					return perishableOrder ? perishableOrder.shipMethod === rate.shipMethod : false;
				});

				this.chosenPerishableShippingMethod = chosenPerishable ? chosenPerishable : this.perishableShippingRates.rateDetail[0];
			}

			if (this.nonperishableShippingRates) {
				const chosenNonPerishable = this.nonperishableShippingRates.rateDetail.find((rate) => {
					const nonPerishableOrder = this.orderArray.find((order) => order.hasNonPerishables);

					return nonPerishableOrder ? nonPerishableOrder.shipMethod === rate.shipMethod : false;
				});

				this.chosenNonperishableShippingMethod = chosenNonPerishable ? chosenNonPerishable : this.nonperishableShippingRates.rateDetail[0];
			}

			if (this.combinedShippingRates) {
				const chosenCombined = this.combinedShippingRates.rateDetail.find((rate) => {
					const combinedOrder = this.orderArray.find((order) => order.hasPerishables && order.hasNonPerishables);

					return combinedOrder ? combinedOrder.shipMethod === rate.shipMethod : false;
				});

				this.chosenCombinedShippingMethod = chosenCombined ? chosenCombined : this.combinedShippingRates.rateDetail[0];
			}

			if (this.combinedShippingRates && this.chosenCombinedShippingMethod) {
				if (this.showDiscountPrice(this.chosenCombinedShippingMethod)) {
					this.orderArray[0].freight = this.chosenCombinedShippingMethod.shipCost;
					this.orderArray[0].shippingDiscountAmount =
						this.chosenCombinedShippingMethod.shipCost - this.calculateDiscountRate(this.chosenCombinedShippingMethod);
				} else {
					this.orderArray[0].freight = this.chosenCombinedShippingMethod.shipCost;
				}
			} else {
				if (this.splitShippingOption && this.chosenPerishableShippingMethod) {
					if (this.showDiscountPrice(this.chosenPerishableShippingMethod)) {
						this.orderArray[0].freight = this.chosenPerishableShippingMethod.shipCost;
						this.orderArray[0].shippingDiscountAmount =
							this.chosenPerishableShippingMethod.shipCost - this.calculateDiscountRate(this.chosenPerishableShippingMethod);
					} else {
						this.orderArray[0].freight = this.chosenPerishableShippingMethod.shipCost;
					}
				} else {
					if (this.perishableShippingRates && this.chosenPerishableShippingMethod) {
						if (this.showDiscountPrice(this.chosenPerishableShippingMethod)) {
							this.orderArray[0].freight = this.chosenPerishableShippingMethod.shipCost;
							this.orderArray[0].shippingDiscountAmount =
								this.chosenPerishableShippingMethod.shipCost - this.calculateDiscountRate(this.chosenPerishableShippingMethod);
						} else {
							this.orderArray[0].freight = this.chosenPerishableShippingMethod.shipCost;
						}
					} else if (this.chosenNonperishableShippingMethod) {
						if (this.showDiscountPrice(this.chosenNonperishableShippingMethod)) {
							this.orderArray[0].freight = this.chosenNonperishableShippingMethod.shipCost;
							this.orderArray[0].shippingDiscountAmount =
								this.chosenNonperishableShippingMethod.shipCost - this.calculateDiscountRate(this.chosenNonperishableShippingMethod);
						} else {
							this.orderArray[0].freight = this.chosenNonperishableShippingMethod.shipCost;
						}
					}
				}
			}
				},
				(error: any) => this.errorService.showErrorMessage(this.fedexApiErrorMsg)
			);
		}
	}

	next() {
		const thisOrderArray = [];

		const order = this.orderArray[0];

		// if we don't have a valid set of shipping methods here then return with error message
		let shippingMethodError = false;
		if (this.splitOrder) {
			// split order requires two specific methods
			if (!(this.chosenPerishableShippingMethod && this.chosenNonperishableShippingMethod)) {
				shippingMethodError = true;
			}
		} else {
			// non-split requires that one of the three be chosen
			if (!(this.chosenCombinedShippingMethod || this.chosenNonperishableShippingMethod || this.chosenPerishableShippingMethod)) {
				shippingMethodError = true;
			}
		}
		if (shippingMethodError) {
			this.errorService.showErrorMessage(this.addShippingMethodToOrderErrorMsg);
			return;
		}

		// which methods should we include in the request
		// don't include methods that don't apply!
		// const order = this.orderArray[0];
		if (!this.splitOrder) {
			let thisOrder: Order = {} as Order;

			if (order.hasPerishables && order.hasNonPerishables && this.chosenCombinedShippingMethod) {
				thisOrder = this.shippingRateDetailInOrder(this.chosenCombinedShippingMethod)!;
			} else if (order.hasPerishables && !order.hasNonPerishables && this.chosenPerishableShippingMethod) {
				thisOrder = this.shippingRateDetailInOrder(this.chosenPerishableShippingMethod)!;
			} else if (!order.hasPerishables && order.hasNonPerishables && this.chosenNonperishableShippingMethod) {
				thisOrder = this.shippingRateDetailInOrder(this.chosenNonperishableShippingMethod)!;
			} else {
				//
			}

			thisOrder.hasNonPerishables = order.hasNonPerishables;
			thisOrder.hasPerishables = order.hasPerishables;
			// update gift options
			thisOrder.giftFlag = this.giftFlag;
			thisOrder.giftReceipt = this.giftFlag ? this.giftReceipt : false;
			thisOrder.giftMessage = this.giftFlag && this.giftReceipt ? this.giftMessage : '';
			thisOrderArray.push(thisOrder);
		} else {
			// SPLIT order
			if (this.chosenPerishableShippingMethod) {
				const perishableOrder = this.shippingRateDetailInOrder(this.chosenPerishableShippingMethod);
				if (perishableOrder) {
					perishableOrder.hasNonPerishables = false;
					perishableOrder.hasPerishables = true;
					// update gift options
					perishableOrder.giftFlag = this.giftFlag;
					perishableOrder.giftReceipt = this.giftFlag ? this.giftReceipt : false;
					perishableOrder.giftMessage = this.giftFlag && this.giftReceipt ? this.giftMessage : '';
					thisOrderArray.push(perishableOrder);
				}
			}

			if (this.chosenNonperishableShippingMethod) {
				const nonperishableOrder = this.shippingRateDetailInOrder(this.chosenNonperishableShippingMethod);
				if (nonperishableOrder) {
					nonperishableOrder.hasNonPerishables = true;
					nonperishableOrder.hasPerishables = false;
					// update gift options
					nonperishableOrder.giftFlag = this.giftFlag;
					nonperishableOrder.giftReceipt = this.giftFlag ? this.giftReceipt : false;
					nonperishableOrder.giftMessage = this.giftFlag && this.giftReceipt ? this.giftMessage : '';
					thisOrderArray.push(nonperishableOrder);
				}
			}
		}

		this.orderService
			.chooseShippingMethod(thisOrderArray)
			// .pipe(retryWhen(this.retryService.safariRetryStrategy()))
			.subscribe(
				(updateOrderResult: boolean) => {
					if (!updateOrderResult) {
						this.errorService.showErrorMessage(this.addShippingMethodToOrderErrorMsg);
						return;
					}
					this.completed.emit();
				},
				(error: any) => {
					if (error.error.message) {
						this.errorService.showErrorMessage(error.error.message);
					} else {
						this.errorService.showErrorMessage(this.addShippingMethodToOrderErrorMsg);
					}
				}
			);
	}

	shippingRateDetailInOrder(shippingRateDetail: ShippingRateDetail): Order | null {
		if (shippingRateDetail) {
			return {
				shipMethod: shippingRateDetail.shipMethod,
				shipVia: shippingRateDetail.shipVia
				// freight: shippingRateDetail.shipCost
			} as Order;
		} else {
			return null;
		}
	}

	return() {
		this.returning.emit();
	}

	isPerishableRateChosen(rateDetail: ShippingRateDetail) {
		return rateDetail === this.chosenPerishableShippingMethod;
	}

	changePerishableRate(rateDetail: ShippingRateDetail) {
		this.chosenPerishableShippingMethod = rateDetail;
		// this will always be in spot 0
		if (this.showDiscountPrice(rateDetail)) {
			this.orderArray[0].freight = rateDetail.shipCost;
			this.orderArray[0].shippingDiscountAmount = rateDetail.shipCost - this.calculateDiscountRate(rateDetail);
		} else {
			this.orderArray[0].freight = rateDetail.shipCost;
		}
	}

	isNonPerishableRateChosen(rateDetail: ShippingRateDetail) {
		return rateDetail === this.chosenNonperishableShippingMethod;
	}

	changeNonPerishableRate(rateDetail: ShippingRateDetail) {
		this.chosenNonperishableShippingMethod = rateDetail;

		let freight = 0;
		let shippingDiscountAmount = 0;
		if (this.showDiscountPrice(rateDetail)) {
			freight = rateDetail.shipCost;
			shippingDiscountAmount = rateDetail.shipCost - this.calculateDiscountRate(rateDetail);
		} else {
			freight = rateDetail.shipCost;
		}

		if (this.splitShippingOption) {
			this.orderArray[1].freight = freight;
			this.orderArray[1].shippingDiscountAmount = shippingDiscountAmount;
		} else {
			this.orderArray[0].freight = freight;
			this.orderArray[0].shippingDiscountAmount = shippingDiscountAmount;
		}
	}

	isCombinedRateChosen(rateDetail: ShippingRateDetail) {
		return rateDetail === this.chosenCombinedShippingMethod;
	}

	changeCombinedRate(rateDetail: ShippingRateDetail) {
		this.chosenCombinedShippingMethod = rateDetail;

		let freight = 0;
		let shippingDiscountAmount = 0;
		if (this.showDiscountPrice(rateDetail)) {
			freight = rateDetail.shipCost;
			shippingDiscountAmount = rateDetail.shipCost - this.calculateDiscountRate(rateDetail);
		} else {
			freight = rateDetail.shipCost;
		}

		// this will always be in spot 0
		this.orderArray[0].freight = freight;
		this.orderArray[0].shippingDiscountAmount = shippingDiscountAmount;
	}

	preventToggle(event: Event, prevent: boolean) {
		if (prevent) {
			event.preventDefault();
		}
	}

	updateGiftFlag() {
		this.giftFlag = !this.giftFlag;
	}

	updateSplitOrders(splitOrder: boolean) {
		if (splitOrder) {
			// split the orders into two
			this.splitOrders();
		} else {
			// combine the orders into one
			this.combineOrders();
		}
	}

	splitOrders() {
		const mainOrder: Order = this.orderArray.pop() ?? new Order();
		const perishableOrders: Order = {...mainOrder};
		if (this.perishableItems) {
			perishableOrders.productTotal = this.perishableItems
				.map((item) => (item.discountPrice ? item.discountPrice * item.quantity : item.price * item.quantity))
				.reduce((total, price) => price + total);
		} else {
			perishableOrders.productTotal = 0;
		}
		perishableOrders.hasNonPerishables = false;

		const nonperishableOrders: Order = {...mainOrder};
		if (this.nonperishableItems) {
			nonperishableOrders.productTotal = this.nonperishableItems
				.map((item) => (item.discountPrice ? item.discountPrice * item.quantity : item.price * item.quantity))
				.reduce((total, price) => price + total);
		} else {
			nonperishableOrders.productTotal = 0;
		}
		nonperishableOrders.hasPerishables = false;

		this.orderArray = [perishableOrders, nonperishableOrders];

		let freight = 0;
		let shippingDiscountAmount = 0;
		if (this.chosenPerishableShippingMethod && this.showDiscountPrice(this.chosenPerishableShippingMethod)) {
			freight = this.chosenPerishableShippingMethod.shipCost;
			shippingDiscountAmount =
				this.chosenPerishableShippingMethod.shipCost - this.calculateDiscountRate(this.chosenPerishableShippingMethod);
		} else {
			freight = this.chosenPerishableShippingMethod?.shipCost ?? 0;
		}
		perishableOrders.freight = freight;
		perishableOrders.shippingDiscountAmount = shippingDiscountAmount;

		freight = 0;
		shippingDiscountAmount = 0;
		if (this.chosenNonperishableShippingMethod && this.showDiscountPrice(this.chosenNonperishableShippingMethod)) {
			freight = this.chosenNonperishableShippingMethod.shipCost;
			shippingDiscountAmount =
				this.chosenNonperishableShippingMethod.shipCost - this.calculateDiscountRate(this.chosenNonperishableShippingMethod);
		} else {
			freight = this.chosenNonperishableShippingMethod?.shipCost ?? 0;
		}
		nonperishableOrders.freight = freight;
		nonperishableOrders.shippingDiscountAmount = shippingDiscountAmount;
	}

	combineOrders() {
		const perishableOrders = this.orderArray[0];
		const nonperishableOrders = this.orderArray[1];

		const combinedOrder = Object.assign(perishableOrders, nonperishableOrders);
		combinedOrder.hasPerishables = true;
		combinedOrder.hasNonPerishables = true;
		const perishableCost = (this.perishableItems || [])
			.map((item) => (item.discountPrice ? item.discountPrice * item.quantity : item.price * item.quantity))
			.reduce((total, price) => price + total);
		const nonPerishableCost = (this.nonperishableItems || [])
			.map((item) => (item.discountPrice ? item.discountPrice * item.quantity : item.price * item.quantity))
			.reduce((total, price) => price + total);
		combinedOrder.productTotal = perishableCost + nonPerishableCost;
		this.orderArray = [combinedOrder];

		let freight = 0;
		let shippingDiscountAmount = 0;
		if (this.chosenCombinedShippingMethod && this.showDiscountPrice(this.chosenCombinedShippingMethod)) {
			freight = this.chosenCombinedShippingMethod.shipCost;
			shippingDiscountAmount = this.chosenCombinedShippingMethod.shipCost - this.calculateDiscountRate(this.chosenCombinedShippingMethod);
		} else {
			freight = this.chosenCombinedShippingMethod?.shipCost ?? 0;
		}
		combinedOrder.freight = freight;
		combinedOrder.shippingDiscountAmount = shippingDiscountAmount;
	}

	validateInputs() {
		// if we don't have a valid set of shipping methods here then set error
		let shippingMethodError = false;
		if (this.splitOrder) {
			// split order requires two specific methods
			if (!(this.chosenPerishableShippingMethod && this.chosenNonperishableShippingMethod)) {
				shippingMethodError = true;
			}
		} else {
			// non-split requires that one of the three be chosen
			if (!(this.chosenCombinedShippingMethod || this.chosenNonperishableShippingMethod || this.chosenPerishableShippingMethod)) {
				shippingMethodError = true;
			}
		}

		return !shippingMethodError;
	}

	showDiscountPrice(rateDetail: ShippingRateDetail): boolean {
		// for this i can grab the first order in the array
		const order = this.orderArray[0];
		if (order && order.shippingPromotionMethod) {
			// todo this string comparison is extremely brittle
			// this equates, eg "FEDEX_GROUND" and "GROUND_HOME_DELIVERY" with the promo method "GROUND"
			// find a less brittle way to do this!
			return rateDetail.shipMethod.toLocaleLowerCase().includes(order.shippingPromotionMethod.toLocaleLowerCase());
		} else {
			return false;
		}
	}

	calculateDiscountRate(rateDetail: ShippingRateDetail): number {
		// for this i can grab the first order in the array
		const order = this.orderArray[0];
		let newPrice = 0;

		switch (order.shippingPromotionType) {
			case PromotionTypeEnum.DOLLAR_OFF:
				newPrice = rateDetail.shipCost - order.shippingPromotionAmount;
				break;
			case PromotionTypeEnum.PERCENT_OFF:
				const percentOff = (order.shippingPromotionAmount / 100) * rateDetail.shipCost;
				newPrice = rateDetail.shipCost - percentOff;
				break;
			case PromotionTypeEnum.SET_PRICE:
				newPrice = order.shippingPromotionAmount;
				break;
		}

		return newPrice < 0 ? 0 : newPrice;
	}
}
