//import { WINDOW } from '@ng-toolkit/universal';
import { Component , Inject} from '@angular/core';
//import {NewsLetterSignupDialogComponent} from "../common-dialogs/news-letter-signup.dialog.component";
import {Store} from '@ngrx/store';
import {AppStore} from '../../models/state.model';
import {Category} from '../../models/category.model';
import {Account} from '../../models/account.model';
import {MatDialog} from "@angular/material/dialog";
import {User} from "../../models/user.interface";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  categories: Category[];
  loggedInUser: User | null;

  constructor(//@Inject(WINDOW) private window: Window,
              private dialog: MatDialog, private store: Store<AppStore>) {
    store.subscribe(store => {
			const state = store.state;
			this.categories = state.categories;
			this.loggedInUser = state.user;
		});
   }

   get primaryCategories(): Category[] {
		return this.categories.filter(cat => cat.primaryCategory)
		// make sure the list is unique
			.filter((cat, index, self) => self.findIndex(s => s.id === cat.id) === index)
			// we only want to see shop by category
			.filter((cat) => cat.name.toLocaleLowerCase() === 'shop by category')
			.sort((a, b) => a.displaySeq - b.displaySeq);
  }

  getCategoryChildren(category: Category): Category[] {
		if (category.categoryRelations) {
			return this.categories.filter(cat => category.categoryRelations.findIndex(cr => cr.categoryId === cat.id) > -1)
				.sort((catA, catB) => {
					const catAIndex = category.categoryRelations.findIndex(cr => cr.categoryId === catA.id);
					const catBIndex = category.categoryRelations.findIndex(cr => cr.categoryId === catB.id);

					return category.categoryRelations[catAIndex].displaySeq - category.categoryRelations[catBIndex].displaySeq;
				});
		} else {
			return [];
		}
	}

  openLink(url: string) {
    //this.window.open(url);
  }

  signUpForEmail() {
    //this.dialog.open(NewsLetterSignupDialogComponent, {width: '60%'})
  }

}
