<div class="flex flex-col" appMobileView>
  <app-carousel *ngIf="carouselItems.length" [carouselItems]="carouselItems"></app-carousel>
  <div class="feature-section flex flex-col md:flex-row justify-center">
    <div class="feature-column-images flex flex-wrap w-full">
      <div class="flex flex-wrap w-full items-start justify-between">
        <app-featured-item *ngFor="let item of featuredCategories" [featureItem]="item"
                           [ngClass]="{'no-image':!item.imageFilename}">

        </app-featured-item>
      </div>
    </div>
  </div>

  <div class="featured-products flex flex-col md:flex-row justify-center md:justify-between">
    <div class="featured-header">
      <img class="about-logo" src="../../assets/layout/butterhead.jpg" alt="Featured Products"/>
      <div class="featured-product-content">
        <p class="header">Featured<br />Products</p>
        <p>Browse our featured products to get growing!</p>
        <p><a [routerLink]="'/products/featured-products'"><button>Shop Featured Products</button></a> </p>
      </div>
    </div>
    <app-featured-item *ngFor="let item of featuredProducts" [featureItem]="item" fxHide.lt-md="true"
                       [ngClass]="{'no-image':!item.imageFilename}">
    </app-featured-item>
  </div>
  <div class="home-about flex flex-col flex-wrap md:flex-row justify-center">
    <div class="about-image-section w-full">
      <img class="about-image" src="../../assets/layout/simplifya.jpg" alt="about"/>
    </div>
    <div class="about-image-section w-full">
      <a href="https://mmiagriculture.com/products/vertical-grow-racks/" target="_blank">
        <img class="about-image cursor-pointer" src="../../assets/layout/mmi-agriculture.jpg" alt="about"/>
      </a>
    </div>
<!--    <div class="about-text max-w-1/2">-->
<!--      <p class="header">-->
<!--        About AM General and Grow Generation-->
<!--      </p>-->
<!--      <p>-->
<!--        AM General has been a trusted partner of the U.S. Military and world allies with a tremendous history of providing them with advanced and dependable Humvee vehicles on the battlefield.-->
<!--      </p>-->

<!--      <p>-->
<!--        When it comes time to service their Humvee fleet, our customers have also trusted us to provide them with Grow Generation to expand the life of their vehicles.-->
<!--      </p>-->

<!--      <p>-->
<!--        Today, these same Humvee repair parts are available to you. By purchasing directly from AM General, you will receive the same high-quality, original equipment parts that our U.S. Armed Forces and allies around the world rely on to fulfill their missions.-->
<!--      </p>-->
<!--      <p class="last">Battle-tested and relied on for decades, Grow Generation can help you accomplish your mission.</p>-->
<!--      <p><a target="_blank" href="https://amgeneral.com"><button>Learn More About AM General</button></a></p>-->
<!--    </div>-->
  </div>
</div>
