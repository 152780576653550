<div class="hidden md:flex gap-2">
  <div *ngIf="images && images.length > 1" class="flex flex-col justify-end gap-2">
    <div *ngFor="let image of images" class=" ">
      <div *ngIf="image.video; then showSideBarVideo else showSideBarImage"></div>
      <ng-template #showSideBarVideo>
        <img class="thumbnail-image" (click)="chooseImage(image)" [src]="generateThumbNailFromVideo(image)">
      </ng-template>
      <ng-template #showSideBarImage>
        <img *ngIf="image.url && !imageNotFound[image.id]" [src]="image.url" [alt]="image.alt" class="thumbnail-image  " (click)="chooseImage(image)" (error)="imageError(image)" />
        <img class="thumbnail-image" aria-hidden="true" *ngIf="imageNotFound[image.id] || !image.url" alt="not found image" src="../../../assets/image-not-found.100.jpg">
      </ng-template>

    </div>
  </div>
  <div class="flex flex-col ">
    <div *ngIf="chosenImage.video; then showVideo else showImage"></div>

    <ng-template #showVideo>
      <iframe class="primary-image"
              [src]="safeVideoUrl" allowfullscreen>
      </iframe>
    </ng-template>

    <ng-template #showImage>
      <img class="primary-image" aria-hidden="true" *ngIf="imageNotFound[chosenImage.id] || noImages" alt="not found image" src="../../../assets/image-not-found.500.jpg">
      <img *ngIf="chosenImage.url && !imageNotFound[chosenImage.id]" [src]="chosenImage.url" [alt]="chosenImage.alt" class="primary-image " (error)="imageError(chosenImage)" >
    </ng-template>
  </div>
</div>

<div class="flex flex-col md:hidden gap-4" (swipeleft)="startAnimation('left')" (swiperight)="startAnimation('right')">
  <div class="flex flex-col"
       [@swipeChange]="animationState"
       (@swipeChange.done)="resetAnimationState()"
       [@completedAnimationState]="completedAnimationState"
       (@completedAnimationState.done)="resetCompletedAnimationState()">
    <div *ngIf="chosenImage.video; then showVideoMobile else showImageMobile"></div>

    <ng-template #showVideoMobile>
      <iframe class="primary-image mobile"
              [src]="safeVideoUrl" allowfullscreen>
      </iframe>
    </ng-template>

    <ng-template #showImageMobile>
      <img class="primary-image mobile" aria-hidden="true" *ngIf="imageNotFound[chosenImage.id] || noImages" alt="not found image" src="../../../assets/image-not-found.500.jpg">
      <img *ngIf="chosenImage.url && !imageNotFound[chosenImage.id]" [src]="chosenImage.url" [alt]="chosenImage.alt" class="primary-image mobile" (error)="imageError(chosenImage)">
    </ng-template>
  </div>
  <!--div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="16px">
    <div *ngFor="let image of images" class="image-bullet" [ngClass]="{'active': isImageActive(image)}" (click)="chooseImage(image)"></div>
  </div -->
</div>
