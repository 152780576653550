<div class="flex flex-col justify-start items-stretch gap-4 p-4">
	<div class="bold-caps">Address validation</div>

	<div *ngIf="loading">
		<mat-spinner class="text-center m-auto" [diameter]="35"></mat-spinner>
	</div>

	<div *ngIf="!loading">
		<div *ngIf="!checked">
			<p>
				We were not able to contact UPS to validate your address. We must have a validated address in order to proceed with your order.
				Please try again later.
			</p>

			<button mat-flat-button color="primary" (click)="close()">Go back</button>
			<button mat-flat-button color="primary" class="float-right " (click)="validateAddress()">Try again</button>
		</div>

		<div *ngIf="checked">
			<div *ngIf="!validated || !suggestion">
				<p>
					UPS was not able to validate the address you entered. We can not proceed without an address validated by UPS. Please double check
					your address, or try entering a different address.
				</p>

				<button mat-flat-button color="primary" (click)="close()">Go back</button>
			</div>

			<div *ngIf="validated && suggestion">
				<p>
					UPS matched your address and offered a validated address for use with your order. Please check the UPS validated address and
					continue, or go back and make changes.
				</p>

				<div class="flex flex-wrap justify-around">
					<div>
						You entered:
						<div class="suggested-address-section">
							{{ preValidation.line1 }}<br />
							<span *ngIf="preValidation.line2"> {{ preValidation.line2 }}<br /> </span>
							{{ preValidation.city }}, {{ preValidation.state }} {{ preValidation.postalCode }}
						</div>
					</div>

					<div>
						UPS validated address:
						<div class="suggested-address-section">
							{{ suggestion.line1 }}<br />
							<span *ngIf="suggestion.line2"> {{ suggestion.line2 }}<br /> </span>
							{{ suggestion.city }}, {{ suggestion.state }} {{ suggestion.postalCode }}
						</div>
					</div>
				</div>
				<div class="flex flex-wrap justify-center gap-5">
					<button mat-flat-button color="primary" class="blue-button" (click)="close()">Go back</button>
					<button mat-flat-button color="primary" class="blue-button" (click)="close(suggestion)">Continue with validated address</button>
				</div>
			</div>
		</div>
	</div>
</div>
