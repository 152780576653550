import {Component, Input, OnInit} from '@angular/core';
import {ShoppingCart} from "../../core/models/shopping-cart.model";
import {Order} from "../../core/models/order.model";

@Component({
	selector: 'app-checkout-summary',
	templateUrl: './checkout-summary.component.html',
	styleUrls: ['./checkout-summary.component.scss']
})
export class CheckoutSummaryComponent implements OnInit {

	@Input() shoppingCart: ShoppingCart;
	@Input() orderArray: Order[];

	constructor() {
	}

	ngOnInit() {
	}

	calculateFreight(order: Order): number {
		const freight = order.freight;
		const discountAmount = order.shippingDiscountAmount ? order.shippingDiscountAmount : 0;
		return freight - discountAmount;
	}

}
